import Breadcrumbs from '@mui/material/Breadcrumbs';

export default function GBreadcrumbs({ breadcrumbs, separator, pointerEventsAllowed=false, displaySeparator=true }) {
  return (<>
    <Breadcrumbs separator={separator} aria-label="breadcrumb">
      {breadcrumbs?.map((bread, index) => {
        if (index === breadcrumbs?.length - 1)
          return (
            <div key={index} style={{ pointerEvents: pointerEventsAllowed ? "all" : "none" }}>
              {bread}
            </div>
          );
        else return <div key={index}>{bread}</div>;
      })}
    </Breadcrumbs>
    {displaySeparator && 
    <div style={{
        marginTop: '32px',
        marginBottom: '32px',
        transform: 'translateX(-40px)',
        width: '100vw',
        borderTop: '1px solid #E1E6E2',
      }} />}
</>  );
}
