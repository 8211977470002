import { useEffect, useMemo, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box, Menu, MenuItem } from '@mui/material';

import Button from '../design/Button';
import GHeader from '../design/components/GHeader';
import { Filters } from '../dashboard/Filters';
import GTable from '../design/components/GTable';

import { JService } from '../api-service/ApiService';
import { enqueueSnackbar } from 'notistack';
import { filtersReducer, formatDate, formatTime, getOrgURLParam, getPermissionId, initiateCall } from '../../utility-functions';
import { fetchData as fetchUsers } from '../../utility-functions';

import styles from './SurveyView.module.css';
import gstyles from '../design/styles';
import colors from '../design/colors';
import { callIcon, dropdownIcon } from '../../utility-functions/assetLoader';
import SurveyModal from './SurveyModal';
import DeleteSurveyModal from './DeleteSurveyModal';
import { CustomTooltip } from '../design/components/customComponents';

const initialState={
    searchText:'',
    users: {all:[], selected: []}
}
const formatCalendarDate = (date) => {
  return `${new Date(date).getFullYear()}-${(
    "0" +
    (new Date(date).getMonth() + 1)
  ).slice(-2)}-${("0" + new Date(date).getDate()).slice(-2)}`;
}

const SurveyView = () => {
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const contentTitle = location.state?.contentTitle;

    const reduxAccount = useSelector(state=>state?.account?.value);
    const reduxSubscription = useSelector(state=>state?.account?.value?.subscription);
    const maskNumbers = useSelector(state => state?.auth?.maskNumbers);
    const {canMakeCalls, canViewUsers} = useSelector((state) => state?.auth?.permissions);

  const [rows, setRows] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [rowCount, setRowCount] = useState(0);
    const [gTablePageNumber, setGTablePageNumber] = useState(0);
    const [calendar, setCalendar] = useState({
        date_start: formatCalendarDate(Date.now() - 2 * 86400000), // 2 days data
        date_end: formatCalendarDate(new Date()),
        time_start: "00:00:00",
        time_end: "23:59:59",
    });
    const [surveyModalVisible, setSurveyModalVisible] = useState(false);
    const [deleteSurveyModalVisible, setDeleteSurveyModalVisible] = useState(false);
    const [dropdownAnchorEl, setDropdownAnchorEl] = useState(null);
    const [dropdownVisible, setDropdownVisible] = useState(false);

    const [availableData, dispatchAvailableData] = useReducer(filtersReducer, initialState);

    const fetchSurveyQuestions = async () => {
      try{
        const res = await JService.get(`/api/v1/core/ivr-survey/${params.id}/`);
        if(!res.success){
            throw new Error(res.message || 'Error while retrieving survey questions');
        }
        const {questions, questions_order} = res.data;
        let questionsOrder = {...questions_order};
        if(!Object.keys(questionsOrder).length){
            questions.forEach((ques, idx) => questionsOrder[idx + 1] = ques['id']);
        }
        const sortedQuesOrder = Object.keys(questionsOrder).sort((a, b) => Number(a) - Number(b));
        const quesData = sortedQuesOrder.map(quesKey => {
            const quesId = questionsOrder[quesKey];
            const ques = questions.find(elem => elem.id === quesId);
            return {
                id: ques.id,
                title: ques.title,
            }
        });
        setQuestions(quesData);
    }
    catch(err){
        enqueueSnackbar(err.message || 'Error while retrieving survey questions', {variant:'error'});
    }
  }
    const fetchData = async (page, size, signal) => {
      // async function fetchData(page, size, signal) {
        try{
            setShowLoader(true);
            const _pageNumber = page ?? pageNumber;
            const _pageSize = size ?? pageSize;
            let url = `/api/v1/core/retrieve-ivr-survey-logs/${getOrgURLParam()}/?survey_id=${params.id}&date_start=${calendar?.date_start}&date_end=${calendar?.date_end}&page=${_pageNumber}&page_size=${_pageSize}`;
            if(availableData?.users?.selected?.length){
                url+=`&users=${JSON.stringify(availableData?.users?.selected)}`;
            }
            if(availableData?.searchText?.trim()?.length){
                url+=`&search=${encodeURIComponent(availableData?.searchText.trim())}`;
            }
            const res = await JService.get(url, {}, {signal});
            if(!('success' in res)) return;
            if(!res.success){
                throw new Error(res.message || 'Error while retrieving survey responses');
            }
            setShowLoader(false);
            setRows(res.data.results);
            setRowCount(res.data.count);
        }
        catch(err){
            setShowLoader(false);
            enqueueSnackbar(err.message || 'Error while retrieving survey responses', {variant:'error'});
        }
        finally{
            // setShowLoader(false);
        }
    }
    const handleShowDropdown = (e) => {
      e.stopPropagation();
      setDropdownVisible(true);
      setDropdownAnchorEl(e.target);
    }
    const handleEditSurvey = () => {
      setSurveyModalVisible({visible: true, mode:'Edit'});
      handleDropdownClose();
    }
    const handleDeleteClicked = () => {
      setDeleteSurveyModalVisible(true);
      handleDropdownClose();
    }
    const handleDropdownClose = () => {
      setDropdownVisible(false);
      setDropdownAnchorEl(null);
    }
    const handleFilterApply = (filterName, val) => {
        if (filterName === "Date") {
          setCalendar(val);
        }
    }
    const onSearch = () => {
      dispatchAvailableData({ type: "setSearchText", payload: ''})
    }
    const handlePageChange = (newPage, dir) => {
      setPageNumber(newPage+1);
      fetchData(newPage+1);
    }
    const handlePageSizeChange = (newSize) => {
      setPageSize(newSize);
      setPageNumber(1);
      setGTablePageNumber(0);
      fetchData(1, newSize);
    }
  
    const handleSetUsers = (data) => {
        dispatchAvailableData({
          type: "setPayloadToAllOfType",
          payload: {
            payloadFor: "users",
            payload: data?.map((u) => ({
              name: u?.first_name + " " + u?.last_name,
              id: u?.id,
            })),
          },
        });
    };

    const customDownloadHandler = async () => {
      try{
        let url = `/api/v1/core/send-ivr-survey-logs/${getOrgURLParam()}/?survey_id=${params.id}&date_start=${calendar?.date_start}&date_end=${calendar?.date_end}`
        if(availableData?.users?.selected?.length)
          url += `&users=${JSON.stringify(availableData.users.selected)}`;
        if(availableData?.searchText?.trim()?.length)
          url += `&search=${encodeURIComponent(availableData.searchText.trim())}`
        const res = await JService.get(url)
        if(!res.success){
          throw new Error(res.message || 'Error while sending the survey response report to inbox');
        }
        enqueueSnackbar(res.message || 'You will receive the survey response report in your inbox', {variant: 'success'})
      }
      catch(err){
        enqueueSnackbar(err.message || 'Error while sending the survey response report to inbox', {variant: 'error'});
      }
    }

    useEffect(() => {
      fetchSurveyQuestions();
    },[]);

    useEffect(() => {
      const controller = new AbortController();
      setPageNumber(1);
      setGTablePageNumber(0);
      fetchData(1, pageSize, controller.signal);
      
      return () => {
       controller.abort();
      }
    },[availableData?.users?.selected, availableData?.searchText, calendar]);

    useEffect(() => {
      if(canViewUsers){
        fetchUsers(`/api/v1/auth/retrieve-users/${getOrgURLParam()}/?permission_id=${getPermissionId(null, 'manage surveys')}`, handleSetUsers, "users");
      }
    },[reduxAccount, canViewUsers]);

    useEffect(() => {
      if(dropdownVisible) window.addEventListener("click", handleDropdownClose);
      else window.removeEventListener("click", handleDropdownClose);
  
      return () => {
        window.removeEventListener("click", handleDropdownClose);
      };
     },[dropdownVisible]);

    const breadcrumbs = [
        <Link className={`${styles.breadcrumbLink} nc-gray-900`} to="/survey">
          Surveys
        </Link>,
        <Link
          className="ic-green-400"
          to={
            "/survey/" +
            (window.location.pathname.split("/").pop() || "1") +
            "/"
          }
        >
          {contentTitle}
        </Link>,
      ];
    const viewSurveyBtn = <Button hierarchy='white' variant='outlined' buttonText='Actions' icon={{}} onClick={handleShowDropdown} disabled={false} isLoading={false}/>

    const cols = useMemo(()=>{
        const defaultCols = [
          {
            field: "contact",
            headerClassName: "user-table-header-user-name",
            headerName: "Name",
            // flex: 1,
            minWidth: 285,
            renderCell: (params) => (
              <Box
                sx={{
                 display:'flex',
                 alignItems:'center',
                 justifyContent:'flex-start'
                }}
              ><div className='t7 regular-font' style={{ display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              paddingLeft: "14px",
              color: colors.nc_gray_900,
             }}>
                <span
                  style={{
                    display: "inline-block",
                    marginBottom: "4px",
                  }}
                  className={styles.noOverflow}
                >
                  {params?.row?.candidate_name}
                </span>
                {(maskNumbers && params?.row?.candidate_name?.trim()?.length) ? null : 
                <span
                  className={`${styles.noOverflow} nc-gray-600 t7`}
                >
                  {params?.row?.candidate_number}
                </span>}
                </div>
               {reduxAccount?.data?.license && reduxAccount?.data?.license!=='Viewer' &&
                <div className="table-cell-edit" style={{width:reduxSubscription?.plan?.name?.includes('USD')?'80px':'40px'}}>
                <div>
                {canMakeCalls && <span className={'contactIcons'} onClick={(e)=>initiateCall(e, params?.row?.candidate_number, params?.row?.contact_list_id)} onMouseDown={(e) => e.stopPropagation()}>{callIcon()}</span>}
                {/* {reduxSubscription?.plan?.name?.includes('USD') && <span onClick={(e)=>messagingEnabled && !params?.row?.logsContact?.candidateNumber?.startsWith('+91') ? handleOpenConversation(e,params,'call-logs'):null} disabled={!messagingEnabled || params?.row?.logsContact?.candidateNumber?.startsWith('+91')} className={`contactIcons ${!messagingEnabled || params?.row?.logsContact?.candidateNumber?.startsWith('+91')? 'message-disabled':''}`}>{messageIcon()}</span>} */}
                </div>
                </div>}
              </Box>
            ),
          },
          {
            field: "created_at",
            headerClassName: "user-table-header-user-name",
            headerName: "Received on",
            // flex: 1.2,
            minWidth: 220,
            renderCell: (params) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    color: colors.nc_gray_900,
                  }}>
                    <span className='t7 regular-font' style={{display: "inline-block" }}>{formatDate(params?.row?.created_at)}</span>
                    <p className="nc-gray-600 t7">{formatTime(params?.row?.created_at)}</p>
                </Box>
              );
            },
          },
          ...reduxAccount.data.profile.role === 2 ? [] : [{
            field: "user",
            headerClassName: "user-table-header-user-name",
            headerName: "User",
            minWidth: 220,
            renderCell: (params) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      marginBottom: "4px",
                    }}
                  >
                    <p className='t7 regular-font nc-gray-900'>{params?.row?.creator_name}</p>
                    <p className="t7 regular-font nc-gray-600" style={{marginTop:0}}>
                      {params?.row?.creator_number}
                    </p>
                  </Box>
                </Box>
              );
            },
           }],
        ];
        const responsesCols = questions?.map((elem, idx) => {
          return {
            field: `Ques-${elem.id}`,
            flex: 1,
            headerClassName: "user-table-header-user-name",
            renderHeader: () => {
              return <CustomTooltip tooltipTitle={elem.title}><p className={styles.noOverflow}>{elem.title}</p></CustomTooltip>
            },
            minWidth: 220,
            renderCell: (params) => {
              return <span className='t7 regular-font'>{params?.row?.survey_answers?.[idx]?.answer || '--'}</span>
            }
          }
        })
        if(responsesCols && Array.isArray(responsesCols)){
          return [...defaultCols, ...responsesCols];
        }
        return [...defaultCols]
    }, [maskNumbers, questions, canMakeCalls]);

    return (
      <div className={`screen-container screen-padding-bottom ${styles.surveyViewContainer}`}>
        <GHeader
        breadcrumbs={{ isVisible: true, breadcrumbs }}
        // title={"Post call survey"}
        threeDots={false}
        topHeader={{}}
        bottomHeader={{
          contentTitle: contentTitle,
          buttons: [
            viewSurveyBtn
          ],
          isCalendar: false,
          style:{marginBottom:'50px', paddingTop: 0}
        }}
        gsepStyles={{marginTop:'24px', marginBottom:'0'}}
      />
       <Filters
        onApply={handleFilterApply}
        availableData={availableData}
        dispatchAvailableData={dispatchAvailableData}
        fromScreen="survey-view"
        filtersList={[{ name: "Users", isSearch: true, payloadFor: "users", permission: 'view users' }]}
      />
      <GTable
        leftHeader={{
          isSearch: true,
          filters:{}
        }}
        rightHeader={{isSearch: false, isRefresh: true, isDownload: true }}
        download={{
          isCustom: true,
          customDownloadHandler
        }}
        availableData={availableData}
        dispatchAvailableData={dispatchAvailableData}
        fromScreen="survey-view"
        onSearch={onSearch}
        rows={rows}
        columns={cols}
        useMuiTable={true}
        isLoading={showLoader}
        getTableRowClassName={() => `teams-table-row`}
        additionalProps={{ bgHover: true }}
        onRowClick={() => {}}
        rowCount={rowCount || 0}
        handlePageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        rowsPerPage={pageSize}
        gTablePageNumber={gTablePageNumber}
        setGTablePageNumber={setGTablePageNumber}
        paginationMode="server"
        rowHeight={88}    
      />
      <Menu
        disableScrollLock={false}
        open={dropdownVisible}
        anchorEl={dropdownAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        sx={localStyles.menu}
      >
        <MenuItem
          onClick={handleEditSurvey}
          sx={localStyles.menuItem}
          >
          View/edit survey
        </MenuItem>
        <MenuItem
          onClick={handleDeleteClicked}
          sx={localStyles.menuItem}
        >
          Delete survey
        </MenuItem>
      </Menu>
      <DeleteSurveyModal visible={deleteSurveyModalVisible} handleClose={()=>setDeleteSurveyModalVisible(false)} id={params.id} title={contentTitle} callback={()=>navigate(-1)}/>
      <SurveyModal id={params.id} visible={surveyModalVisible} handleClose={()=>setSurveyModalVisible(false)} mode="Edit"/>
    </div>
    )
}

export default SurveyView;

const localStyles = {
  menu: {
    marginLeft: '-15px',
    "& .MuiPaper-root": {
     boxShadow: 'none',
     border: `1px solid ${colors.nc_gray_300}`,
    },
    "& .MuiPaper-root .MuiList-root.MuiMenu-list":{
      padding: 0
    }
  },
  menuItem: {
    cursor: "pointer",
    padding: '12px 20px', 
    ...gstyles.t7, 
    ...gstyles.regular_font, 
    color: colors.nc_gray_900 
  }
}