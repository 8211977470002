import React from "react";
import { useSelector } from 'react-redux';
import Button from "@mui/material/Button";
import HubspotIcon from "../../assets/HubspotIcon.svg";
import PipedriveIcon from "../../assets/pipedrive-logo.svg";
import ZohoIcon from "../../assets/ZohoIcon.png";
import DeskeraIcon from "../../assets/DeskeraIcon.png";
import SalesforceIcon from "../../assets/Salesforce.png"
import Dynamics from "../../assets/Dynamics-365-CRM-Logo.png"
import "./Integrations.css"

export default function IntegrationsTab(props) {
	const reduxAccount = useSelector((state) => state?.account?.value);

  return (
    <div className="integrations-container nc-gray-900 regular-font">
      <div className="crm-integrations">
        <div className="title medium-font t5">CRM Integrations</div>
        <div className="content">
          {props.canManageOrgLevelIntegrations && 
          <div className="integration-card">
            <div className="upper-half">
              <img src={HubspotIcon} alt="" />
            </div>
            <div className="lower-half">
              <p className="title medium-font">Hubspot</p>
              <p className="integration-btn">
                {!props.hubspotAccess ? (
                <Button
                  variant="contained"
                  onClick={props.handleHubspotConnect}
                >
                  Connect
                </Button>
              ) : (
                <Button
                  variant="contained"
                  className="disconnect-btn"
                  onClick={props.handleHubspotDisconnect}
                >
                  Disconnect
                </Button>
              )}
              </p>
            </div>
          </div>}
          <div className="integration-card">
            <div className="upper-half">
              <img src={PipedriveIcon} alt="" />
             
            </div>
            <div className="lower-half">
              <p className="title medium-font">Pipedrive</p>
              <p className="integration-btn">
              {!props.pipedriveAccess ? (
                <Button
                  variant="contained"
                  onClick={props.handlePipedriveConnect}
                >
                  Connect
                </Button>
              ) : (
                <Button
                  variant="contained"
                  className="disconnect-btn"
                  onClick={props.handlePipedriveDisconnect}
                >
                  Disconnect
                </Button>
              )}              
              </p>
            </div>
          </div>

		  <div className="integration-card">
            <div className="upper-half">
              <img className="zoho-img" src={ZohoIcon} alt="" style={{width: "50px", height: "50px" }}/>
            
            </div>
            <div className="lower-half">
              <p className="title medium-font">Zoho Phonebridge</p>
              <p className="integration-btn">
              {!props.zohoPhonebridgeAccess ? (
                <Button
                  variant="contained"
                  onClick={props.handleZohoPhoneBridgeConnect}
                >
                  Connect
                </Button>
              ) : (
                <Button
                  variant="contained"
                  className="disconnect-btn"
                  onClick={props.handleZohoPhoneBridgeDisconnect}
                >
                  Disconnect
                </Button>
              )}
              </p>
            </div>
          </div>

          {props.canManageOrgLevelIntegrations && 
          <div className="integration-card">
            <div className="upper-half">
              <img className="zoho-img" src={DeskeraIcon} alt="" style={{width: "50px", height: "50px" }}/>
            </div>
            <div className="lower-half">
              <p className="title medium-font">Deskera</p>
              <p className="integration-btn">
              {!props.deskeraAccess ? (
                <Button
                  variant="contained"
                  onClick={props.handleDeskeraConnect}
                >
                  Connect
                </Button>
              ) : (
                <Button
                  variant="contained"
                  className="disconnect-btn"
                  onClick={props.handleDeskeraDisconnect}
                >
                  Disconnect
                </Button>
              )}
              </p>
            </div>
          </div>}
          {props.canManageOrgLevelIntegrations && 
          <div className="integration-card">
            <div className="upper-half">
              <img src={Dynamics} alt="" style={{width: "50px", height: "50px" }}/>
            </div>
            <div className="lower-half">
              <p className="title medium-font">Microsoft Dynamics 365</p>
              <p className="integration-btn">
              {!props.dynamicsAccess? (
                <Button
                  variant="contained"
                  onClick={props.handleDynamicsConnect}
                >
                  Connect
                </Button>
              ) : (
                <Button
                  variant="contained"
                  className="disconnect-btn"
                  onClick={props.handleDynamicsDisconnect}
                >
                  Disconnect
                </Button>
              )}
              </p>
            </div>
          </div>}
          {props.canManageOrgLevelIntegrations && 
          <div className="integration-card">
          <div className="upper-half">
            <img src={SalesforceIcon} alt="" style={{ width: "40px", height: "40px" }} />
          </div>
          <div className="lower-half">
            <p className="title medium-font">Salesforce</p>
            <div className="integration-btn">
            {!props.salesforceAccess ? (
                  <>
                    <Button
                      variant="contained"
                      className="connect-btn"
                      onClick={props.handleSalesForceConnect}
                    >
                      Connect
                    </Button>
                  </>
                ) : (
                  <Button
                    variant="contained"
                    className="disconnect-btn"
                    onClick={props.handlesalesForceDisconnect}
                  >
                    Disconnect
                  </Button>
                )}
            </div>
          </div>
        </div>
        }
        </div>
      </div>
    </div>
  );
}
