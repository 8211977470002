// #css
import "./sideNavStyles.css";
import colors from "../../design/colors";

// #lib
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import List from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import { Box } from "@mui/system";

// # assets
import {
  sideNavCallLogsIcon,
  sideNavCampaignsIcon,
  sideNavContactListsIcon,
  sideNavDashboardIcon,
  sideNavSettingsIcon,
  sideNavTemplatesIcon,
  virtualNumbersIcon,
  sideNavBillingIcon,
  userAndTeamIcon
} from "../../../utility-functions/assetLoader";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

export default function SideNav() {
  const reduxSubscription=useSelector((state)=>state?.account?.value?.subscription);
  const tabsToDisplay = useSelector((state) => state?.auth?.validPaths);

  const [campaignsOpen, setCampaignsOpen] = useState(false);
  const [templatesOpen, setTemplatesOpen] = useState(false);

  function handleScrollForNav(e) {
    const _scrolledBy = Math.ceil(
      Math.abs(document.body.getBoundingClientRect().top)
    );

    const _sideNav = document.querySelector(".side-nav-ul");

    if (_scrolledBy >= 64) {
      _sideNav.style.position = "fixed";
      _sideNav.style.top = "0px";
      _sideNav.style.left = "0";
      _sideNav.style.height = "calc(100vh)";
      _sideNav.classList.add(["with-top-border"]);
    }

    if (_scrolledBy === 0) {
      _sideNav.style.position = "fixed";
      _sideNav.style.top = `var(--top-nav-height)`;
      _sideNav.style.left = "unset";
      _sideNav.style.height = "calc(100vh - var(--top-nav-height))";
      _sideNav.classList.remove(["with-top-border"]);
    } else if (_scrolledBy < 64) {
      let negativeScrollAdjustment = -1 * parseInt(window.scrollY);
      _sideNav.style.position = "fixed";
      _sideNav.style.top = `calc(var(--top-nav-height) + ${negativeScrollAdjustment}px)`;
      _sideNav.style.left = "unset";
      _sideNav.style.height = `calc(100vh - var(--top-nav-height) + 8px - ${negativeScrollAdjustment}px)`;
      _sideNav.classList.add(["with-top-border"]);
    }
    //logic: position:fixed (to 0,0) when scroll>=90 else position:relative
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScrollForNav);
    return () => window.removeEventListener("scroll", handleScrollForNav);
  }, []);

  if(window.location.pathname==='/account-suspended')
  return null

  return (
  <div className="side-nav-ul-container">
    <ul className="side-nav-ul b1 regular-font nc-gray-900">
      {/* DASHBOARD */}
      {tabsToDisplay['Dashboard']?.allowed && (
        <li className="side-nav-li b1 regular-font nc-gray-900">
          <NavLink
            to="/dashboard"
            className={({ isActive }) =>
              isActive ? "side-nav-link active-link" : "side-nav-link"
            }
          >
            {({ isActive }) => (
              <>
                {isActive
                  ? sideNavDashboardIcon(colors.ic_green_400)
                  : sideNavDashboardIcon()}
                <p
                // style={{marginLeft: '8px'}}
                >
                  Dashboard
                </p>
              </>
            )}
          </NavLink>
      </li>)}

      {/* CALL LOGS */}
      {tabsToDisplay['Call logs']?.allowed && (
        <li className="side-nav-li">
          <NavLink
          className={({ isActive }) =>
            (isActive || window.location.pathname.includes('activity-logs') || window.location.pathname.includes('call-logs')) ? "side-nav-link active-link" : "side-nav-link"
          }
          to={reduxSubscription?.plan?.name?.includes('USD')? "/activity-logs/calls":"/call-logs"}
        >
          {({ isActive }) => (
            <>
              {(isActive || window.location.pathname.includes('activity-logs') || window.location.pathname.includes('call-logs'))
                ? sideNavCallLogsIcon(colors.ic_green_400)
                : sideNavCallLogsIcon(colors.nc_gray_900)}
              <p>{reduxSubscription?.plan?.name?.includes('USD')? "Activity logs":"Call logs"}</p>
            </>
          )}
        </NavLink>
      </li>
      )}

      {/* CONTACT LISTS */}
      {tabsToDisplay['Contact lists']?.allowed && (
        <li className="side-nav-li">
          <NavLink
            to="/contact-lists"
            className={({ isActive }) =>
              isActive ? "side-nav-link active-link" : "side-nav-link"
            }
          >
            {({ isActive }) => (
              <>
                {isActive
                  ? sideNavContactListsIcon(colors.ic_green_400)
                  : sideNavContactListsIcon()}
                <p>Contact lists</p>
              </>
            )}
          </NavLink>
        </li>
      )}

      {/* CAMPAIGNS : VB & AUTODIAL */}
      <>
      {tabsToDisplay['Campaigns']?.allowed && (
        <><li
            className="side-nav-li collapse-nav-container"
            onClick={() => setCampaignsOpen((prevState) => !prevState)}
              >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ marginRight: "8px" }}>{sideNavCampaignsIcon()}</Box>
              <p className="b1 regular-font nc-gray-900">Campaigns</p>
            </div>
            {campaignsOpen ? <ExpandLess /> : <ExpandMore />}
          </li>
          <Collapse in={campaignsOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {tabsToDisplay['Autodial'].allowed && 
                <li className="side-nav-li">
                  <NavLink
                    to="/autodial"
                    className={({ isActive }) => isActive
                      ? "side-nav-link active-link child-link"
                      : "side-nav-link child-link"}
                  >
                    <p>Autodial</p>
                  </NavLink>
                </li>
              }
              {tabsToDisplay['Survey']?.allowed && (
                  <li className="side-nav-li">
                    <NavLink
                      to="/survey"
                      className={({ isActive }) => isActive
                        ? "side-nav-link active-link child-link"
                        : "side-nav-link child-link"}
                    >
                      <p>Survey</p>
                    </NavLink>
                  </li>
                )}
            </List>
          </Collapse></>
        )}
      </>

      {/* USERS & TEAMS*/}
      {tabsToDisplay['Users & Teams']?.allowed && (
        <li
          className="side-nav-li"
        >
          <NavLink
          to="/users-and-teams"
          className={({ isActive }) =>
            isActive ? "side-nav-link active-link" : "side-nav-link"
          }
          style={{ display: "flex", alignItems: "center", textDecoration: "none" }}
        >
          {({ isActive }) => (
            <>
              {isActive ? userAndTeamIcon(colors.ic_green_400) : userAndTeamIcon()} 
              <p
                className="b1 regular-font"
                style={{ color: isActive ? colors.ic_green_400 : "inherit" }}
              >
                Users & teams
              </p>
            </>
          )}
          </NavLink>
        </li>
      )}


      {/* Templates */}
       {tabsToDisplay['Templates']?.allowed && (
        <>
          <li
            className="side-nav-li collapse-nav-container"
            onClick={() => setTemplatesOpen((prevState) => !prevState)}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ marginRight: "8px" }}>{sideNavTemplatesIcon()}</Box>
              <p className="b1 regular-font nc-gray-900">Templates</p>
            </div>
            {templatesOpen ? <ExpandLess /> : <ExpandMore />}
          </li>
          <Collapse in={templatesOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <li className="side-nav-li">
                <NavLink
                  to="/messages"
                  className={({ isActive }) =>
                    isActive
                      ? "side-nav-link active-link child-link"
                      : "side-nav-link child-link"
                  }
                >
                  <p>Messages</p>
                </NavLink>
              </li>
            </List>
          </Collapse>
      </>)}

      {/* VIRTUAL NUMBERS */}
      {tabsToDisplay['Virtual numbers']?.allowed && (
          <li className="side-nav-li">
            <NavLink
              to="/virtual-numbers"
              className={({ isActive }) =>
                isActive ? "side-nav-link active-link" : "side-nav-link"
              }
            >
              {({ isActive }) => (
                <>
                  {isActive
                    ? virtualNumbersIcon(colors.ic_green_400)
                    : virtualNumbersIcon()}
                  <p>Virtual numbers</p>
                </>
              )}
            </NavLink>
          </li>
        )}

      {/* BILLING */}
      {tabsToDisplay['Billing']?.allowed && (
      <li className="side-nav-li">
        <NavLink
          to="/billing"
          className={({ isActive }) =>
            isActive ? "side-nav-link active-link" : "side-nav-link"
          }
        >
          {({ isActive }) => (
            <>
              {isActive
                ? sideNavBillingIcon(colors.ic_green_400)
                : sideNavBillingIcon()}
              <p>Billing</p>
            </>
          )}
        </NavLink>
      </li>)}

      {/* SETTINGS */}
      {tabsToDisplay['Settings']?.allowed && (<li className="side-nav-li">
        <NavLink
          to="/settings"
          className={({ isActive }) =>
            isActive ? "side-nav-link active-link" : "side-nav-link"
          }
        >
          {({ isActive }) => (
            <>
              {isActive
                ? sideNavSettingsIcon(colors.ic_green_400)
                : sideNavSettingsIcon()}
              <p>Settings</p>
            </>
          )}
        </NavLink>
      </li>
      )}
    </ul>
  </div>
)}
