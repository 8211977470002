import React, {useEffect, useReducer, useState} from "react";
import Button from "../design/Button";
import GHeader from "../design/components/GHeader";
import AddIcon from "@mui/icons-material/Add";
import GTable from "../design/components/GTable";
import GModal from "../design/components/GModal";
import "./contactListsStyles.css";
import campaignSampleFile from "../../assets/campaign_sample.csv";
import threedotsIcon from "../../assets/gcall-logs-threedots-icon.svg";
import contactListsEmpty from "../../assets/contact-lists-empty.svg";
import baseFileIcon from "../../assets/base file icon.svg";
import {useDispatch, useSelector} from "react-redux";
import {storeCampaigns} from "../features/campaign/campaign";
import {Box} from "@mui/system";
import {useNavigate} from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import {formatFileSize, formatDate} from "../../utility-functions";
import {JService} from "../api-service/ApiService";
import WarnModal from "./WarnModal/WarnModal";
import {enqueueSnackbar} from "notistack";

const initialState = {
  currentSet: "",
  searchText: "",
  searchFields: [],
};

const localStyles = {
  spanNoOverFlowStyle: {
    display: "inline-block",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "100%",
  },
};

export default function ContactLists() {
  const navigate = useNavigate();

  const [showLoader, setShowLoader] = useState(false);
  const [controller, setController] = useState(0);
  const [masterRows, setMasterRows] = useState([]);
  const [rows, setRows] = useState([]);
  // New Contact List Form
  const [showNewListModal, setShowNewListModal] = useState(false);
  const [newListName, setNewListName] = useState("");
  const [newListFile, setNewListFile] = useState([]);
  const [fileKey, setFileKey] = useState("random-string-that-doesnt-match-anything");
  const [newListFormListener, setNewListFormListener] = useState(0);
  const [disableNewListSubmit, setDisableNewListSubmit] = useState(true);
  const [rowOptionsVisible, setRowOptionsVisible] = useState(false);
  const [rowBeingEdited, setRowBeingEdited] = useState(null);
  const [rowOptionsCords, setRowOptionsCords] = useState([0, 0]);
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editListName, setEditListName] = useState("");
  const [pageData, setPageData] = useState({
    currentPage: 1,
    nextUrl: null,
    previousUrl: null,
    rowCount: 0,
  });
  const [pageSize, setPageSize] = useState(10);
  const [hasLists, setHasLists] = useState(null)

  const {canCreateEditContactsAndLists, canDeleteContactsAndLists} = useSelector((state) => state?.auth?.permissions);

  // ----- SEARCH & FILTERs LOGIC ------
  const filterReducer = (state, action) => {
    switch (action.type) {
      case "setSearchText": {
        // use useTransition or throttle search
        if (typeof action.payload !== "string") return state;
        return {
          ...state,
          searchText: action.payload,
        };
      }

      case "clearAllFilters": {
        return initialState;
      }
      default:
        return state;
    }
  };

  const [availableData, dispatchAvailableData] = useReducer(filterReducer, initialState);


  

  const onSearch = (searchText) => {
    dispatchAvailableData({
      type: "setSearchText",
      payload: searchText.trim(),
    });
  };
  

  
  const fetchData = (signal, page = 1) => {
    setShowLoader(true);
  
    const searchQuery = availableData.searchText.trim();
    const url = `/api/v1/core/retrieve-contact-lists/?page=${page}&page_size=${pageSize}${
      searchQuery ? `&search=${encodeURIComponent(searchQuery)}` : ""
    }`;
  
    JService.get(url, {}, { signal })
      .then((res) => {
        if (!res?.success) throw new Error("Failed to fetch contact lists");
  
        if (hasLists === null) {
          setHasLists(res.data.count > 0);
        }
        setMasterRows(res.data.results);
        setRows(res.data.results);
        setPageData({
          currentPage: page,
          nextUrl: res.data.next,
          previousUrl: res.data.previous,
          rowCount: res.data.count,
        });
      })
      .catch((err) => {
        console.error("Error fetching contact lists:", err);
      })
      .finally(() => {
        setShowLoader(false);
      });
  };
  
  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage !== pageData.currentPage) {
      setPageData((prev) => ({ ...prev, currentPage: newPage }));
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
  
    fetchData(signal, pageData.currentPage);
  
    return () => {
      abortController.abort();
    };
  }, [availableData.searchText, controller, pageData.currentPage, pageSize]);
  
  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize); 
    setPageData((prev) => ({ ...prev, currentPage: 1 })); 
  };
  
  
  
  useEffect(() => {
    if (newListFile.length != 0 && newListName.trim().length !== 0) {
      setDisableNewListSubmit(false);
    } else setDisableNewListSubmit(true);
  }, [newListFormListener]);

  const handleCreateSubmit = e => {
    e.preventDefault();
    if (newListFile.length != 0 && newListName.trim().length !== 0) {
      let formData = new FormData();
      formData.append("file", newListFile[0]);
      formData.append("title", newListName);
      JService.post("/api/v1/core/create-contact-list/", formData)
        .then(res => {
          if (!res?.success) throw new Error(res?.message || "Failed to create contact list");

          enqueueSnackbar(res?.message, {variant: "success"});
          setController(prev => prev + 1);
        })
        .catch(err => enqueueSnackbar(err?.message || "Error while creating contact list", {variant: "error"}))
        .finally(() => {
          setFileKey(Date.now());
          handleCreateCancel();
        });
    }
  };

  const handleCreateCancel = () => {
    setNewListName("");
    setNewListFile([]);
    setShowNewListModal(false);
  };

  // NOTE: check error message
  const handleListDelete = id => {
    JService.delete(`/api/v1/core/update-delete-contact-list/${id}/`)
      .then(res => {
        setShowDeletePrompt(false);
        if (res?.success === false || res.status > 300) throw new Error(res.message);

        enqueueSnackbar(res?.message || "Deleted contact list", {variant: "success"});
        setController(prev => prev + 1);
      })
      .catch(err => enqueueSnackbar(err?.message || "Error while deleting contact list", {variant: "error"}));
  };

  const handleListEdit = id => {
    JService.patch(`/api/v1/core/update-delete-contact-list/${id}/`, {
      title: editListName,
    })
      .then(response => {
        setShowEditModal(false);

        if (!response?.success) throw new Error(response.message || "Failed to update contact list");
        enqueueSnackbar(response?.message, {variant: "success"});
        setController(prev => prev + 1);
      })
      .catch(err => enqueueSnackbar(err?.message || "Error while editing contact list", {variant: "error"}));
  };

  const createTeamButton = canCreateEditContactsAndLists ? (
    <Button
      variant="filled"
      buttonText="New List"
      icon={{startIcon: <AddIcon />}}
      disabled={false}
      isLoading={false}
      hierarchy="green"
      onClick={() => setShowNewListModal(true)}
    />
  ) : null;

  // scrolled // close rows options and detatch listener
  const handleScrollWhenRowOptionsOpen = e => {
    setRowOptionsVisible(false);
    window.removeEventListener("scroll", handleScrollWhenRowOptionsOpen);
  };

  // useEffect attaching scroll event listener when threedots is open
  useEffect(() => {
    if (rowOptionsVisible) {
      window.addEventListener("scroll", handleScrollWhenRowOptionsOpen);
    }

    return () => {
      window.removeEventListener("scroll", handleScrollWhenRowOptionsOpen);
    };
  }, [rowOptionsVisible]);

  const cols = [
    {
      field: "title",
      headerName: "List name",
      headerClassName: "contact-lists-view-table-header-list-name",
      flex: 2,
      // width: 400,

      renderCell: params => {
        return (
          <p
            style={{
              textDecoration: "underline",
              color: "#28994B",
              marginLeft: "14px",
              marginRight: "24px", // 16 px header and filters also @change
              marginRight: "32px",
              ...localStyles.spanNoOverFlowStyle,
            }}
          >
            {params.row.title}
          </p>
        );
      },
    },
    {
      field: "updated_at",
      headerName: "Last edited",
      flex: 1,
      renderCell: params => {
        return <p style={localStyles.spanNoOverFlowStyle}>{formatDate(params.row.updated_at)}</p>;
      },
    },
    {
      field: "total_contacts",
      headerName: "Contacts",
      flex: 0.5,
    },
    {
      field: "assigned_contacts",
      headerName: "Assigned contacts",
      flex: 0.75,
    },
   ...((canCreateEditContactsAndLists || canDeleteContactsAndLists) ? [{
      field: "listEdit",
      headerName: "",
      flex: 0.4,
      cellClassName: "table-cell-edit", // @important use this for threedots
      renderCell: params => {
        return (
          <Box sx={{textAlign: "left"}}>
            <img
              onClick={e => {
                setRowOptionsCords([e["clientX"], e["clientY"]]);
                e.stopPropagation();
                setRowBeingEdited(params.row);
                setRowOptionsVisible(true);
              }}
              src={threedotsIcon}
              alt="three dots icon"
            />
          </Box>
        );
      },
    }] : [])
  ];

  return (
    <div
      className="screen-container "
      style={{
        height: document.documentElement.scrollHeight - 56 >= window.innerHeight ? "fit-content" : window.innerHeight,
      }}
    >
      {/* GHeader, GTable */}
      {hasLists === false && !showLoader ? (
        <div className="contact-lists-empty">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "60px",
            }}
          >
            <img src={contactListsEmpty} />
            {canCreateEditContactsAndLists && <>
            <p className="t5 medium-font" style={{marginTop: "24px", marginBottom: "16px"}}>
              Create contact list
            </p>
            <p className="t6 regular-font">Stay organized and speed up your outreach process.</p>
            <div style={{marginTop: "24px"}}>{createTeamButton}</div>
            </>}
          </div>
        </div>
      ) : (
        <>
          <GHeader
            breadcrumbs={false}
            threeDots={false}
            topHeader={{}}
            bottomHeader={{
              contentTitle: "Contact lists",
              buttons: [createTeamButton],
              isCalendar: false,
            }}
          />

          <GTable
             leftHeader={{
              isSearch: true,
              filters: [],
            }}
            rightHeader={{
              filters: [],
            }}
            rows={rows}
            columns={cols}
            useMuiTable={true}
            isLoading={showLoader}
            getTableRowClassName={() => `teams-table-row`}
            additionalProps={{bgHover: true}}
            onRowClick={(params, event, details) => {
              navigate(`/contact-lists/${params?.id}`, {state: {listTitle: params?.row?.title}});
            }}
            onSearch={onSearch}
            fromScreen="contact-lists"
            availableData={availableData}
            dispatchAvailableData={dispatchAvailableData}
            paginationMode="server"
            rowCount={pageData.rowCount}
            gTablePageNumber={pageData.currentPage - 1}
            setGTablePageNumber={(page) => handlePageChange(page + 1)}
            onPageSizeChange={handlePageSizeChange}
            rowsPerPage={pageSize}
          />
        </>
      )}

      <GModal
        visible={showNewListModal}
        closeModal={handleCreateCancel}
        heading="New list"
        modalStyle={{width: "448px", height: "80%"}}
        bottomButtons={[
          <Button variant="filled" buttonText="Cancel" icon={{}} disabled={false} isLoading={false} hierarchy="white" onClick={handleCreateCancel} />,
          <Button
            variant="filled"
            buttonText="Create"
            icon={{}}
            disabled={disableNewListSubmit}
            isLoading={false}
            hierarchy="green"
            onClick={e => handleCreateSubmit(e)}
          />,
        ]}
        body={
          <div>
            <form onSubmit={e => handleCreateSubmit(e)} className="new-contact-list-form t6 regular-font nc-gray-900">
              <label className="t7 medium-font" style={{marginBottom: "32px"}}>
                List Name
                <input
                  placeholder="Enter List Name"
                  className="t6 regular-font ui--input ui--input-name"
                  name="name"
                  type="text"
                  onChange={e => {
                    setNewListName(e.target.value);
                    setNewListFormListener(newListFormListener + 1);
                  }}
                  value={newListName}
                />
              </label>
              <span className="t7 medium-font">Upload list</span>
              <label htmlFor="upload-list-file" className=" upload-file-label">
                {newListFile.length === 0 && (
                  <>
                    <div className="no-file">
                      <div>
                        <a className="t6 ic-green-500">Click to upload</a>
                        <span className="t6 medium-font">&nbsp;or drag and drop</span>
                        <p className="t8 regular-font nc-gray-600">File format: csv, xls or xlsx (max size: 10MB)</p>
                      </div>
                    </div>
                    <input
                      key={fileKey || ""}
                      id="upload-list-file"
                      placeholder="or drag and drop files here"
                      className="t6 regular-font ui--input ui--input-file"
                      name="file"
                      type="file"
                      disabled={newListFile[0]?.name}
                      accept={".csv,.xls,.xlsx"}
                      onChange={e => {
                        const _file = e.target.files[0];
                        if (
                          _file?.type === "text/csv" ||
                          _file?.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                          _file?.type === "application/vnd.ms-excel"
                        ) {
                          setNewListFile([e.target.files[0]]);
                          setNewListFormListener(newListFormListener + 1);
                        } else setNewListFile([]);
                      }}
                    />
                  </>
                )}
                {newListFile[0]?.name && (
                  <div className="has-file">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <img src={baseFileIcon} />
                      <div
                        className="t6 regular-font"
                        style={{
                          marginLeft: "20px",
                        }}
                      >
                        {/* <p className="nc-gray-900">{newListFile[0]?.name}</p> */}
                        <p className="nc-gray-900 t6 regular-font">
                          {/* clever 🙊. reasons why text-overflow doesnt work
                           * text-overflow needs a definite width, like 100px,
                           * if 100% is what needs to be set, try calc(100%)
                           * instead. white-space(to no-wrap) and overflow
                           * (to hidden) also need to be set, along with
                           * text-overflow(to ellipses).  And finally,
                           * display should not be inline. */}
                          {newListFile[0].name.substring(0, 27)}
                          {newListFile[0].name.length > 27 ? "…" : ""}
                        </p>

                        <p className="nc-gray-600 t6 regular-font">{formatFileSize(newListFile[0]?.size)}</p>
                      </div>
                    </div>
                    <CloseIcon
                      style={{cursor: "pointer"}}
                      onClick={e => {
                        e.stopPropagation();
                        setNewListFile([]);
                      }}
                    />
                  </div>
                )}
              </label>
              <a
                download
                href={campaignSampleFile}
                className="ic-green-500 regular-font t7"
                style={{
                  cursor: "pointer",
                  lineHeight: "20px",
                  textDecoration: "underline",
                }}
              >
                Download sample file
              </a>
            </form>
          </div>
        }
      />

      {!!rowOptionsVisible && (
        <div className="row-options-backdrop" onClick={() => setRowOptionsVisible(false)}>
          <div style={{position: "relative"}}>
            <div
              className="row-options-foreground"
              style={{
                marginLeft: rowOptionsCords[0],
                marginTop: rowOptionsCords[1],
              }}
            >
              {canCreateEditContactsAndLists && 
              <button
                style={{textAlign: "left"}}
                className="t6 nc-gray-900"
                onClick={() => {
                  setEditListName(rowBeingEdited.title);
                  setShowEditModal(true);
                }}
              >
                Edit
              </button>}
              {canDeleteContactsAndLists && 
              <button
                className="t6 nc-gray-900"
                style={{textAlign: "left"}}
                onClick={() => {
                  setShowDeletePrompt(true);
                }}
              >
                Delete
              </button>}
            </div>
          </div>
        </div>
      )}

      {/* Edit List Modal */}
      <GModal
        modalStyles={{width: "448px"}}
        closeModal={() => setShowEditModal(false)}
        heading={<p className="t6 semi-font">Edit contact list</p>}
        visible={showEditModal}
        bottomButtons={[
          <Button
            variant="filled"
            buttonText="Cancel"
            icon={{}}
            disabled={false}
            isLoading={false}
            hierarchy="white"
            onClick={() => setShowEditModal(false)}
          />,
          <Button
            variant="filled"
            buttonText={"Save"}
            icon={{}}
            isLoading={false}
            hierarchy="green"
            disabled={editListName.length === 0}
            onClick={() => handleListEdit(rowBeingEdited.id)}
          />,
        ]}
        body={
          <form className="new-contact-list-form">
            <label className="t7 medium-font" style={{marginBottom: "32px"}}>
              List Name
              <input
                placeholder="Enter List Name"
                className="t6 regular-font ui--input"
                name="name"
                type="text"
                onChange={e => {
                  setEditListName(e.target.value);
                }}
                value={editListName}
              />
            </label>
          </form>
        }
      />

      {/* Delete List Modal */}
      <WarnModal
        visible={showDeletePrompt}
        closeModal={() => setShowDeletePrompt(false)}
        heading={<p className="t6 medium-font">Delete contact list</p>}
        message={
          <div className="t6 regular-font">
            Are you sure you want to delete&nbsp;
            {!!rowBeingEdited && (
              <p
                className="t6 regular-font"
                style={{
                  display: "inline",
                  textDecoration: "underline",
                  fontWeight: 500,
                }}
              >
                {rowBeingEdited.title}
              </p>
            )}
            &nbsp;?
          </div>
        }
        actionButtonTitle="Delete"
        handleAction={() => handleListDelete(rowBeingEdited.id)}
      />
    </div>
  );
}
