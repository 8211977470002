import './deniedStyles.css'
import { lockIcon } from '../../utility-functions/assetLoader';
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

let timerId = null;
export default function PermissionDenied() {
    const navigate = useNavigate()

    useEffect(() => {
        timerId = setTimeout(() => navigate('/login', {replace: true}), 5000);

        return () => clearTimeout(timerId)
    }, [])
    
    return(
        <div className='access-denied-container bordered-container nc-gray-900 regular-font t6'>
            <div className='lock-icon-container'>{lockIcon()}</div>
            <p className='medium-font t5 '>Access denied</p>
            <p className='regular-font t6 nc-gray-500'>You do not have permission to access this page</p>
        </div>
    )
}