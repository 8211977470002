import { infoCircle } from "../../utility-functions/assetLoader";
import classes from "./Insights.module.css";

const Insights = (props) => {
  return (
    <>
      {props?.talkRatio?.[0]?.percent >= 0 &&
      props?.talkRatio?.[0]?.percent >= 0 ? (
        <div className={classes.insightContainer}>
          <div className={classes.headingContainer}>
            <h6 className="text-sm medium-font">Talk ratio</h6>
            {infoCircle()}
          </div>
          {props?.talkRatio?.map((person, index) => (
            <div key={index} className={classes.ratioContainer}>
              <h6 className="regular-font text-sm">{person.name}</h6>
              <div className={classes.progressContainer}>
                <div>
                  <div style={{ width: `${(238 * person.percent) / 100}px` }} />
                </div>
                <span className="text-sm regular-font">
                  {person.percent + "%"}
                </span>
              </div>
            </div>
          ))}
        </div>
      ) : (
        ""
      )}
      {props?.sentiment?.score &&
      props?.sentiment?.sentimentType &&
      props?.sentiment?.summary ? (
        <div className={classes.insightContainer}>
          <div className={classes.headingContainer}>
            <h6 className="text-sm medium-font">Sentiment score</h6>
            {infoCircle()}
          </div>
          <h2 className="display-xs regular-font">
            {props?.sentiment?.score + "%"}
          </h2>
          <div className={`${classes.infoContainer} text-sm regular-font`}>
            {props?.sentiment?.sentimentType}
          </div>
          <p className={`${classes.senSummary} text-sm regular-font`}>
            {props?.sentiment?.summary}
          </p>
        </div>
      ) : (
        ""
      )}
      {/* <div className={classes.insightContainer}>
        <div className={classes.headingContainer}>
          <h6 className="text-sm medium-font">Questions covered</h6>
          {infoCircle()}
        </div>
        <h2 className="display-xs regular-font">
          {props?.quesCovered?.percent + "%"}
        </h2>
        <div className={`${classes.infoContainer} text-sm regular-font`}>
          {(props?.quesCovered?.percent * props?.quesCovered?.total) / 100 +
            " out of " +
            props?.quesCovered?.total}
        </div>
        {props?.quesCovered?.quesList?.map((item, index) => (
          <div className={classes.question} key={index}>
            <div className={item.answered ? classes.checked : ""}>
              {item.answered ? check() : ""}
            </div>
            <p className="regular-font text-sm">{item.ques}</p>
          </div>
        ))}
      </div> */}
    </>
  );
};

export default Insights;
