import clevertap from "clevertap-web-sdk";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ApiService, { JService } from "../api-service/ApiService";
import { formatCurrentDate, getOrgURLParam } from "../../utility-functions";
import { setAllOrgsDetails, saveAccountDetails } from "../features/account/account";
import { reduxPersistSelectedVirtualNumber } from "../features/virtual-number/virtualNumber";
import AccountTab from "./AccountTab";
import GeneralTab from "./GeneralTab";
import IntegrationsTab from "./IntegrationsTab";
import CallingSMSTab from "./Calling&SMSTab";
import "./Settings.css";
import AnimationPage from '../shared/animation-page/AnimationPage';
import { enqueueSnackbar } from 'notistack';

function Settings() {
  const API_BASE_URL = process.env.REACT_APP_BETA;
  const queryParameters = new URLSearchParams(window.location.search);
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.account.value);
  const reduxAccount = useSelector((state) => state?.account?.value);

  const [tabNames, setTabNames] = useState(["General","Integrations"]);
  const [activeTab, setActiveTab] = useState("General");
  const [userProfile, setUserProfile] = useState(null);
  const [userVirtualNumber, setUserVirtualNumber] = useState(null);
  const [organization, setOrganization] = useState(null);
  const [apiKey, setApiKey] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [controller, setController] = useState(0);

  const {canManage2FA,
    canManagePrivateRecording,
    canManageRecordWarning,
    canManageReports,
    canManageCallDispositions,
    canManageOrgLevelIntegrations,
  }  = useSelector((state) => state?.auth?.permissions);

  // 1st useEffect for common api calls
  useEffect(() => {
    if (queryParameters.get("tab") === "integrations")
      setActiveTab("Integrations");

    retrieveAccountProfile();
    retrieveVirtualNumbers();
    const secondTab=currentUser?.data?.currency==='USD'?"Calling & SMS":'Calling'

    if (queryParameters.get("tab") === "Calling")
      setActiveTab(secondTab);

    // check if user has any org level settings permission
    if (canManage2FA || canManageCallDispositions || canManageOrgLevelIntegrations
       || canManagePrivateRecording || canManageRecordWarning || canManageReports) {
      setTabNames(["General", secondTab, "Account", "Integrations"]);
      retrieveOrganization();
    }
    else{
      setTabNames(["General", secondTab,"Integrations"]);
    }
    
  }, [canManage2FA, canManageCallDispositions, canManageOrgLevelIntegrations,
     canManagePrivateRecording, canManageRecordWarning, canManageReports, currentUser]);

  	// 2nd useEffect to handle use case of multiple account holder
	useEffect(() => {
		if (reduxAccount?.data?.profile?.account_holder) {
			if (reduxAccount?.allOrgs?.length !== 0) {
				let currentSelectedOrgObj = reduxAccount?.allOrgs?.find(
				(rd) => rd?.id === reduxAccount?.selectedOrg
				);
				// allOrgsResponseItem = currentSelectedOrgObj
				console.log(
				"currentSelectedOrgObj from all redux allorgs: ",
				currentSelectedOrgObj
				);
				setOrganization(currentSelectedOrgObj || {});
			} else {
				setShowLoader(true)
				JService.get(API_BASE_URL + "/api/v1/auth/organizations/")
				.then((res) => {
					if (!res?.success)
					throw new Error(
						res?.message || "Could not retrieve organizations"
					);

					dispatch(setAllOrgsDetails(res?.data));
					let currentSelectedOrgObj = res?.data?.find(
					(rd) => rd?.id === reduxAccount?.selectedOrg
					);
					// allOrgsResponseItem = currentSelectedOrgObj
					console.log(
					"currentSelectedOrgObj from all Orgs response: ",
					currentSelectedOrgObj
					);
					setOrganization(currentSelectedOrgObj || {});
					// prevOrgDetails.current = currentSelectedOrgObj
				})
				.catch((err) => console.warn(err?.message))
				.finally(() => setShowLoader(false))
			}
		} else {
			JService.get(API_BASE_URL + `/api/v1/auth/retrieve-organization/`)
			.then((response) => {
				if(!response?.success) throw new Error(response?.message || "Failed to retrieve organization data")
				console.log("retrieved org because not account holder and organization is null", response)
				setOrganization(response?.data);
			}).catch((error) => {
				console.error(error.response);
			});
		}
	}, [controller]);

  const retrieveAccountProfile = () => {
    JService.get(API_BASE_URL + "/api/v1/auth/retrieve-account-profile/")
      .then((response) => {
        setUserProfile(response.data);
      })
      .catch((error) => {
        console.error(error.response);
      });
  };

  const retrieveVirtualNumbers = () => {
    JService.get(API_BASE_URL + "/api/v1/auth/retrieve-virtual-numbers/")
      .then((response) => {
        // ************ OLD LOGIC ************

        // let formattedVirtualNumbers =
        //   response.data.data.inbound_virtual_numbers.concat(
        //     response.data.data.outbound_virtual_numbers
        //   );

        // // removing duplicates since user can have same number in inbound and outbound
        // const seen = new Set();
        // formattedVirtualNumbers = formattedVirtualNumbers.filter((vn) => {
        //   const duplicate = seen.has(vn.number);
        //   seen.add(vn.number);
        //   return !duplicate;
        // });
        // console.log("set user vns",formattedVirtualNumbers);
        // setUserVirtualNumber(formattedVirtualNumbers);

        // ************ NEW LOGIC ************

        let formattedVirtualNumbers = [];
        // dictionary to keep track of numbers which are already parsed
        let temp_dict = {};

        response?.data?.inbound_virtual_numbers.forEach((vn) => {
          temp_dict[vn.id] = true;
          formattedVirtualNumbers.push({ ...vn, incoming: true });
        });

        response?.data?.outbound_virtual_numbers.forEach((vn) => {
          if (temp_dict[vn.id]) {
            // find the index on the VN which needs to be updated
            const numberIndex = formattedVirtualNumbers.findIndex(
              (obj) => obj.id == vn.id
            );
            // update the vn
            formattedVirtualNumbers[numberIndex].outgoing = true;
          } else {
            formattedVirtualNumbers.push({ ...vn, outgoing: true });
          }
        });

        setUserVirtualNumber(formattedVirtualNumbers);
      })
      .catch((error) => {
        console.error(error.response);
      });
  };

  const retrieveOrganization = () => {
	// let _url = "" 
	// // check if selectedOrg is the primary org 
	// reduxAccount?.selectedOrg ===
    // JService.get(API_BASE_URL + `/api/v1/auth/retrieve-organization/`)
    //   .then((response) => {
    //     setOrganization(response.data);
    //   })
    //   .catch((error) => {
    //     console.error(error.response);
    //   });
	
	// guard against duplicate api calls on reload/first landing
	if(controller) setController(prev => ++prev)
  };

  const handleUserNameUpdate = (firstName, lastName) => {
    const data = {
      first_name: firstName,
      last_name: lastName,
    };
    JService.patch(API_BASE_URL + "/api/v1/auth/update-user/", data)
      .then((response) => {
        if (!response?.success)
          throw new Error(
            response?.message || "Failed to update phone number. "
          );
        setUserProfile((prevState) => {
          return { ...prevState, first_name: firstName, last_name: lastName };
        });
		enqueueSnackbar(response?.message, { variant: "success"})
      })
      .catch((error) => enqueueSnackbar(error?.message, { variant: "error"}))
  };

  const handleDefaultNumberUpdate=(data, defaultFor)=>{
     let payload={}
     if(defaultFor==='both'){
      payload['primary_virtual_number_id']=data?.id;
      payload['default_sms_number_id']=data?.id;
     }
     else {
      payload[defaultFor==='calling'? 'primary_virtual_number_id':'default_sms_number_id']=data?.id
     }
     JService.patch(
      API_BASE_URL + "/api/v1/auth/update-userprofile/",
      payload
    )
    .then((res)=>{
      if(!res?.success)
      throw new Error(res?.message || `Failed to update default ${defaultFor} number`)
       
      dispatch(saveAccountDetails({...reduxAccount,data:{...reduxAccount.data, profile:res.data}}))
      if(defaultFor==='calling'){
        if(res?.data?.primary_virtual_number)
        dispatch(reduxPersistSelectedVirtualNumber(res?.data?.primary_virtual_number))
      }
      enqueueSnackbar(`Successfully updated the default ${defaultFor} number`, {variant:'success'})
      retrieveVirtualNumbers();
    })
    .catch(err=>{
      enqueueSnackbar(err.message || `Failed to update default ${defaultFor} number`, {variant:'error'})
    })
  }

  const handleUpdateCallSettings=async (data)=>{
    try{
      const res=await JService.patch(API_BASE_URL+'/api/v1/auth/update-userprofile/',JSON.stringify(data));
      if(!res.success) throw new Error(res?.message || 'Failed to update user profile')
      setUserProfile(prev=>({...prev, profile:res?.data}))
      dispatch(saveAccountDetails({...reduxAccount, data:{...reduxAccount.data, profile:{...reduxAccount?.data?.profile, ...res?.data}}}))
      enqueueSnackbar(res?.message, {variant:'success'})
    }
    catch(err){
      enqueueSnackbar(err?.message,{variant:'error'})
      return {success:false, profile:userProfile}
    }
  }

  const handleUserPhoneNumberUpdate = (phone_number,country_code) => {

    const data = {
      number: country_code + phone_number,

    };
    JService.patch(API_BASE_URL + "/api/v1/auth/update-userphonenumber/", data)
      .then((response) => {
        if (!response?.success)
          throw new Error(
            response?.message || "Failed to update phone number"
          );
        setUserProfile((prevState) => {
          return {
            ...prevState,
            number: {
              country_code: response?.data?.country_code,
              phone_number: phone_number,
            },
          };
        });
		enqueueSnackbar(response?.message, { variant: "success"})
      })
      .catch((error) => enqueueSnackbar(error?.message, { variant: "error"}))
  };

  const handleBusinessHoursChange = (
    workingDays,
    workTimeStart,
    workTimeEnd,
    breakTimeStart,
    breakTimeEnd
  ) => {
    let data = {};
    let isValid = true;

    // Validations
    if (workTimeEnd < workTimeStart) {
	  enqueueSnackbar("Work Time (Start) must be before Work Time (End)", { variant: "error"})
      isValid = false;
    }
    if (breakTimeEnd < breakTimeStart) {
	  enqueueSnackbar("Break Time (Start) must be before Break Time (End)", { variant: "error"})
      isValid = false;
    }
    if (breakTimeStart < workTimeStart || breakTimeEnd > workTimeEnd) {
	  enqueueSnackbar("Break Time must be between Work Time Range", { variant: "error"})
      isValid = false;
    }

    if (!isValid) {
      return;
    }

    // Prepare payload for API
    const wkDays = {
      Monday: false,
      Tuesday: false,
      Wednesday: false,
      Thursday: false,
      Friday: false,
      Saturday: false,
      Sunday: false,
    };
    workingDays.forEach((item) => {
      wkDays[item] = true;
    });
    data["working_days"] = wkDays;

    data["work_time_start"] =
      workTimeStart.getHours() +
      ":" +
      workTimeStart.getMinutes() +
      ":" +
      workTimeStart.getSeconds();

    data["work_time_end"] =
      workTimeEnd.getHours() +
      ":" +
      workTimeEnd.getMinutes() +
      ":" +
      workTimeEnd.getSeconds();

    data["break_time_start"] =
      breakTimeStart.getHours() +
      ":" +
      breakTimeStart.getMinutes() +
      ":" +
      breakTimeStart.getSeconds();

    data["break_time_end"] =
      breakTimeEnd.getHours() +
      ":" +
      breakTimeEnd.getMinutes() +
      ":" +
      breakTimeEnd.getSeconds();

    console.log(data);
    JService.patch(API_BASE_URL + "/api/v1/auth/update-business-hours/", data)
      .then((response) => {
		enqueueSnackbar(response?.message, { variant: "success"})
      })
      .catch((error) => enqueueSnackbar(error?.message || "Failed to Edit Business Hours", { variant: "error"}))
  };

  const handlePasswordChange = (
    currentPassword,
    newPassword,
    confirmNewPassword
  ) => {
    const data = {
      current_password: currentPassword,
      new_password: newPassword,
      confirm_new_password: confirmNewPassword,
    };
    JService.post(API_BASE_URL + "/api/v1/auth/change-password/", data)
      .then((response) => {
        if (!response?.success)
          throw new Error(
            response?.message || "Failed to update phone number. "
          );
        
		enqueueSnackbar(response?.message, { variant: "success"})
      })
      .catch((error) => enqueueSnackbar(error?.message, { variant: "error"}))
  };

  const handleUpdateOrganization = async (data) => {
    try{
    let orgUrlParam = getOrgURLParam();
    console.log(`updating ${orgUrlParam} with`, data);
    if (data?.id !== reduxAccount?.selectedOrg) {
      return;
    }
    let _data={...data}
    delete _data['credit']
    delete _data['subscription']
    const response = await JService.patch(
      API_BASE_URL + `/api/v1/auth/update-organization/${orgUrlParam}`,
      _data
    );
        if(!response?.success) 
        throw new Error(response?.message || 'Failed to update organization details')
        setOrganization(response.data);
        enqueueSnackbar( response?.message, { variant: "success"})

        let sendAllOrgs = [
          ...currentUser?.allOrgs?.filter((ao) => ao?.id !== response.data?.id),
          response.data,
        ];
        dispatch(setAllOrgsDetails(sendAllOrgs));
      }
      catch(error){
        enqueueSnackbar(error?.message, { variant: "error"});
        return {success:false,organization:organization}
  };
}

  const handleGenerateApiKey = () => {
    JService.get(API_BASE_URL + "/api/v1/integrations/obtain-api-key")
      .then((response) => {
        console.log(response);
        setApiKey(response["api-key"]);
        enqueueSnackbar(response?.message, { variant: "success"})
      })
      .catch((error) => enqueueSnackbar(error?.message, { variant: "error"}))
  };

  const handleCopyApiKey = () => {
    navigator.clipboard.writeText(apiKey);
	enqueueSnackbar("API Key copied to clipboard", { variant: "success"})
  };

  const handleHubspotConnect = () => {
    JService.get(API_BASE_URL + "/api/v1/integrations/hubspot-oauth/")
      .then((response) => {
        console.log(response);
        window.location.href = response.url;
      })
      .catch((error) => {
        console.error(error.response);
      });
  };

  const handleHubspotDisconnect = () => {
    JService.get(API_BASE_URL + "/api/v1/integrations/hubspot-disconnect/")
      .then((response) => {
        setUserProfile((prevState) => {
          return {
            ...prevState,
            profile: {
              ...userProfile.profile,
              hubspot_access: false,
            },
          };
        });
		enqueueSnackbar(response?.message || "Disconnected Hubspot Successfully", { variant: "success"})
      })
      .catch((error) => enqueueSnackbar(error?.message || "Failed to disconnect Hubspot", { variant: "error"}))
  };

  const handlePipedriveConnect = () => {
    JService.get(API_BASE_URL + "/api/v1/integrations/pipedrive-oauth/")
      .then((response) => {
        console.log(response.url);
        window.location.href = response.url;
      })
      .catch((error) => {
        console.error(error.response);
      });
  };

  const handlePipedriveDisconnect = () => {
    JService.get(API_BASE_URL + "/api/v1/integrations/pipedrive-disconnect/")
      .then((response) => {
        setUserProfile((prevState) => {
          return {
            ...prevState,
            profile: {
              ...userProfile.profile,
              pipedrive_access: false,
            },
          };
        });
		enqueueSnackbar(response?.message || "Disconnected Pipedrive Successfully", { variant: "success"})
      })
      .catch((error) => enqueueSnackbar(error?.message || "Failed to disconnect Pipedrive", { variant: "error"}))
  };

  const handleZohoConnect = () => {
    JService.get(API_BASE_URL + "/api/v1/integrations/zoho-oauth/")
      .then((response) => {
        console.log(response.url);
        window.location.href = response.url;
      })
      .catch((error) => {
        console.error(error.response);
      });
  };

  const handleZohoDisconnect = () => {
    JService.get(API_BASE_URL + "/api/v1/integrations/zoho-disconnect/")
      .then((response) => {
        setUserProfile((prevState) => {
          return {
            ...prevState,
            profile: {
              ...userProfile.profile,
              zoho_access: false,
            },
          };
        });
		enqueueSnackbar(response?.message || "Disconnected Zoho CRM Successfully", { variant: "success"})
      })
      .catch((error) => enqueueSnackbar(error?.message, { variant: "error"}))
  };

  const handleZohoPhoneBridgeConnect = () => {
    JService.get(API_BASE_URL + "/api/v1/integrations/zoho-phonebridge-oauth/")
      .then((response) => {
        console.log(response);
        window.location.href = response.url;
      })
      .catch((error) => {
        console.error(error.response);
      });
  };

  const handleZohoPhoneBridgeDisconnect = () => {
    JService.get(
      API_BASE_URL + "/api/v1/integrations/zoho-phonebridge-disconnect/"
    )
      .then((response) => {
        setUserProfile((prevState) => {
          return {
            ...prevState,
            profile: {
              ...userProfile.profile,
              zoho_phonebridge_access: false,
            },
          };
        });
		enqueueSnackbar(response?.message || "Disconnected Zoho Phonebridge Successfully", { variant: "success"})
      })
      .catch((error) => enqueueSnackbar(error?.message, { variant: "error"}))
  };

  const handleDeskeraConnect = () => {
    JService.get(API_BASE_URL + "/api/v1/integrations/deskera-oauth/")
      .then((response) => {
        console.log(response.url);
        window.location.href = response.url;
      })
      .catch((error) => {
        console.error(error.response);
      });
  };

  const handleDeskeraDisconnect = () => {
    JService.get(API_BASE_URL + "/api/v1/integrations/deskera-disconnect/")
      .then((response) => {
        setUserProfile((prevState) => {
          return {
            ...prevState,
            profile: {
              ...userProfile.profile,
              deskera_access: false,
            },
          };
        });
		enqueueSnackbar(response?.message || "Disconnected Deskera Integration Successfully", { variant: "success"})
      })
      .catch((error) => enqueueSnackbar(error?.message || "Failed to disconnect Deskera Integration", { variant: "error"}))
  };

  const handleMicrosoftDynamicsConnect = () => {
    JService.get(API_BASE_URL + "/api/v1/integrations/microsoft-oauth/")
      .then((response) => {
        console.log(response.url);
        window.location.href = response.url;
      })
      .catch((error) => {
        console.error(error.response);
      });
  };
  
  const handleMicrosoftDynamicsDisconnect = () => {
    JService.get(API_BASE_URL + "/api/v1/integrations/microsoft-disconnect/")
      .then((response) => {
        setUserProfile((prevState) => {
          return {
            ...prevState,
            profile: {
              ...userProfile.profile,
              microsoft_access: false,
            },
          };
        });
        enqueueSnackbar(response?.message || "Disconnected Microsoft Dynamics Successfully", { variant: "success" });
      })
      .catch((error) => enqueueSnackbar(error?.message || "Failed to disconnect Microsoft Dynamics", { variant: "error" }));
  };
  const handleSalesForceConnect = () => {
    JService.get(API_BASE_URL + "/api/v1/integrations/salesforce-oauth/")
      .then((response) => {
        console.log(response.url);
        window.location.href = response.url;
      })
      .catch((error) => {
        console.error(error.response);
      });
  };
  
  const handlesalesForceDisconnect = () => {
    JService.get(API_BASE_URL + "/api/v1/integrations/salesforce-disconnect/")
      .then((response) => {
        setUserProfile((prevState) => {
          return {
            ...prevState,
            profile: {
              ...userProfile.profile,
              salesforce_access: false,
            },
          };
        });
        enqueueSnackbar(response?.message || "Disconnected Microsoft Dynamics Successfully", { variant: "success" });
      })
      .catch((error) => enqueueSnackbar(error?.message || "Failed to disconnect Microsoft Dynamics", { variant: "error" }));
  };
  const prevReduxOrg = useRef(reduxAccount?.selectedOrg);
  useEffect(() => {
    // reduxAccount changed
	if (
      prevReduxOrg.current !== reduxAccount?.selectedOrg &&
      (Boolean(reduxAccount?.selectedOrg) || reduxAccount?.selectedOrg === 0)
    ) {
      // setorgdetails again if allOrgs?.length > 1 && primaryAccount holder
      prevReduxOrg.current = "";
      // reduxOrg.selectedOrg
      setController((prevState) => ++prevState);
    }
  }, [reduxAccount]);

  if(showLoader) return <AnimationPage />

  return (
    <div
      className="screen-container "
      style={{
        paddingTop: "32px",
        height:
          document.documentElement.scrollHeight - 56 >= window.innerHeight
            ? "fit-content"
            : window.innerHeight,
      }}
    >
      <p className="t3 medium-font nc-gray-900">Settings</p>

      {userProfile && (
        <>
          {!!tabNames && (
            <div className="tab-selection">
              {tabNames.map((tabName, index) => {
                return (
                  <p
                    className={`b1 nc-gray-100 tab-label ${
                      tabName === activeTab && "selected-tab-label"
                    }`}
                    key={index}
                    onClick={() => setActiveTab(tabName)}
                  >
                    {tabName}
                  </p>
                );
              })}
            </div>
          )}

          {activeTab === "General" && (
            <GeneralTab
              userProfile={userProfile}
              userVirtualNumber={userVirtualNumber}
              handleUserNameUpdate={handleUserNameUpdate}
              handleBusinessHoursChange={handleBusinessHoursChange}
              handlePasswordChange={handlePasswordChange}
            />
          )}
          {(activeTab === "Calling & SMS" || activeTab==="Calling") && (
            <CallingSMSTab
                userVirtualNumber={userVirtualNumber}
                userProfile={userProfile}
                handleUserPhoneNumberUpdate={handleUserPhoneNumberUpdate}
                handleDefaultNumberUpdate={handleDefaultNumberUpdate}
                handleUpdateCallSettings={handleUpdateCallSettings}
            />
          )}
          {activeTab === "Account" && (
            <AccountTab
              organization={organization}
              handleUpdateOrganization={handleUpdateOrganization}
              apiKey={apiKey}
              handleGenerateApiKey={handleGenerateApiKey}
              handleCopyApiKey={handleCopyApiKey}
              currentUser={currentUser}
            />
          )}
          {activeTab === "Integrations" && (
            <IntegrationsTab
              hubspotAccess={userProfile.profile.hubspot_access}
              pipedriveAccess={userProfile.profile.pipedrive_access}
              dynamicsAccess={userProfile.profile.microsoft_access}
              zohoAccess={userProfile.profile.zoho_access}
              zohoPhonebridgeAccess={
                userProfile.profile.zoho_phonebridge_access
              }
              salesforceAccess={
                userProfile?.profile?.salesforce_access
              }
              deskeraAccess={userProfile.profile.deskera_access}
              handleHubspotConnect={handleHubspotConnect}
              handleHubspotDisconnect={handleHubspotDisconnect}
              handlePipedriveConnect={handlePipedriveConnect}
              handlePipedriveDisconnect={handlePipedriveDisconnect}
              handleZohoConnect={handleZohoConnect}
              handleZohoDisconnect={handleZohoDisconnect}
              handleZohoPhoneBridgeConnect={handleZohoPhoneBridgeConnect}
              handleZohoPhoneBridgeDisconnect={handleZohoPhoneBridgeDisconnect}
              handleDeskeraConnect={handleDeskeraConnect}
              handleDeskeraDisconnect={handleDeskeraDisconnect}
              handleDynamicsConnect={handleMicrosoftDynamicsConnect}
              handleDynamicsDisconnect={handleMicrosoftDynamicsDisconnect}
              handleSalesForceConnect={handleSalesForceConnect}
              handlesalesForceDisconnect={handlesalesForceDisconnect}
              canManageOrgLevelIntegrations={canManageOrgLevelIntegrations}
            />
          )}
        </>
      )}

    </div>
  );
}

export default Settings;
