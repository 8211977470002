import { useEffect, useState, useMemo } from "react";
import {Autocomplete, Box, TextField, ListItem} from '@mui/material';
import { SelectedUsersContainer } from "../components";
import GModal from "../../design/components/GModal";
import Button from "../../design/Button";
import styles from "../../design/styles";
import colors from "../../design/colors";
import AnimationPage from "../../shared/animation-page/AnimationPage";
import { JService } from "../../api-service/ApiService";
import { enqueueSnackbar } from "notistack";
import { topNavAccountSelectedAccountTickIcon } from "../../../utility-functions/assetLoader";

const CreateEditTeamModal = (props) => {
    const {visible, handleClose, mode, teamId, usersArray, refetchData} = props;
    const [values, setValues] = useState({name: '', members: []});
    const [showLoader, setShowLoader] = useState(false);

    const handleCancel = () => {
        setValues({name: '', members: []});
        handleClose();
    }

    const handleSave = () => {
        if (!showLoader) setShowLoader(true);
  
        let sendMemberIds = [
        ...values?.members?.reduce(function (x, y) {
            if (x.indexOf(y) < 0) x.push(y);
            return x;
        }, []),
        ];
  
        let finalSendData = sendMemberIds.map((sm) => parseInt(sm));
    
        const payload = {
        name: values?.name,
        members: finalSendData,
        };

        const url = mode === 'Create' ? `/api/v1/auth/teams/` : `/api/v1/auth/teams/${teamId}/`;
        if(mode === 'Create'){
            JService.post(url, payload)
            .then((res) => {
                if (!res?.success) throw new Error(res?.message || `Could not create team`);
                enqueueSnackbar(res?.message || `Created team successfully`, { variant: "success"})
                handleCancel();
            })
            .catch((err) => enqueueSnackbar(err?.message, { variant: "error"}))
            .finally(() => {
                setShowLoader(false);
                refetchData();
            });
        }
        else{
            JService.patch(url, payload)
            .then((res) => {
                if (!res?.success) throw new Error(res?.message || `Could not update team`);
                enqueueSnackbar(res?.message || `Updated team successfully`, { variant: "success"})
                handleCancel();
            })
            .catch((err) => enqueueSnackbar(err?.message, { variant: "error"}))
            .finally(() => {
                setShowLoader(false);
                refetchData();
            });
        }
    }

    const fetchTeamDetails = () => {
        setShowLoader(true);
        const payload = {}
        JService.get(`/api/v1/auth/teams/${teamId}/`)
        .then((res) => {
          if (!res?.success)
            throw new Error(res?.message || "Could not retreive teams");
          setValues({name: res.data.name, members: res.data.users.map(user => user.id)});
        })
        .catch((error) => enqueueSnackbar(error?.message, { variant: "error"})) 
        .finally(() => {setShowLoader(false)}) 
    } 

    const handleRemoveUser = (id) => {
        setValues(prev => ({ ...prev, members: prev.members.filter(uid => uid !== id) }))
    } 

    const handleAutocompleteChange = (_, newValue) => {
        const value = newValue[newValue.length - 1]
        if(!value || typeof value !== 'object') return;
        const members = [...new Set([...values.members, value.id])]
        setValues(prev => ({ ...prev, members }));
    };

    function handleNameChange(e) {
        const value = e.target.value;
        setValues((prevState) => {
          return { ...prevState, name: value };
        });
      }
    
    const formattedUsers = useMemo(() => {
        const usersObj = {}
        usersArray?.forEach((elem) => {
          usersObj[elem.id] = {...elem}
        });
        return usersObj
    }, [usersArray]);

    useEffect(() => {
        if(!visible) return;
        if(mode === 'Edit'){
            fetchTeamDetails();
        }
    }, [teamId, mode, visible]);

    const modalBody = showLoader ? <AnimationPage/> :
        <Box>
          <form style={localStyles.form}>
            <label style={localStyles.label} className="t7 medium-font nc-gray-900">
              Team name
              <input
                value={values.name || ''}
                type="text"
                data-addtype="name" 
                style={localStyles.textInput}
                onChange={handleNameChange}
            />
            </label>
            <label className="t7 medium-font nc-gray-900" style={localStyles.membersLabel}>Team members ({values.members?.length})</label>
            <Autocomplete
              multiple
              disableClearable
              options={usersArray?.map(user => ({ label: `${user.first_name} ${user.last_name} ${user.email}`, ...user }))}
              getOptionLabel={option => option.label}
              onChange={handleAutocompleteChange}
              value={values.members}
              renderTags={() => null}
              renderInput={params => <TextField {...params} placeholder="Enter name to add user" variant="outlined" size="small" />}
              renderOption={(props, option) => {
                const selected = values.members?.includes(option.id)
                return(
                <ListItem {...props} key={option.email} sx={{ bgcolor: selected ? "#F0FCF4" : "white", borderRadius: "5px", "&:hover": { bgcolor: "#F0FCF4" } }}>
                  <Box sx={{ flexGrow: 1, padding: '8px 10px' }}>
                    <p className="t7 regular-font nc-gray-900">{option.first_name + ' ' + option.last_name}</p>
                    <p className={`t7 regular-font nc-gray-500 secondary-text`}>{option.email}</p>
                  </Box>
                  <Box>
                    {selected && topNavAccountSelectedAccountTickIcon()}
                  </Box>
                </ListItem>
              )}}
              sx={{
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: "1px solid #34CB65 !important",
                }}}
            />
            <Box className="custom-scrollbar" sx={localStyles.usersContainer}>
              <SelectedUsersContainer selectedUsers={values.members} allUsers={formattedUsers} handleRemoveUser={handleRemoveUser} noSelectionText="No team members yet"/>
            </Box>
          </form>
        </Box>
    
    return (
        <GModal
            disableCloseButton={showLoader}
            closeModal={handleCancel}
            body={modalBody}
            noScrollbar={true}
            heading={
            <span className="t6 nc-gray-900 medium-font">{props.heading ?? `${mode} team` }</span>
            }
            visible={visible}
            modalStyle={{width: '520px'}}
            bodyWrapperStyle={{paddingRight: 0}}
            bottomButtons={[
            <Button
                disabled={false}
                hierarchy="white"
                variant="outlined"
                buttonText="Cancel"
                isLoading={false}
                onClick={handleCancel}
            />,
            <Button
                disabled={!values.name?.trim()?.length}
                hierarchy="green"
                variant="contained"
                buttonText="Save"
                isLoading={false}
                onClick={handleSave}
            />,
            ]}
      />
    )
}

export default CreateEditTeamModal;

const localStyles = {
	form: {
		display: "flex",
		flexDirection: "column",
		maxWidth: "100%"
	},
	label: {
		marginBottom: "24px",
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start",
		alignItems: "stretch",
	},
	textInput: {
		marginTop: "4px",
		padding: "8px 12px",
		borderRadius: "4px",
		border: "1px solid #E1E6E2",
	},

	membersLabel: {
		marginBottom: "4px" 
	},
	membersSelect: {
		alignSelf: "flex-start",
		minWidth: 200,
		width: "100%",
		maxWidth: "100%",
		outline: "none",
		borderRadius: "4px",
		padding: 0,
		marginBottom: "24px",
		...styles.t6,
		...styles.regular_font,
		...colors.nc_gray_900,
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #34CB65",
      boxShadow: "none"
    },
		"&:before": {
			outline: "none",
			border: "none",
		},
		"&:focus .MuiOutlinedInput-input": {
			border: "none",
			outline: "none",
		},
		"& .MuiOutlinedInput-input": {
			padding: "8px 12px 8px 12px",
			border: "none",
			outline: "none",
		},
		"& .Mui-disabled": {
			cursor: "not-allowed",
		},
	},
  usersContainer: { 
    border: '1px solid #E0E0E0', 
    borderRadius: '8px', 
    minHeight: '120px', 
    maxHeight: '136px', 
    overflowY: 'scroll', 
    marginTop: '12px' 
  }
}