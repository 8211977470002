// # components
import GBreadcrumbs from "./GBreadcrumbs";

// # css
import "./gheaderStyles.css";
import colors from "../colors";
import "rsuite/dist/rsuite.min.css";

// # lib
import React, { useEffect, useState } from "react";
import { Menu, MenuItem } from "@mui/material";

// # assets
import threedotsIcon from "../../../assets/gcall-logs-threedots-icon.svg";
import searchIcon from "../../../assets/gusers-and-teams-gtable-search-icon.svg";
import searchActiveIcon from "../../../assets/gusers-and-teams-gtable-search-active-icon.svg";
import breadcrumbsSeparatorIcon from "../../../assets/gusers-and-teams-breadcrumbs-separtor.svg";

const localStyles = {
  spanNoOverFlowStyle: {
    display: "inline-block",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "100%",
  },
};

export default function GHeader({
  breadcrumbs,
  title,
  threeDots,
  contentTitle,
  topHeader,
  topHeaderVisible = true,
  bottomHeader,
  separator,
  handleSetSearchText,
  gsepStyles = {},
}) {
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    if (bottomHeader && bottomHeader?.search) handleSetSearchText(searchText);
  }, [searchText]);

  // threedots
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [dropdownAnchorEl, setDropdownAnchorEl] = useState(null);

  function handleThreeDotsClick(e) {
    setIsDropdownVisible(true);
    setDropdownAnchorEl(e.target || e.currentTarget || null);
  }

  // when one of the options in the dropdown is clicked
  function handleDropdownClick(e, menuitemtype) {
    if (menuitemtype === "delete")
      threeDots?.modal?.setDeleteModalVisible(true);
    else return;
  }

  function threeDotsAutoClose(e) {
    const path = e.composedPath()?.map((pathItem) => {
      let newPathItem = null;
      newPathItem =
        pathItem?.tagName?.toLowerCase() +
        (pathItem?.className ? "." + pathItem?.className : "");
      return newPathItem;
    });

    // clicked outside
    if (!path?.length || path[0]?.includes("div.MuiBackdrop-root")) {
      setIsDropdownVisible(false);
      setDropdownAnchorEl(null);
      return;
    }
  }

  useEffect(() => {
    if (isDropdownVisible) window.addEventListener("click", threeDotsAutoClose);
    else window.removeEventListener("click", threeDotsAutoClose);

    return () => {
      window.removeEventListener("click", threeDotsAutoClose);
    };
  }, [isDropdownVisible]);
  // -- threedots end

  return (
    <>
      <div className="gheader-container">
        {/*breadcrumbs, title and header-threeDots */}
        {topHeaderVisible && (
          <>
            <div className="top-header-container">
              {/* breadcrumbs */}
              {breadcrumbs.isVisible && (
                <GBreadcrumbs
                  {...breadcrumbs}
                  separator={
                    <img
                      src={breadcrumbsSeparatorIcon}
                      alt="breadcrumbs separtor icon"
                    />
                  }
                />
              )}

              {title && (
                <div className="title">
                  {/* header/route title */}

                  <p
                    title={title}
                    style={{
                      ...localStyles?.spanNoOverFlowStyle,
                      maxWidth: "620px",
                      cursor: "default",
                    }}
                    className="t3 medium-font nc-gray-900 "
                  >
                    {title}
                  </p>

                  {/* threedots */}
                  {threeDots?.isVisible && (
                    <div
                      className="threedots-container"
                      style={{ cursor: "pointer" }}
                      onClick={handleThreeDotsClick}
                    >
                      <img
                        className={`threedots-${threeDots?.id}`}
                        data-callid={threeDots?.id}
                        onClick={(e) => handleThreeDotsClick(e)}
                        src={threedotsIcon}
                        alt="Three dots icon"
                      />
                    </div>
                  )}
                </div>
              )}
            </div>

            {/* separator that extends to both sides. Can be */}
            {/* placed in a child to render to parent's width */}
            <div className="gsep" style={gsepStyles}></div>
          </>
        )}
        {/*bottomHeader: subsection title, calendar, buttons */}
        <div
          className="bottom-header-container"
          style={{
            paddingTop: topHeaderVisible ? "24px" : "0",
            ...bottomHeader.style,
          }}
        >
          <div className="bottom-left-header">
            {bottomHeader.contentTitle && (
              <p className="t5 medium-font nc-gray-900">
                {" "}
                {bottomHeader.contentTitle || ""}{" "}
              </p>
            )}
            {bottomHeader.search && (
              <div
                className="left-header-item search"
                style={{
                  border: `1px solid ${colors.nc_gray_300}`,
                  maxHeight: "40px",
                  padding: "8px 12px",
                  borderRadius: "4px",
                  alignItems: "center",
                  display: "flex",
                  marginTop: "24px",
                }}
              >
                <img
                  onClick={(e) =>
                    document.getElementById("gheader-search")?.focus()
                  }
                  //style={{ ...rightHeaderIconStyle }}
                  src={searchText?.length > 0 ? searchActiveIcon : searchIcon}
                  alt="Search icon"
                />
                <input
                  id="gheader-search"
                  style={{
                    border: "none",
                    margin: "0 12px 0 6px",
                  }}
                  className="t6 regular-font"
                  type="search"
                  placeholder="Search"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </div>
            )}
          </div>
          {/* After clearing the start and end dates currently
			  there is no way to know the previously selected
			  dates */}
          <div className="bottom-right-header">
            {Array.isArray(bottomHeader?.buttons) &&
              bottomHeader?.buttons?.length > 0 &&
              bottomHeader.buttons.map((button, index) => (
                <div key={index}>{button}</div>
              ))}{" "}
            {/* // eslint-disable-line */}
          </div>
        </div>
      </div>

      {/* threedots dropdown */}
      <Menu
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        autoFocus={false}
        disableScrollLock={true}
        open={isDropdownVisible}
        anchorEl={dropdownAnchorEl}
      >
        {threeDots?.options?.map((threeDotOption, index) => (
          <MenuItem
            key={index}
            onClick={(e) =>
              handleDropdownClick(e, threeDotOption?.option?.toLowerCase())
            }
            sx={{ cursor: "pointer" }}
          >
            {threeDotOption?.option}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
