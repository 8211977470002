import { useState } from "react"
import { useLocation } from "react-router-dom";
import Teams from "./teams/Teams";
import styles from "./UsersAndTeam.module.css";
import Users from "./users/Users";
import Roles from "./roles/Roles";
import { useSelector } from "react-redux";

const tabs =['Users','Teams','Roles']

export default function UsersAndTeam(){

    const location=useLocation()
    const searchParams = new URLSearchParams(window.location.search);

    const {canViewUsers, canViewTeams, canManageRoles} = useSelector((state) => state?.auth?.permissions);
    
    const [activeTab, setActiveTab]=useState(searchParams.get('tab') || location?.state?.tab || 'Users')

    let tabs = [];
    if(canViewUsers) tabs.push('Users');
    if(canViewTeams) tabs.push('Teams');
    if(canManageRoles) tabs.push('Roles');

    return (
      <div>
        <div className={styles.tabContainer}>
          <hr />
          {tabs.map((tab, index) => {
            return (
              <p
                className={`${styles.tabLabel} ${
                  tab === activeTab ? styles.selectedTabLabel : ''
                } b2 regular-font`}
                key={index}
                onClick={() => setActiveTab(tab)}
              >
                {tab}
              </p>
            );
          })}
          <hr />
        </div>
        
        <div
          className="screen-container"
          style={{
            paddingTop: "24px",
            paddingLeft: '32px',
            height:
              document.documentElement.scrollHeight - 56 >= window.innerHeight
                ? "fit-content"
                : window.innerHeight,
          }}
        >
          {activeTab === 'Users' && <Users />}
          {activeTab === 'Roles' && <Roles/>}
          {activeTab === 'Teams' && <Teams />}
        </div>
      </div>
     );
     
}

