import { Tab } from "@mui/material";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";

const GTab = ({
  value,
  changeHandler,
  tabsStyle = {},
  tabsContainerStyle = {},
  tabs = [],
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        padding: "24px 0 36px 0",
        ...tabsContainerStyle,
      }}
    >
      <Tabs
        value={value}
        onChange={changeHandler}
        textColor="inherit"
        sx={{
          borderBottom: "1px solid #e4e4e7",
          "& .MuiTabs-indicator": {
            backgroundColor: "#219848 !important",
            borderRadius: "10px",
            width: "73px !important",
          },
          "& .MuiTab-root": {
            color: "#3F3F46",
            fontWeight: "400",
            fontFamily: "Inter",
            padding: "12px 0",
            maxWidth: "max-content",
            minWidth: "73px",
            marginRight: "20px",
          },
          "& .Mui-selected": {
            color: "#219848",
            fontWeight: "500",
          },
          ...tabsStyle,
        }}
        aria-label="tabs"
      >
        {tabs.length > 0 &&
          tabs.map((tabLabel, index) => (
            <Tab key={index} value={tabLabel.toLowerCase()} label={tabLabel} />
          ))}
      </Tabs>
    </Box>
  );
};

export default GTab;
