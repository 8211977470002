import { useState } from 'react';
import {Box} from '@mui/material';
import Button from '../../design/Button';
import GModal from '../../design/components/GModal';
import { enqueueSnackbar } from 'notistack';
import { JService } from '../../api-service/ApiService';

const DeleteTeamModal = (props) => {
    const {visible, handleClose, teamId, teamName, refetchData} = props;
    const [showLoader, setShowLoader] = useState(false);

    function handleDeleteTeam() {
        setShowLoader(true);
        JService.delete(`/api/v1/auth/teams/${teamId}/`)
          .then((res) => {
            if (!res?.success) throw new Error(res?.message || "Could not delete team");
            
            enqueueSnackbar(res?.message || "Team deleted", { variant: "success"})
            handleClose();
            refetchData();
          })
          .catch((error) => enqueueSnackbar(error?.message, { variant: "error"}))
          .finally(() => setShowLoader(false));
      }
    
    const modalBody = (
        <Box>
          <p className="regular-font t7 nc-gray-900" style={{lineHeight: '25.2px'}}>
          Are you sure you want to permanently delete &nbsp;
            <span
              className="medium-font t7 nc-gray-900"
            >
              {teamName}
            </span>
            &nbsp;team?
          </p>
        </Box>
      );
    return (
    <GModal
        // disableCloseButton={showLoader}
        closeModal={handleClose}
        body={modalBody}
        heading={
          <span className="t6 nc-gray-900 medium-font">Delete team</span>
        }
        visible={visible}
        modalStyle={{width: '448px', padding: '32px 24px', marginBottom: '0'}}
        noScrollbar={false}
        bottomButtons={[
          <Button
            disabled={showLoader}
            hierarchy="white"
            variant="outlined"
            buttonText="Cancel"
            isLoading={false}
            onClick={handleClose}
          />,
          <Button
            disabled={false}
            onClick={handleDeleteTeam}
            hierarchy="red"
            variant="contained"
            buttonText="Delete"
            isLoading={showLoader}
          />,
        ]}
    />
)}

export default DeleteTeamModal;