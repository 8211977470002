// # css
import styles from "./Roles.module.css";

// # components
import GHeader from "../../design/components/GHeader";
import Button from "../../design/Button";
import GTable from "../../design/components/GTable";
import GModal from "../../design/components/GModal";
import CreateEditRoleModal from "./CreateEditRoleModal";

// # assets
import userEditIcon from "../../../assets/call-logs-edit.svg";

// # lib
import { useEffect, useReducer, useState } from "react";
import { Box, Menu, MenuItem} from "@mui/material";
import { enqueueSnackbar } from 'notistack';

// # helper functions
import { JService } from "../../api-service/ApiService";
import { fetchData, filtersReducer } from "../../../utility-functions";
import { Filters } from "../../dashboard/Filters";

const initialState = {
  types: {
    all: [
      { id: 'default', name: 'Default' },
      { id: 'custom', name: 'Custom'}
  ],
  selected: []},
  searchText: "",
  searchFields: ["title"],
};
const filtersList = [{ name: "Type", toggle: false, isSearch: false, payloadFor: 'types'},]

export default function Roles() {
  const [rows, setRows] = useState([]);
  const [masterRows, setMasterRows] = useState([]);
  const [cols, setCols] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [controller, setController] = useState(1);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [dropdownAnchorEl, setDropdownAnchorEl] = useState(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [createEditModalVisible, setCreateEditModalVisible] = useState({visible: false, mode: ''});
  const [currentRole, setCurrentRole] = useState(null);
  const [defaultPermissions, setDefaultPermissions] = useState([]);
  const [csvData, setCSVData] = useState({
    headers: [["Role name"], ["Type"], ["Description"], ["Users"]],
    data: [],
    ids: [],
  });

  const [availableData, dispatchAvailableData] = useReducer(
    filtersReducer,
    initialState
  );

  const fetchRolesList = () => {
    setShowLoader(true);
    JService.get(`/api/v1/auth/roles/`)
      .then((res) => {
        if (!res?.success) throw new Error(res?.message || "Failed to retrieve roles");
        updateDefaultPermissions(res.data);
        setCols([
          {
            field: "title",
            headerName: "Role name",
            headerClassName: "user-table-header-user-name",
            cellClassName: "roles-table-cell-title",
            flex: 0.23,
            renderCell: (params) => (
              <Box sx={{ paddingLeft: '24px'}}>
                <p className="t7 nc-gray-900 medium-font">{params.row.title}</p>
              </Box>
            ),
          },
          {
            field: "is_custom",
            headerName: "Type",
            headerClassName: "user-table-header-user-name",
            flex: 0.15,
            renderCell: (params) => (
              <p className="t7 nc-gray-900 regular-font">
                {params.row.is_custom ? 'Custom' : 'Default'}
              </p>
            ),
          },
          {
            field: "description",
            headerName: "Description",
            headerClassName: "user-table-header-user-name",
            flex: 0.46,
            renderCell: (params) => (
              <p className="t7 nc-gray-900 regular-font">
                {params.row.description}
              </p>
            ),
          },
          {
            field: "users_count",
            headerName: "Users",
            headerClassName: "user-table-header-user-name",
            flex: 0.15,
            renderCell: (params) => (
              <p className="t7 nc-gray-900 regular-font">{params.row.users_count}</p>
            ),
          },
          {
            field: "roleOptions",
            headerName: "",
            flex: 0.15,
            cellClassName: "table-cell-edit",
            renderCell: (params) => {
              return (
                <Box data-teamid={params?.id}>
                  <img
                    data-teamid={params?.id}
                    onClick={(e) => handleThreeDotsClick(e, params?.row)}
                    src={userEditIcon}
                    alt="three dots icon"
                  />
                </Box>
              );
            },
          },
        ]);
        setRows(res.data || []);
        setMasterRows(res.data || []);
      })
      .catch((err) => enqueueSnackbar(err.message || "Failed to retrieve roles", { variant: "error" }))
      .finally(() => setShowLoader(false));
  }

  function handleDeleteRole(e) {
    JService.delete(`/api/v1/auth/roles/${currentRole?.id}/`)
      .then((res) => {
        if (!res?.success) throw new Error(res?.message || "Could not delete the role");
        enqueueSnackbar(res?.message || "Role deleted successfully", { variant: "success"})
        handleCloseDeleteModal();
        setController(prev => ++prev);
      })
      .catch((error) => enqueueSnackbar(error?.message, { variant: "error"}))
  }

  function updateDefaultPermissions(data) {
    const agentId = data.find(role => role.title === 'Agent' && !role.is_custom)?.id;
    const setPermissions = (data) => setDefaultPermissions(data.permissions?.map(p => p.id))
    fetchData(`/api/v1/auth/roles/${agentId}/`, setPermissions, 'role details');
  }

  function onSearch() {
    // after search call combineSearchAndFilters
    // simple search
    const searchTextLower = availableData?.searchText?.toLowerCase();
    let finalResult = [];
    availableData?.searchFields?.forEach((searchField) => {
      let intermediateResult = masterRows?.filter((row) =>
        row[searchField]?.toLowerCase()?.includes(searchTextLower)
      );
      finalResult = finalResult.concat(intermediateResult);
    });

    combineSearchAndFilters(finalResult);
  }

  function combineSearchAndFilters(arg1) {
    let temp = arg1;
    if(availableData?.types?.selected?.length){
      temp = temp.filter(elem => {
        const type = elem.is_custom ? 'custom' : 'default';
        return availableData?.types?.selected?.includes(type)
      })
    }

    // @note removing duplicates
    temp = temp?.reduce(function (x, y) {
      if (x.indexOf(y) < 0) x.push(y);
      return x;
    }, []);
    setRows(temp);
  }

  const handleCreateRoleClick = (e) => {
    if (deleteModalVisible) handleCloseDeleteModal();
    setCreateEditModalVisible({visible: true, mode: 'Create'});
  };

  const handleThreeDotsClick = (e, role) => {
    e.stopPropagation();
    setIsDropdownVisible(true);
    setDropdownAnchorEl(e.currentTarget || null);
    setCurrentRole(role);
  }

  const handleDropdownClick = (e) => {
    setIsDropdownVisible(false);
    setDropdownAnchorEl(null);
    const menuitemtype = e.target.dataset.menuitemtype;
    if (menuitemtype === "edit") {
      setCreateEditModalVisible({visible: true, mode: 'Edit'})
    }
    else if( menuitemtype === "delete"){
      setDeleteModalVisible(true);
    }
  }

  function handleCloseDeleteModal() {
    setDeleteModalVisible(false);
  }

  function handleCloseCreateEditModal(){
    setCreateEditModalVisible({visible: false, mode: ''});
    setCurrentRole({});
  }

  function threeDotsAutoClose(e) {
    const path = e.composedPath()?.map((pathItem) => {
      let newPathItem = null;
      newPathItem =
        pathItem?.tagName?.toLowerCase() +
        (pathItem?.className ? "." + pathItem?.className : "");
      return newPathItem;
    });

    if (!path?.length || path[0]?.includes("div.MuiBackdrop-root")) {
      // clicked outside
      setIsDropdownVisible(false);
      setDropdownAnchorEl(null);
      return;
    }
  }

  useEffect(() => {
    fetchRolesList();
  }, [controller]);

  useEffect(() => {
    setCSVData((prevState) => {
      let newIds = [];
      let newData = [];

      for (let i = 0; i < rows?.length; ++i) {
        let item = rows[i];
        if (!newIds?.includes(item?.id)) {
          newIds.push(item?.id);
          let temp = [
            item?.title,
            item?.is_custom ? 'Custom' : 'Default',
            item?.description,
            item?.users_count,
          ];
          newData.push(temp);
        }
      }
      return { ...prevState, data: newData, ids: newIds };
    });
  }, [rows]);

  useEffect(() => {
    onSearch();
  }, [availableData]); //eslint-disable-line

  useEffect(() => {
    if (isDropdownVisible) window.addEventListener("click", threeDotsAutoClose);
    else window.removeEventListener("click", threeDotsAutoClose);

    return () => {
      window.removeEventListener("click", threeDotsAutoClose);
    };
  }, [isDropdownVisible]);

  const createRoleButton = (
    <Button
      variant="filled"
      buttonText="Create Role"      
      isLoading={false}
      hierarchy="green"
      onClick={handleCreateRoleClick}
      styleOverride={{marginTop: '-24px', marginBottom: '24px'}}
	    className="ui--create-team"
    />
  );

  const deleteModalBody = deleteModalVisible ? (
    <Box>
      <p className="regular-font nc-gray-900 t7">
        Are you sure you want to delete the{" "}
        <span
          className="medium-font t7 nc-gray-900"
          style={{ textDecoration: "underline" }}
        >
          {currentRole?.title}
        </span>{" "}
        role?
      </p>
      <ul className={styles.deleteModal}>
        <li className="regular-font nc-gray-900 t7">
          All users with this role will lose their associated permissions.
        </li>
        <li className="regular-font nc-gray-900 t7">
        Users will be assigned default role ‘Agent’ if this was the only role assigned to them.
        </li>
      </ul>
    </Box>
  ) : null;

  return (
    <div>
      <GHeader
        breadcrumbs={false}
        threeDots={false}
        topHeader={{}}
        bottomHeader={{
          contentTitle: "",
          buttons: [],
          isCalendar: false,
        }}
      />
      <div className="filter-btn-container">
       <Filters
        filtersList={filtersList}
        dispatchAvailableData={dispatchAvailableData}
        availableData={availableData}
        dateFilter={false}
        fromScreen="roles"
      />
      {createRoleButton}
      </div>
      <Menu
        disableScrollLock={true}
        open={isDropdownVisible}
        anchorEl={dropdownAnchorEl}
      >
        <MenuItem
          data-menuitemtype="edit"
          onClick={handleDropdownClick}
          sx={{ cursor: "pointer" }}
        >
          Edit 
        </MenuItem>

        {currentRole?.is_custom && 
        <MenuItem
          data-menuitemtype="delete"
          onClick={handleDropdownClick}
          sx={{ cursor: "pointer" }}
        >
          Delete
        </MenuItem>}
      </Menu>

      <GTable
        leftHeader={{ filters: {}, isSearch: true }}
        dispatchAvailableData={dispatchAvailableData}
        availableData={availableData}
        rightHeader={{ isRefresh: true, isDownload: true }}
        download={{
          data: csvData,
          body: null,
          filename:
            "frejun-roles-" +
            new Date().toLocaleString("en-US", {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            }),
        }}
        rows={rows}
        fromScreen="roles"
        columns={cols}
        useMuiTable={true}
        isLoading={showLoader}
        additionalProps={{ bgHover: true }}
        onRowClick={() => null}
        onSearch={onSearch}
        combineSearchAndFilters={combineSearchAndFilters}
      />

      {/* delete team modal */}
      <GModal
        noScrollbar={true}
        disableCloseButton={showLoader}
        closeModal={handleCloseDeleteModal}
        body={deleteModalBody}
        heading={
          <span className="t6 nc-gray-900 medium-font">Delete Role</span>
        }
        modalStyle={{width: '400px', padding: '0', minWidth: '400px'}}
        bodyWrapperStyle={{padding: '32px 24px 12px 24px'}}
        visible={deleteModalVisible}
        bottomButtons={[
          <Button
            disabled={false}
            hierarchy="white"
            variant="outlined"
            buttonText="Cancel"
            isLoading={false}
            styleOverride={{fontSize: "14px", padding: '6px 12px'}}
            onClick={handleCloseDeleteModal}
          />,
          <Button
            disabled={false}
            onClick={handleDeleteRole}
            hierarchy="red"
            variant="contained"
            buttonText="Delete"
            styleOverride={{fontSize: "14px", padding: '6px 12px'}}
            isLoading={false}
          />,
        ]}
      />
      {/* create / edit Role modal */}
      <CreateEditRoleModal
        visible={createEditModalVisible.visible}
        mode={createEditModalVisible.mode}
        handleClose={handleCloseCreateEditModal}
        roleId={currentRole?.id}
        refetchData={fetchRolesList}
        defaultPermissions={defaultPermissions}
      />
    </div>
  );
}