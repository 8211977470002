import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  callRecordingDownloadIcon,
  callRecordingPlayIcon,
  callRecordingSettingsIcon,
  callRecordingTickIcon,
  chevronLeft,
  link,
} from "../../utility-functions/assetLoader";
import RecordingDetails from "./RecordingDetails";
import classes from "./CallRecordingNew.module.css";
import GetAIAddOn from "./GetAIAddOn";
import RecordingNotes from "./RecordingNotes";
import Insights from "./Insights";
import Transcript from "./Transcript";
import Summary from "./Summary";
import GTab from "../design/components/GTab";
import { JService } from "../api-service/ApiService";
import { enqueueSnackbar } from "notistack";
import {
  formatDate,
  getHMSfromMins,
  getOrgURLParam,
} from "../../utility-functions";
import { useSelector } from "react-redux";
import GBottomSaveBar from "../design/components/GBottomSaveBar";
import pauseIcon from "../../assets/pauseIcon.svg";
import wavesurfer from "wavesurfer.js";
import { ListItemIcon, Menu, MenuItem } from "@mui/material";
import colors from "../design/colors";
import styles from "../design/styles";
import AnimationPage from "../shared/animation-page/AnimationPage";
import { hasPermission } from "../features/permission/auth";

const PLAYBACK_SPEEDS = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 2];

const CallRecordingNew = ({
  isWidgetOpen,
  handleToggleWidget,
  shared = false,
  recordingDataShared = {},
}) => {
  const navigate = useNavigate();
  const { id: callId } = useParams();
  const API_BASE_URL = process.env.REACT_APP_BETA;
  const hasAIInsightsAddOn = useSelector(
    (state) => state?.account?.value?.data?.profile?.ai_insights
  );
  const maskNumbers = useSelector((state) => state?.auth?.maskNumbers);
  const isLoggedIn = useSelector((state) => state?.user?.value?.isLoggedIn);
  const ws = useRef();
  const audioRef = useRef();
  const plabackMenuAnchorRef = useRef();
  const downloadRef = useRef();

  //States
  const [firstTabValue, setFirstTabValue] = useState("details");
  const [secondTabValue, setSecondTabValue] = useState("notes");
  const [recordingDetails, setRecordingDetails] = useState();
  const [aiInsights, setAiInsights] = useState({});
  const [showBottomSaveBar, setShowBottomSaveBar] = useState(false);
  const [callStage, setCallStage] = useState("");
  const [callOutcomes, setCallOutcomes] = useState("");
  const [allTags, setAllTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [comments, setComments] = useState("");
  const [transcript, setTranscripts] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isPlaybackMenuVisible, setIsPlaybackMenuVisible] = useState(false);
  const [playbackSpeed, setPlaybackSpeed] = useState(1.0);
  const [loading, setLoading] = useState(false);

  //Handlers
  const backBtnHandler = () => {
    navigate("/call-logs");
  };

  const tabHandler1 = (event, newValue) => {
    setFirstTabValue(newValue);
  };

  const tabHandler2 = (event, newValue) => {
    setSecondTabValue(newValue);
  };

  const getSharableLink = () => {
    navigator.clipboard.writeText(recordingDetails?.sharable_url);
    enqueueSnackbar("Recording link copied to clipboard", {
      variant: "success",
    });
  };

  const cancelNotesHandler = () => {
    setCallOutcomes(recordingDetails?.call_outcome || "");
    setCallStage(recordingDetails?.call_reason || "");
    setSelectedTags(recordingDetails?.tags || []);
    setComments(recordingDetails?.notes || "");
  };

  const saveNotesHandler = () => {
    JService.patch("/api/v1/core/update-call-record/", {
      call_id: recordingDetails.id,
      call_reason: callStage,
      call_outcome: callOutcomes,
      tag_ids: selectedTags?.map((tag) => tag.id),
      notes: comments,
    })
      .then((response) => {
        if (!response?.success)
          throw new Error(
            response?.message || "Error occurred while updating call record"
          );

        enqueueSnackbar(response?.message || "Updated call record", {
          variant: "success",
        });
        setRecordingDetails(response?.data);
      })
      .catch((err) => enqueueSnackbar(err?.message, { variant: "error" }));
  };

  function handleDownload() {
    console.log("download container clicked")
    downloadRef.current.dispatchEvent(
      new MouseEvent('click', { 
      bubbles: true, 
      cancelable: true, 
      view: window 
      })
    )
  }

  function handleAnchorClick(e) {
    console.log("downloading recording")
    e.stopPropagation()
    return true
  }

  function handleOpenSettingsMenu() {
    setIsPlaybackMenuVisible((prev) => !prev);
  }

  function handlePlaybackPopoverClose() {
    setIsPlaybackMenuVisible(false);
  }

  function handleSeekToTime(seekToTime) {
    // seek to the time of the utterance
    if (!seekToTime) {
      console.warn("Invalid time");
      return;
    }
    ws.current?.setCurrentTime(seekToTime / 1000);
  }

  function handlePlaybackSpeedChange(speed) {
    setPlaybackSpeed(speed);
    ws.current.setPlaybackRate(speed);
    handlePlaybackPopoverClose();
  }

  //APIs
  const getSharedRecord = () => {
    setLoading(true);
    JService.get(
      API_BASE_URL + `/api/v1/core/retrieve-call-record/?call_id=${callId}`
    )
      .then((response) => {
        if (response?.success) {
          setRecordingDetails(response?.data);
          setCallOutcomes(response?.data?.call_outcome || "");
          setCallStage(response?.data?.call_reason || "");
          setSelectedTags(response?.data?.tags || []);
          setComments(response?.data?.notes || "");
        } else
          throw new Error(
            response?.message ||
              "Error occurred while fetching recording details"
          );
      })
      .catch((err) => enqueueSnackbar(err?.message, { variant: "error" }))
      .finally(() => setLoading(false));
  };

  const getAIInsights = (value = callId) => {
    JService.get(API_BASE_URL + `/api/v1/core/ai-insights/${value}/`)
      .then((response) => {
        if (response?.success) {
          setAiInsights(response?.ai_insights);
        } else {
          throw new Error(
            response?.message || "Error occurred while fetching AI Insights"
          );
        }
      })
      .catch((err) => enqueueSnackbar(err?.message, { variant: "error" }));
  };

  const getTranscripts = (value = callId) => {
    JService.get(API_BASE_URL + `/api/v1/core/fetch-transcript/${value}/`)
      .then((response) => {
        if (response?.success) {
          setTranscripts(response?.transcript);
        } else {
          throw new Error(
            response?.message || "Error occurred while fetching transcripts"
          );
        }
      })
      .catch((err) => enqueueSnackbar(err?.message, { variant: "error" }));
  };

  const getTags = () => {
    JService.get(
      API_BASE_URL + `/api/v1/auth/retrieve-tags/${getOrgURLParam()}`
    )
      .then((response) => {
        if (!response?.success)
          throw new Error(response?.message || "Could not retreive tags");
        setAllTags(response?.data);
      })
      .catch((err) => {
        enqueueSnackbar(err?.message, { variant: "error" });
      });
  };

  //Effects
  useEffect(() => {
    if (!shared) {
      getSharedRecord();
      getTags();
      if (hasAIInsightsAddOn && recordingDetails?.call_status === "answered") {
        getAIInsights();
        getTranscripts();
      }
    }
  }, [hasAIInsightsAddOn, shared, recordingDetails?.call_status]);

  useEffect(() => {
    if (shared && Object.keys(recordingDataShared).length > 0) {
      setRecordingDetails(recordingDataShared);
      setCallOutcomes(recordingDataShared?.call_outcome || "");
      setCallStage(recordingDataShared?.call_reason || "");
      setSelectedTags(recordingDataShared?.tags || []);
      setComments(recordingDataShared?.notes || "");
      if (hasAIInsightsAddOn) {
        getAIInsights(recordingDataShared?.id);
        getTranscripts(recordingDataShared?.id);
      }
    }
  }, [shared, recordingDataShared, hasAIInsightsAddOn]);

  useEffect(() => {
    const normalize = (value) =>
      value === null || value === undefined ? "" : value;

    if (
      normalize(callStage) !== normalize(recordingDetails?.call_reason) ||
      normalize(callOutcomes) !== normalize(recordingDetails?.call_outcome) ||
      !(
        selectedTags?.length === recordingDetails?.tags?.length &&
        selectedTags.every((tag) =>
          recordingDetails?.tags.some((rTag) => rTag.id === tag.id)
        )
      ) ||
      normalize(comments) !== normalize(recordingDetails?.notes)
    ) {
      setShowBottomSaveBar(true);
    } else {
      setShowBottomSaveBar(false);
    }
  }, [callStage, callOutcomes, selectedTags, comments, recordingDetails]);

  useEffect(() => {
    if (audioRef.current && !ws?.current && recordingDetails?.recording_url) {
      ws.current = wavesurfer.create({
        container: audioRef.current,
        mediaType: "audio",
        backend: "MediaElement",
        xhr: {
          cache: "default",
          mode: "no-cors",
          method: "GET",
          credentials: "include",
          headers: [
            { key: "cache-control", value: "no-cache" },
            { key: "pragma", value: "no-cache" },
          ],
        },
        barWidth: 4,
        barRadius: 3,
        barGap: 0,
        cursorWidth: 1,
        barMinHeight: 12,
        height: 80,
        barHeight: 90,
        waveColor: "#34CB65",
        progressColor: "#5F6661",
        responsive: true,
      });
      ws.current.load(recordingDetails?.recording_url);
      ws.current.drawBuffer();
      ws.current.on("ready", function () {});
    }

    return () => {
      ws.current?.destroy();
      ws.current = null;
    }
  }, [recordingDetails]);

  return (
    <>
      <div className={`${shared ? "" : "screen-container"}`}>
        {!shared && <button onClick={backBtnHandler} className={classes.backBtn}>
          {chevronLeft()}
          <span className="text-sm medium-font">Back to call logs</span>
        </button>}
        {loading ? (
          <AnimationPage />
        ) : (
          <div className={classes.container}>
            <div className={classes.header}>
              <div className={classes.personalDetails}>
                <div>
                  <span className="display-sm medium-font">
                    {recordingDetails?.candidate_details?.name
                      ?.slice(0, 1)
                      .toUpperCase() || "U"}
                  </span>
                </div>
                <div>
                  <h5 className="medium-font text-lg">
                    {recordingDetails?.candidate_details?.name || "Unknown"}
                  </h5>
                  {maskNumbers ? null : <h6 className="regular-font text-sm">
                    {recordingDetails?.candidate_details?.phone_number || "-"}
                  </h6>}
                </div>
              </div>
              <button
                className={classes.copyLinkBtn}
                onClick={() => getSharableLink()}
              >
                {link()}
                <span className="medium-font text-sm">Copy link</span>
              </button>
            </div>

            {!!recordingDetails?.recording_url &&
              recordingDetails?.duration !== 0 && (
                <div className={classes.recording}>
                  <div
                    style={{
                      borderWidth: ws.current?.isPlaying() ? "1.6px" : "0px",
                    }}
                    onClick={(e) => {
                      if (isPlaying) {
                        ws.current.pause();
                        setIsPlaying(false);
                      } else {
                        ws.current.play();
                        setIsPlaying(true);
                      }
                    }}
                    className={classes.pausePlayBtn}
                  >
                    {ws.current?.isPlaying() ? (
                      <img src={pauseIcon} alt="pause-icon" />
                    ) : (
                      callRecordingPlayIcon()
                    )}
                  </div>

                  <div className={classes.waveContainer} ref={audioRef} />

                  {/* speed */}
                  <div
                    ref={plabackMenuAnchorRef}
                    className={classes.settingsIconContainer}
                    onClick={handleOpenSettingsMenu}
                    style={{
                      background: isPlaybackMenuVisible
                        ? " #F7FAF8"
                        : "#ffffff",
                    }}
                  >
                    {callRecordingSettingsIcon()}
                  </div>
                  {/* download */}
                  {((!isLoggedIn && shared) || hasPermission('calling', 'download', recordingDetails?.creator_id)) && <div 
                    onClick={handleDownload} 
                    className='download-icon-container'
                  >
                    <a 
                      ref={downloadRef} 
                      onClick={handleAnchorClick} 
                      onMouseDown={handleAnchorClick} 
                      download target="_blank" 
                      href={recordingDetails?.recording_url}
                    >
                      {callRecordingDownloadIcon()} 
                    </a>
                  </div>}
                </div>
              )}

            <Menu
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              autoFocus={false}
              open={isPlaybackMenuVisible}
              anchorEl={plabackMenuAnchorRef?.current || null}
              onClose={handlePlaybackPopoverClose}
              sx={{
                "& .MuiPaper-root": {
                  width: "160px",
                  border: `1px solid ${colors.nc_gray_300}`,
                  borderRadius: "6px",
                },
              }}
            >
              <p
                style={{
                  color: colors.nc_gray_900,
                  ...styles.bold_font,
                  textAlign: "left",
                  paddingLeft: "16px",
                }}
              >
                Speed
              </p>
              {PLAYBACK_SPEEDS.map((speed) => (
                <MenuItem
                  key={speed}
                  sx={{
                    paddingRight: "0px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    backgroundColor:
                      speed === playbackSpeed
                        ? colors.ic_green_100
                        : colors.ic_white,
                    "&:hover": {
                      background: colors.nc_gray_100,
                    },
                  }}
                  onClick={() => handlePlaybackSpeedChange(speed)}
                  value={speed}
                >
                  {speed === 1 ? "Normal" : speed + "x"}
                  <ListItemIcon
                    style={{ marginLeft: "12px", paddingRight: "12px" }}
                  >
                    {speed === playbackSpeed && callRecordingTickIcon()}
                  </ListItemIcon>
                </MenuItem>
              ))}
            </Menu>

            <div className={classes.tabsContainer}>
              <div className={classes.tabSection1}>
                <GTab
                  value={firstTabValue}
                  changeHandler={tabHandler1}
                  tabs={["Details", "Insights"]}
                />
                {firstTabValue === "details" && (
                  <RecordingDetails
                    callDetails={[
                      {
                        label: "Date",
                        value: formatDate(recordingDetails?.start || ""),
                      },
                      {
                        label: "Call status",
                        value:
                          recordingDetails?.call_status
                            ?.charAt(0)
                            .toUpperCase() +
                            recordingDetails?.call_status?.slice(1) || "",
                      },
                      {
                        label: "Duration",
                        value: getHMSfromMins(recordingDetails?.duration || 0),
                      },
                      {
                        label: "Contacted by",
                        value: recordingDetails?.creator || "",
                      },
                      {
                        label: "Virtual number",
                        value:
                          recordingDetails?.virtual_number?.country_code +
                          " " +
                          recordingDetails?.virtual_number?.number,
                      },
                    ]}
                  />
                )}
                {firstTabValue === "insights" ? (
                  hasAIInsightsAddOn ? (
                    <Insights
                      talkRatio={[
                        {
                          name: "Speaker A",
                          percent: aiInsights?.talk_ratio?.["speaker A"],
                        },
                        {
                          name: "Speaker B",
                          percent: aiInsights?.talk_ratio?.["speaker B"],
                        },
                      ]}
                      sentiment={{
                        score: aiInsights?.sentiment_score?.percentage,
                        sentimentType: aiInsights?.sentiment_score?.sentiment,
                        summary: aiInsights?.sentiment_score?.sentiment_summary,
                      }}
                      // quesCovered={{
                      //   total: 1,
                      //   percent: 100,
                      //   quesList: [
                      //     {
                      //       ques: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et?",
                      //       answered: true,
                      //     },
                      //   ],
                      // }}
                    />
                  ) : (
                    <GetAIAddOn
                      handleToggleWidget={handleToggleWidget}
                      isWidgetOpen={isWidgetOpen}
                      tabName="insights"
                      style={{ width: "75%" }}
                    />
                  )
                ) : null}
              </div>
              <div className={classes.tabSection2}>
                <GTab
                  value={secondTabValue}
                  changeHandler={tabHandler2}
                  tabs={["Notes", "Summary", "Transcript"]}
                />
                {secondTabValue === "notes" && (
                  <RecordingNotes
                    callStage={callStage}
                    setCallStage={setCallStage}
                    callOutcomes={callOutcomes}
                    setCallOutcomes={setCallOutcomes}
                    selectedTags={selectedTags}
                    setSelectedTags={setSelectedTags}
                    allTags={allTags.filter(
                      (tag) =>
                        !selectedTags.some((selected) => selected.id === tag.id)
                    )}
                    comments={comments}
                    setComments={setComments}
                    shared={shared}
                  />
                )}
                {secondTabValue === "summary" ? (
                  hasAIInsightsAddOn ? (
                    <Summary
                      summary={aiInsights?.summary?.transcript_summary}
                      questions={aiInsights?.qa}
                      actionItems={aiInsights?.summary?.action_items}
                    />
                  ) : (
                    <GetAIAddOn
                      handleToggleWidget={handleToggleWidget}
                      isWidgetOpen={isWidgetOpen}
                      tabName="summary"
                      style={{ width: "45%" }}
                    />
                  )
                ) : null}
                {secondTabValue === "transcript" ? (
                  hasAIInsightsAddOn ? (
                    <Transcript
                      transcript={transcript}
                      handleSeekToTime={handleSeekToTime}
                    />
                  ) : (
                    <GetAIAddOn
                      handleToggleWidget={handleToggleWidget}
                      isWidgetOpen={isWidgetOpen}
                      tabName="transcript"
                      style={{ width: "45%" }}
                    />
                  )
                ) : null}
              </div>
            </div>
          </div>
        )}
      </div>
      <GBottomSaveBar
        show={shared ? false : showBottomSaveBar}
        onCancel={cancelNotesHandler}
        onSave={saveNotesHandler}
      />
    </>
  );
};

export default CallRecordingNew;
