// #css
import classes from "./CreateEditRoleModal.module.css";
import colors from "../../design/colors";

// # components
import Button from "../../design/Button";
import GModal from "../../design/components/GModal";
import GTab from "../../design/components/GTab";
import { SelectedUsersContainer } from "../components";
import AnimationPage from "../../shared/animation-page/AnimationPage";
import { CustomAvatar, CustomInput } from "../../design/components/customComponents";

// # assets
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { topNavAccountSelectedAccountTickIcon, checkboxUncheck, checkboxCheck} from "../../../utility-functions/assetLoader";

// # lib
import { Box, Select, MenuItem, Checkbox, List, ListItem, ListItemText, TextField, Typography, Grid, FormControl, Autocomplete} from "@mui/material";
import { useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { enqueueSnackbar } from "notistack";

// # helper functions
import { capitalizeString, fetchData, getPermissionId } from "../../../utility-functions";
import { JService } from "../../api-service/ApiService";

const tabs = ['Description', 'Permissions', 'Scope', 'Users']
const accessTypes = {2: 'Their own data', 3: 'Selected users and teams', 1: 'All users and teams'}

const CreateEditRoleModal = (props) => {
    const {visible, handleClose, mode, roleId, defaultPermissions} = props;
    const allPermissions = useSelector((state) => state.auth.permissionsList);
    const {canViewUsers, canViewTeams} = useSelector((state) => state?.auth?.permissions);

    const [showLoader, setShowLoader] = useState(false);
    const [allUsers, setAllUsers] = useState([]);
    const [allTeams, setAllTeams] = useState([]);
    const [activeTab, setActiveTab]=useState('description');
    const [isDefault, setIsDefault] = useState(false);
    const [title, setTitle] = useState({value: '', error: null});
    const [description, setDescription] = useState('');
    const [enabledPermissions, setEnabledPermissions] = useState(defaultPermissions);
    const [selectedCategory, setSelectedCategory] = useState()
    const [accessType, setAccessType] = useState(2);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedTeams, setSelectedTeams] = useState([]);
    const [selectedRoleUsers, setSelectedRoleUsers] = useState([]); 

    
    const permissionsOptions = useMemo(() => {
      const categories = new Set();
      allPermissions?.forEach(elem => 
        categories.add(capitalizeString(elem.category))
      );
      const arr = Array.from(categories);
      setSelectedCategory(arr[0]);
      return arr;
    }, [allPermissions]);

    const renderOptions = (category) => {
      return allPermissions
            .filter(elem => elem.category.toLowerCase() === category.toLowerCase())
            .map(elem => ({...elem, action: elem.action?.includes('sdk') ? 
              'Integrations (iFrame and SDK)' : capitalizeString(elem.action)}))
    }

    const updateDetails = (data) => {
      setIsDefault(!data.is_custom);
      setTitle({value: data.title, error: null});
      setDescription(data.description);
      setEnabledPermissions(data.permissions.map(elem => elem.id));
      setAccessType(data.scope.scope_type);
      setSelectedUsers(data.scope.users?.map(elem => elem.id));
      setSelectedTeams(data.scope.teams?.map(elem => elem.id));
      setSelectedRoleUsers(data.users?.map(elem => elem.id));
    }

    function handleSave(e) {
      if (!showLoader) setShowLoader(true);
      const payload = {
        title : title.value,
        description,
        permissions: enabledPermissions,
        scope: {
            scope_type: parseInt(accessType, 10),
        },
        users: selectedRoleUsers
      }
      if(accessType == 3){
        payload['scope']['users'] = selectedUsers;
        payload['scope']['teams'] = selectedTeams;
      }

      if(mode === 'Create'){
        JService.post(`/api/v1/auth/roles/`, payload)
        .then((res) => {
          if (!res?.success) throw new Error(res?.message || "Could not create role");
          enqueueSnackbar(res?.message || "Created role successfully", { variant: "success"})
          handleCancel();
      })
      .catch((err) => enqueueSnackbar(err?.message, { variant: "error"}))
      .finally(() => {
        setShowLoader(false);
        props.refetchData();
      });
      }
      else if(mode === 'Edit'){
        JService.patch(`/api/v1/auth/roles/${roleId}/`, payload)
        .then((res) => {
          if (!res?.success) throw new Error(res?.message || "Could not edit role");
          enqueueSnackbar(res?.message || "Updated role successfully", { variant: "success"})
          handleCancel();
      })
      .catch((err) => enqueueSnackbar(err?.message, { variant: "error"}))
      .finally(() => {
        setShowLoader(false);
        props.refetchData();
      });
      }
    }

    const fetchRoleDetails = async (id) => {
      try{
        setShowLoader(true);
        const res = await JService.get(`/api/v1/auth/roles/${id}/`);
        if(!res.success){
          throw new Error(res?.message || 'Failed to retrieve role details', {variant: 'error'});
        }
        updateDetails(res.data);
      }
      catch(err){
        handleCancel();
        enqueueSnackbar(err.message || 'Failed to retrieve role details', {variant: 'error'});
      }
      finally{
        setShowLoader(false)
      }
    }

    const handleCancel = () => {
      setTitle({value: '', error: null});
      setDescription('');
      setIsDefault(false);
      setActiveTab('description');
      setSelectedCategory(permissionsOptions?.[0]);
      setAccessType(2);
      setSelectedRoleUsers([]);
      setSelectedTeams([]);
      setSelectedUsers([]);
      setEnabledPermissions(defaultPermissions);
      handleClose();
    }

    const handleTabChange = (e, tab) => {
      setActiveTab(tab);
    }

    const handleTitleChange = (e) => {
      setTitle({value: e.target.value, error: null});
    }

    const handleRoleDescriptionChange = (event) => {
      setDescription(event.target.value);
    }

    const handleTogglePermission = (e, action) => {
      const add = e.target.checked;
      const permissions = [action.id];
      const unlinkedActionIds = [];
      let viewPermissionId = null;
      if(['contacts', 'users and roles', 'teams'].includes(action.category)){
        viewPermissionId = allPermissions.find(elem => elem.category === action.category.toLowerCase()
                                                       && elem.action.includes('view'))?.id                                                     
      }  
      if(['Send sms', 'Sms logs'].includes(action.action)){
        viewPermissionId = getPermissionId('sms', 'sms logs');
        unlinkedActionIds.push(getPermissionId('sms', 'message templates'))
      }
      if (add){
        if(viewPermissionId && !enabledPermissions.includes(viewPermissionId) && action.id != viewPermissionId && !unlinkedActionIds.includes(action.id)){
          permissions.push(viewPermissionId)
        }
        setEnabledPermissions(prev => ([...prev, ...permissions]));  
      }
      else{
        if(viewPermissionId && permissions.includes(viewPermissionId)){
          allPermissions.forEach(elem => {
            if(elem.category == action.category.toLowerCase() && !unlinkedActionIds.includes(elem.id)){
              permissions.push(elem.id)
            }
          })
        }
        setEnabledPermissions(prev => {
          let temp = [...prev];
          temp = temp.filter(elem => !permissions.includes(elem))
          return temp;
        });
      }
    }

    const handleAccessTypeChange = (e) => {
      setAccessType(e.target.value);
    }

    const handleUsersTeamsChange = (e, valueArr) => {
      const add = e.target.checked;
      const value = valueArr[valueArr.length - 1];
      if(value.type === 'user'){
        if(add){
          setSelectedUsers(prev => ([...prev, value.id]));
        }
        else{
          setSelectedUsers(prev => {
            let temp = [...prev];
            temp = temp.filter(elem => elem != value.id
          );
            return temp;
          });
        }
      }
      else if(value.type === 'team'){
        if(add){
          setSelectedTeams(prev => ([...prev, value.id]));
          setSelectedUsers(prev => {
            const users = new Set([...prev]);
            value.users.forEach(uid => users.add(uid));
            return Array.from(users);
          })
        }
        else{
          const users = new Set();
          const usersToRemove = [];
          selectedTeams.forEach(tid => {
              const teamUsers = allTeams[tid]?.users;
              if(tid !== value.id){
                teamUsers.forEach(id => users.add(id));
              }
              else{
                usersToRemove.push(...teamUsers)
              }
          });
          setSelectedUsers(prev => {
            let temp = [...prev];
            temp = temp.filter(uid => !(usersToRemove.includes(uid) && !users.has(uid)));
            return temp;
          })

          setSelectedTeams(prev => {
            let temp = [...prev];
            temp = temp.filter(elem => elem !== value.id);
            return temp;
          });
        }
      }
    }

    const handleAddRoleUser = (event, valueArr) => {
      const value = valueArr[valueArr.length - 1]
      if (
        value &&
        !selectedRoleUsers.some((uid) => uid === value.id)
      ) {
        setSelectedRoleUsers((prevUsers) => [...prevUsers, value.id]);
      }
    }

    const handleRemoveRoleUser = (uid)  => {
      if(title.value === 'Owner' && isDefault){
        if(selectedRoleUsers.length === 1){
          enqueueSnackbar('Cannot remove the only user with role Owner', {variant: 'error'})
          return;
        }
      }
      setSelectedRoleUsers(
        selectedRoleUsers.filter((id) => id !== uid),
      )
    }

    useEffect(() => {
      if(!visible) return;
      if(mode === 'Edit'){
        fetchRoleDetails(roleId)
      }
      else if(mode === 'Create'){
        setEnabledPermissions([...defaultPermissions]);
      }
    }, [roleId, mode, visible]);

    useEffect(() => {
      const setUsers = (users) => {
        setAllUsers(() => {
          const userObj = {};
          users.forEach(elem => {
            userObj[elem.id] = {...elem, type: 'user'}
            });
          return userObj;
        });
      }

      const setTeams = (teams) => {
        setAllTeams(() => {
          const teamObj = {};
          teams.forEach(elem => {
            teamObj[elem.id] = {...elem, type: 'team'}
          });
          return teamObj;
        });
      }

      if(allPermissions && Array.isArray(allPermissions) && allPermissions.length){
        if(canViewUsers){
          fetchData(`/api/v1/auth/retrieve-users/?permission_id=${getPermissionId(null, 'manage roles')}`,
            setUsers, 'users');
        }
        if(canViewTeams){
          fetchData(`/api/v1/auth/teams/?permission_id=${getPermissionId(null, 'manage roles')}`,
          setTeams, 'teams');
        }
      }
    }, [allPermissions, canViewTeams, canViewUsers]);

    const modalBody = (
      showLoader ? <AnimationPage/> :
        <Box>
          <form style={localStyles.form}>
            <CustomInput inputProps={{disabled:isDefault}} label="Role name" placeholder="Enter role name" type='text' value={title} handleChange={handleTitleChange} labelStyles={{marginLeft: 24, marginRight: 24}} style={{backgroundColor: isDefault ? colors.nc_gray_100 : colors.ic_white}}/>
            <hr className={classes.secSeparator}/>
            <GTab
              value={activeTab}
              changeHandler={handleTabChange}
              tabs={tabs}
              tabsContainerStyle={{padding: "0 24px"}}
            />
            <div className={classes.tabContent}>
              {activeTab === "description" && (
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <label className="t7 nc-gray-900 medium-font">
                    Role description
                  </label>
                  <textarea
                    disabled={isDefault}
                    value={description}
                    onChange={handleRoleDescriptionChange}
                    placeholder="Add a short description for the role"
                    className={`${classes.descriptionTextarea} ${isDefault && classes.disabled} nc-gray-900 t7 regular-font`}
                  ></textarea>
                </Box>
              )}
      
              {activeTab === "permissions" && (
                <Box
                  display="flex"
                  border={1}
                  borderColor="grey.300"
                  sx={{borderRadius: '6px'}}
                >
                  {/* Left Sidebar */}
                  <Box
                    width="240px"
                    sx={localStyles.scrollbar}
                  >
                    <List>
                      {permissionsOptions.map((option) => (
                        <ListItem
                          key={option}
                          selected={selectedCategory === option}
                          onClick={() => setSelectedCategory(option)}
                          sx={localStyles.listItem}
                        >
                          <p className={`t7 regular-font nc-gray-900 ${classes.categoryName} ${selectedCategory === option && 'medium-font'}`}>{option}</p>
                        </ListItem>
                      ))}
                    </List>
                  </Box>
      
                  {/* Right Content Area */}
                  <Box sx={ localStyles.rightContentArea } className="custom-scrollbar">
                    <Typography
                      variant="h7"
                      sx={{
                        paddingBottom: "24px",
                        fontFamily: "Inter",
                        fontWeight: "500",
                      }}
                    >
                      {selectedCategory}
                    </Typography>
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap={3}
                      sx={{ paddingTop: "24px" }}
                    >
                      {renderOptions(selectedCategory).map((action) => (
                        <Box key={action.id} display="flex" alignItems="center" gap={1}>
                          <label className="switch">
                            <input type="checkbox" disabled={isDefault} style={{ cursor: isDefault ? "not-allowed" : "pointer" }} checked={enabledPermissions.includes(action.id)} onChange={(e)=>handleTogglePermission(e, action)}/>
                            <span
                              className="slider round"
                              style={{ cursor: isDefault ? "not-allowed" : "pointer" }}
                            ></span>
                          </label>
                          <p className={`t7 regular-font nc-gray-900`}>{action.action}</p>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </Box>
              )}
      
              {activeTab === "scope" && (
                <Box sx={{ borderRadius: "8px" }}>
                  <Grid container alignItems="center">
                    <Grid sx={{ width: "230px" }}>
                      <FormControl fullWidth>
                        <p
                          className="t7 nc-gray-900 medium-font"
                          style={{ marginBottom: "8px" }}
                        >
                          Access to
                        </p>
                        {isDefault ? <div className={classes.accessTypeContainer}>
                          <p className="t7 regular-font nc-gray-900">{accessTypes[accessType]}</p>
                        </div> :
                        <Select
                          IconComponent={ExpandMoreIcon}
                          sx={localStyles.select}
                          value={accessType}
                          onChange={handleAccessTypeChange}
                          fullWidth
                          displayEmpty
                          renderValue={(selected) => <p className="t7 regular-font nc-gray-900">{accessTypes[selected] || "Select Access Type"}</p>}
                          MenuProps={{
                            sx: localStyles.selectMenu,
                            PaperProps: {
                              sx: localStyles.menuPaper
                            },
                          }}
                        >
                          {Object.entries(accessTypes).map((item) => (
                            <MenuItem
                              key={item[0]}
                              value={item[0]}
                              sx={{
                                padding: "12px, 16px, 12px, 16px",
                                height: "46px",
                                display: "flex",
                                justifyContent: "space-between",
                                "&.Mui-selected": {
                                  backgroundColor: "#F0FCF4",
                                  color: "#333333",
                                },
                                "&:hover": {
                                  backgroundColor: "#FAFAFA !important",
                                },
                              }}
                            >
                              <p className="t7 regular-font nc-gray-900">{item[1]}</p>
                              {accessType == item[0] && (
                               topNavAccountSelectedAccountTickIcon(colors.ic_green_300)
                              )}
                            </MenuItem>
                          ))}
                        </Select>
                        }
                      </FormControl>
                    </Grid>
                    <Grid sx={{ width: "322px", paddingTop: "27px" }}>
                      {accessType == 3 && (
                        <Autocomplete
                          multiple
                          forcePopupIcon={false}
                          disableClearable
                          disableCloseOnSelect
                          sx={{
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              border: "1px solid #34CB65 !important",
                            },
                            marginLeft: "8px",
                          }}
                          options={[...Object.values(allTeams || {}), ...Object.values(allUsers || {})]}
                          groupBy={(option) => option.type}
                          getOptionLabel={(option) => option.type === 'user' ? `${option.first_name} ${option.last_name} ${option.email}` : option.name}
                          value={ 
                            [, ...selectedTeams, ...selectedUsers]
                          }
                          onChange={handleUsersTeamsChange}
                          renderTags={() => ""}
                          renderValue={() => ''}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Enter name to add user or team"
                              variant="outlined"
                              size="small"
                            />
                          )}
                          renderGroup={(params) => {
                            return <li key={params.key} className={classes.group}>
                              <p className={`t7 medium-font nc-gray-900 ${classes.selectionHeader}`}>{capitalizeString(params.group) + 's'}</p>
                              <div>{params.children}</div> 
                              <div className={classes.groupBorder}></div>
                            </li>

                          }}
                          renderOption={(props, option, { selected }) => {
                            const isSelected = option.type === 'user' ? selectedUsers.includes(option.id) : selectedTeams.includes(option.id)
                            return (
                            <ListItem
                              {...props}
                              button
                              key={option.id}
                              sx={{
                                backgroundColor: isSelected
                                  ? "#F0FCF4 !important"
                                  : "white",
                                borderRadius: "5px",
                                "&:hover": { backgroundColor: "#F7FAF8 !important" },
                              }}
                            >
                            <Checkbox
                              icon={checkboxUncheck()}
                              checkedIcon={checkboxCheck()}
                              style={{ marginRight: 8 }}
                              checked={isSelected}
                            />
                            <ListItemText
                              primary={<p className="t7 regular-font nc-gray-900">{option?.type === 'user' ? option.first_name + ' ' + option.last_name : option.name}</p>}
                              secondary={<p className="t7 regular-font nc-gray-500">{option.email || `${option.total_members} users`}</p>}
                            />
                            </ListItem>
                            )}
                          }
                        />
                      )}
                    </Grid>
                  </Grid>
                  {accessType == 3 &&
                    <Box
                      className="custom-scrollbar"
                      sx={localStyles.usersContainer}
                    >
                    {selectedUsers.length === 0 && selectedTeams.length === 0 && 
                      <p className={`t7 regular-font nc-gray-500 ${classes.noSelectionText}`}>No users or teams selected</p>
                    } 
                    {selectedTeams.length > 0 && 
                      <>
                        <p className={`t7 medium-font nc-gray-900 ${classes.selectionHeader}`}>Teams</p>
                        {selectedTeams.map((tid, idx) => (
                          <Box
                            key={tid}
                            sx={{
                              ...localStyles.selectedObject,
                              borderBottom: idx !== selectedTeams.length - 1 ? "1px solid #FAFAFA" : 'none'
                            }}
                          >
                            <CustomAvatar name={allTeams[tid].name}/>
                            <Box sx={{ flexGrow: 1 }}>
                              <p className="t7 regular-font nc-gray-900">{allTeams[tid].name}</p>
                              <p className={`t7 regular-font nc-gray-500 ${classes.selectedSecondaryText}`}>
                                {`${allTeams[tid].total_members} users`}
                              </p>
                            </Box>
                          </Box>
                        ))}
                      </>
                      }
                    {(selectedUsers.length && selectedTeams.length) ? <div className={classes.groupBorder}></div> : null}
                    {selectedUsers.length > 0 && 
                      <>
                        <p className={`t7 medium-font nc-gray-900 ${classes.selectionHeader}`}>Users</p>
                        {selectedUsers.map((uid, idx) => (
                          <Box
                            key={uid}
                            sx={{
                              ...localStyles.selectedObject,
                              borderBottom: idx !== selectedUsers.length - 1 ? "1px solid #FAFAFA" : 'none'
                            }}
                          > 
                            <CustomAvatar name={allUsers[uid].first_name}/>
                            <Box sx={{ flexGrow: 1 }}>
                              <p className="t7 regular-font nc-gray-900">
                                {allUsers[uid].first_name + ' ' + allUsers[uid].last_name}
                              </p>
                              <p className={`t7 regular-font nc-gray-500 ${classes.selectedSecondaryText}`}>
                                {allUsers[uid].email}
                              </p>
                            </Box>
                          </Box>
                        ))}
                      </>
                      }
                    </Box>
                  }
                </Box>
              )}
              {activeTab === "users" && (
                <div>
                  <p className="t7 nc-gray-900 medium-font">
                    Role assigned to
                  </p>
                  <Autocomplete
                    forcePopupIcon={false}
                    multiple
                    disableCloseOnSelect
                    disableClearable
                    sx={{
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #34CB65 !important",
                      },
                      marginTop: '12px'
                    }}
                    size="small"
                    ListboxProps={{sx: {maxHeight: '206px'}}}
                    options={Object.values(allUsers)}
                    getOptionLabel={(option) => `${option.first_name} ${option.last_name} ${option.email}`}
                    value={selectedRoleUsers}
                    onChange={handleAddRoleUser}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Enter name to add user"
                        variant="outlined"
                      />
                    )}
                    renderTags={() => ""}
                    renderOption={(props, option) => {
                      const selected = selectedRoleUsers?.includes(option.id);
                      console.log(selectedRoleUsers, option.id, selected)
                      return (
                      <ListItem
                        {...props}
                        key={option.id}
                        sx={{
                          backgroundColor: selected ? "#F0FCF4 !important" : "white",
                          borderRadius: "5px",
                          "&:hover": { backgroundColor: "#F0FCF4 !important" },
                        }}
                      >
                        <Box sx={{ flexGrow: 1, padding: '12px 20px', ...localStyles.flexRow}}>
                         <div>
                          <p className="t7 regular-font nc-gray-900">{option.first_name + ' ' + option.last_name}</p>
                          <p className={`t7 regular-font nc-gray-500 ${classes.selectedSecondaryText}`}>{option.email}</p>
                         </div>
                         <span>{selected && topNavAccountSelectedAccountTickIcon()}</span>
                        </Box>
                      </ListItem>
                    )}}
                  />
                  <Box
                    className="custom-scrollbar"
                    sx={localStyles.usersContainer}
                  > 
                    <SelectedUsersContainer 
                      selectedUsers={selectedRoleUsers}
                      allUsers={allUsers}
                      handleRemoveUser={handleRemoveRoleUser}
                      noSelectionText="No users selected"
                    />
                  </Box>
                </div>
              )}
            </div>
          </form>
        </Box>
      );

    return (
        <GModal
            disableCloseButton={showLoader}
            closeModal={handleCancel}
            body={modalBody}
            heading={
            <span className="t6 nc-gray-900 medium-font">{mode} role</span>
            }
            visible={visible}
            noScrollbar={true}
            modalStyle={{width: 600, padding: '32px 0 0', marginBottom: 0}}
            bodyWrapperStyle={{height: 'min(520px, 85vh)', paddingRight: 0}}
            bottomButtons={[
            <Button
                disabled={false}
                hierarchy="white"
                variant="outlined"
                buttonText="Cancel"
                isLoading={false}
                onClick={handleCancel}
            />,
            <Button
                disabled={!title.value?.trim()?.length || !description?.trim()?.length} 
                hierarchy="green"
                variant="contained"
                buttonText="Save"
                isLoading={false}
                onClick={handleSave}
            />,
            ]}
      />
    )
}

export default CreateEditRoleModal;

const localStyles = {
	form: {
		display: "flex",
		flexDirection: "column",
	},
  listItem: {
    cursor: 'pointer',
    backgroundColor: "white",
    "&.Mui-selected": {
      backgroundColor: "#F0FCF4",
      borderRadius: "5px",
    },
    "&:hover": { backgroundColor: "#FAFAFA !important" },
  },
  select: {
    "& .MuiOutlinedInput-input": {
      padding: "8px 12px",
      border: "none",
      outline: "none",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #34CB65",
    },
  },
  selectMenu: {
    paddingTop: '4px',
    paddingBottom: '4px'
  },
  menuPaper: {
    width: "240px",
    marginLeft: "0px",
    boxShadow: "none",
    border: "1px solid #E1E5E2",
    padding: "0px",
  },
  scrollbar: {
    maxHeight: "270px",
    overflowY: "auto",
    padding: "0 8px",
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#F7FAF8",
      borderRadius: "0px",
      border: "1px solid #E1E5E2",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#D0D0D0",
      borderRadius: "10px",
    },
  },
  rightContentArea: { 
    padding: '24px 29px', 
    width: '310px', 
    maxHeight: "250px", 
    overflowY: 'scroll' 
  },
  usersContainer: {
    border: "1px solid #E0E0E0",
    borderRadius: "8px",
    marginTop: '12px',
    maxHeight: '198px',
    overflowY: 'scroll',
    minHeight: '120px'
  },
  selectedObject: {
    display: "flex",
    alignItems: "center",
    padding: "12px 16px",
  },
  flexRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row'
  }
}