import { stars } from "../../utility-functions/assetLoader";
import classes from "./GetAIAddOn.module.css";

const GetAIAddOn = ({ tabName, style, isWidgetOpen, handleToggleWidget }) => {
  //Handler
  const handleOpenChatWidget = () => {
    if (!isWidgetOpen) {
      handleToggleWidget();
    }
  };

  return (
    <div className={classes.container}>
      <div>{stars()}</div>
      <h4 className="medium-font text-lg">Call {tabName} with AI</h4>
      <h6 style={style} className="regular-font text-sm">
        Get call transcript, summary and insights with FreJun AI add-on.
      </h6>
      <button className="text-sm medium-font" onClick={handleOpenChatWidget}>
        Contact support
      </button>
    </div>
  );
};

export default GetAIAddOn;
