import { createSlice } from '@reduxjs/toolkit';

let initial = null
try{
    initial = JSON.parse(localStorage.getItem('frejun-current-user'))
}catch(err){
    console.warn(err, ' Setting up local storage for first (re)use.')
}

initial = initial?initial:{}

export const userSlice = createSlice({
    name: 'user',
    initialState: {value:Object.keys(initial).length === 0?{}:initial},
    reducers:{
        loginAttempt: (state, action) => {
            const { email } = action.payload
            // NOTE: login attempt details
			
            const _setStateValue = {...state, email, isLoggedIn:false}
            state.value = _setStateValue
            localStorage.setItem('frejun-current-user', JSON.stringify(state.value))
        },
        login: (state, action) => {
            // NOTE: AFTER successfully logging in details stored here
			
            const _setStateValue = {...state, email: action.payload?.email, isLoggedIn:true, source: action.payload?.source ?? ''}
            state.value = _setStateValue
            localStorage.setItem('frejun-current-user', JSON.stringify(state.value))
        },
        setRedirectOnAuth: (state, action) => {
            state.value = {...state, redirect: action.payload}
        },
        setNavigate: (state, action) => {
            state.value = {...state, navigate: action.payload}
            localStorage.setItem('frejun-current-user', JSON.stringify(state.value))
        },
        resetUserState: (state, action) => {
            state.value = {...state, source: null, navigate: null}
            localStorage.setItem('frejun-current-user', JSON.stringify(state.value))
        }
    }
})

export const { loginAttempt, login, setRedirectOnAuth, resetUserState, setNavigate } = userSlice.actions
export default userSlice.reducer