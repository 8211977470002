import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userRoles: localStorage.getItem('frejun-user-roles') ? JSON.parse(localStorage.getItem('frejun-user-roles')) : [], 
  permissionsList: localStorage.getItem('frejun-permissions-list') ? JSON.parse(localStorage.getItem('frejun-permissions-list')) : [],
  permissions: {},
  isOwner: false,
  maskNumbers: false,
  validPaths: {}
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserRoles: (state, action) => {
      state.userRoles = action.payload;
      localStorage.setItem('frejun-user-roles', JSON.stringify(action.payload));
    },
    setPermissionsList: (state, action) => {
      state.permissionsList = action.payload;
      localStorage.setItem('frejun-permissions-list', JSON.stringify(action.payload))
    },
    setPermissions: (state, action) => {
      state.permissions = action.payload
    },
    setIsOwner: (state, action) => {
      state.isOwner = action.payload;
    },
    setMaskNumbers: (state, action) => {
      state.maskNumbers = action.payload
    },
    setValidPaths: (state, action) => {
      state.validPaths = {...action.payload}
    },
    resetAuthState: (state, action) => {
      console.log('resetting auth state');
      state.validPaths = {};
      state.userRoles = [];
      state.permissionsList = [];
      state.permissions = {};
      state.isOwner = false;
      state.maskNumbers = false;
    }
  },
});

export const { 
  setUserRoles,
  setPermissionsList, 
  setPermissions,
  setIsOwner,
  setMaskNumbers,
  setValidPaths,
  resetAuthState
 } = authSlice.actions;
export default authSlice.reducer;
