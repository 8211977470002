import dotsBottom from '../../assets/login-dots-bottom.svg'
import dotsTop from '../../assets/login-dots-top.svg'
import vectorArt from '../../assets/login-vector-art.png'
import createPasswordVectorArt from '../../assets/create-password-vector-art.svg'
import logo from '../../assets/top-nav-logo.svg'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { JService } from '../api-service/ApiService'
import { useLocation, useNavigate } from 'react-router-dom'
import { CustomInput } from '../design/components/customComponents'
import { saveAccountDetails } from '../features/account/account'
import { enqueueSnackbar } from 'notistack'
import { login, setRedirectOnAuth } from '../features/user/user'

const API_BASE_URL = process.env.REACT_APP_BETA
function ForgotPasswordReset() {
    const dispatch = useDispatch()
    const location=useLocation();
    const navigate=useNavigate()

    const [password,setPassword]=useState({value:'',error:null})
    const [confirmPassword,setConfirmPassword]=useState({value:'',error:null})
	const [confirmMatch, setConfirmMatch] = useState(true)
    const [passwordStrength, setPasswordStrength] = useState(null)
    
    const handleFocus=(e)=>{
        const name=e.target.name
        if(name==='password')
            setPassword(prev=>({...prev,error:null}))
        else if(name==='confirmpassword')
            setConfirmPassword(prev=>({...prev,error:null}))
    }
    const handleSubmit = e => {
        e.preventDefault()
		if(!confirmMatch) {
            setPassword(prev=>({...prev,error:'Passwords do not match'}))
            setConfirmPassword(prev=>({...prev,error:'Passwords do not match'}))
            return;
        }

        JService.post(API_BASE_URL + '/api/v1/auth/reset-password/', JSON.stringify({password:password.value, confirm_password: confirmPassword.value}))
        .then(res => {
            if(!res?.success) throw new Error(res?.message || `Error occurred while resetting password`)
            dispatch(login({email: location?.state?.email}))
			enqueueSnackbar(res?.message || 'Password (re)set', { variant: "success"})
            if(location?.state?.from === 'invite') {
                JService.get("/api/v1/auth/retrieve-account-profile/")
                .then((res) => {
                    if (!res?.success) throw Error("Failed to fetch account details");
                    dispatch(saveAccountDetails(res));
                    dispatch(setRedirectOnAuth(true));
                })
                .catch((err) => {        
                  console.warn(err)
              })
                return;
            }
            navigate("/login", {replace: true})
        })
		.catch(err => enqueueSnackbar(err?.message || 'Error occurred while (re)setting password', { variant: "error"}))
    }

	useEffect(()=>{
		if(password.value !== confirmPassword.value) setConfirmMatch(false)
		else setConfirmMatch(true)
	}, [confirmPassword, password])
    const getPasswordStrength = (password) => {
        const criteria = {
            length: password.length >= 8,
            uppercase: /[A-Z]/.test(password),
            lowercase: /[a-z]/.test(password),
            digit: /\d/.test(password),
            specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
        };

        const fulfilledCriteria = Object.values(criteria).filter((criterion) => criterion).length;

        let score = 'Weak';
        if (fulfilledCriteria === 5) {
            score = 'Strong';
        } else if (fulfilledCriteria >= 3) {
            score = 'Medium';
        }

        return score;
    };
	const strengthColors = {
		Weak: "indianred",
		Medium: "orange",
		Strong: "#2eb258"
	};


    useEffect(() => {
        if (password.value) {
            const strength = getPasswordStrength(password.value);
            setPasswordStrength(strength);
        } else {
            setPasswordStrength(null);
        }
    }, [password]);

    return(
		<div className='login-container'>
			<div className='login-art'>
			<img src={ dotsTop }/>
            <a href="https://frejun.com/" target="_blank" rel="noopener noreferrer"> <img src={logo} alt="Logo" /></a>
			<img style={{width:'255px',height:'190px'}} src={ location?.state?.from === 'invite'?createPasswordVectorArt:vectorArt }/>
			<img src={ dotsBottom }/>
			{location?.state?.from!=='invite' && <p className='normal-font-poppins px-18'>Simplify and Automate<br/>Your Calling Process</p>}
			</div>
			<div className='login-form' style={{justifyContent:'center'}}>
				<p className='title t4 medium-font' style={{marginTop:'0',marginBottom:'56px',textAlign:'left'}}>{location?.state?.from === 'invite'?'Create':'Reset'} Your Password</p>
				<form onSubmit={e => handleSubmit(e)}>
						{/* <label className='t7 medium-font'>
					{location?.state?.from === 'invite'?'':'New'} Password
						<input style={{border: confirmMatch?'1px solid #C5C5C5':'1px solid #C44444', borderRadius: "3px"}} className='normal-font-poppins px-14' name='password' type='password' value={password} onChange={e => handleInputChange(e)} />
					</label>
					<label className='t7 medium-font'>
						Confirm Password
						<input style={{border: confirmMatch?'1px solid #C5C5C5':'1px solid #C44444', borderRadius: "3px"}} className='normal-font-poppins px-14' name='confirmpassword' type='password' value={confirmPassword} onChange={e => handleInputChange(e)} />
					</label>  */}
					<CustomInput label={`${location?.state?.from === 'invite'?'':'New'} Password`} value={password} handleChange={(e)=>setPassword(prev=>({...prev,value:e.target.value}))} type="password" name="password" handleFocus={handleFocus}/>
                    {passwordStrength&&(<div  style={{ color: strengthColors[passwordStrength]  }}>Password Strength :{passwordStrength}</div>)}<br/>
					<CustomInput label='Confirm password' value={confirmPassword} handleChange={(e)=>setConfirmPassword(prev=>({...prev,value:e.target.value}))} type="password" name="confirmpassword" handleFocus={handleFocus}/>
					
					<input id='reset-password-button' className='btn reset-password-btn login-btn medium-font t7 btn-green' type='submit' value={`${location?.state?.from === 'invite'?'Create':'Reset'} Password`} onSubmit={e => handleSubmit(e)} onClick={e => handleSubmit(e)}/>
					{location?.state?.from !== 'invite' && <a href='/login' className='to-login normal-font-poppins px-14 color-green'>Return to login</a>}
				</form>
			</div>
		</div>
    )
}
export default ForgotPasswordReset
