import vectorArt from '../../assets/signup-screen2.svg'
import dotsBottom from '../../assets/login-dots-bottom.svg'
import dotsTop from '../../assets/login-dots-top.svg'
import logo from '../../assets/top-nav-logo.svg'

import { useEffect, useRef, useState } from 'react'

import { loginScreen2Validation } from '../../request-handlers/validation/request-validation'
import { useDispatch, useSelector } from 'react-redux'
import {loginAttempt} from '../features/user/user'
import { saveAccountDetails } from '../features/account/account'

import { enqueueSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'

const API_BASE_URL = process.env.REACT_APP_BETA
function ForogotPasswordVerify() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const userLoginAttemptWith = useSelector(state=> state.user.value)
    const [loginScreen2Values, setLoginScreen2Values] = useState({
        field1: '',
        field2: '',
        field3: '',
        field4: '',
        field5: '',
        field6: ''
    })

    function listenToPasteEvent(e)
    {
        const pasteContent = (e.clipboardData || window.clipboardData).getData('text')
        if(pasteContent === pasteContent.toUpperCase() && pasteContent.length === 6){
            // a code is copied
            const _fromClipboard = pasteContent.toUpperCase()
            setLoginScreen2Values(prevState => {
                return {
                    field1: _fromClipboard[0],
                    field2: _fromClipboard[1],
                    field3: _fromClipboard[2],
                    field4: _fromClipboard[3],
                    field5: _fromClipboard[4],
                    field6: _fromClipboard[5]
                }
            })
        }
    }
    useEffect(() => {
        document.addEventListener('paste', listenToPasteEvent)
        return () => {
            document.removeEventListener('paste', listenToPasteEvent)
        }
    }, [])

    useEffect(()=>{
        const _payload = {verification_code: [loginScreen2Values.field1, loginScreen2Values.field2, loginScreen2Values.field3, loginScreen2Values.field4, loginScreen2Values.field5, loginScreen2Values.field6].join('')}
        const  isValid  = loginScreen2Validation(_payload)
        const verifyButton = document.getElementById('login-passcode-verify')
        if(verifyButton)verifyButton.disabled = !isValid
    }, [loginScreen2Values])

    const field1Ref = useRef()
    const field2Ref = useRef()
    const field3Ref = useRef()
    const field4Ref = useRef()
    const field5Ref = useRef()
    const field6Ref = useRef()
    
    const handleChange = e => {

        const _field = e.target.dataset.loginscreen2
        const _value = e.target.value
        if(_value.trim().length === 0  && _value === ' ') return
        const _inputType = e.nativeEvent.inputType
        if(_field === '1'){
            if(_inputType === 'deleteContentBackward' || _inputType === 'deleteContentForward') field1Ref.current.focus()
            else if(_inputType === 'insertText') field2Ref.current.focus()
        }
        else if(_field === '2'){
            if(_inputType === 'deleteContentBackward' || _inputType === 'deleteContentForward') field1Ref.current.focus()
            else if(_inputType === 'insertText') field3Ref.current.focus()
        }
        else if(_field === '3'){
            if(_inputType === 'deleteContentBackward' || _inputType === 'deleteContentForward') field2Ref.current.focus()
            else if(_inputType === 'insertText') field4Ref.current.focus()
        }
        else if(_field === '4'){
            if(_inputType === 'deleteContentBackward' || _inputType === 'deleteContentForward') field3Ref.current.focus()
            else if(_inputType === 'insertText') field5Ref.current.focus()
        }
        else if(_field === '5'){
            if(_inputType === 'deleteContentBackward' || _inputType === 'deleteContentForward') field4Ref.current.focus()
            else if(_inputType === 'insertText') field6Ref.current.focus()
        }
        else if(_field === '6'){
            if(_inputType === 'deleteContentBackward' || _inputType === 'deleteContentForward') field5Ref.current.focus()
            else if(_inputType === 'insertText') field6Ref.current.focus()
            // field6Ref.current.focus()
        }
        setLoginScreen2Values(prevState => {
            if(_field === '1') {
                return {...prevState, field1: _value.toUpperCase()}
            }
            else if(_field === '2') {
                return {...prevState, field2: _value.toUpperCase()}
            }
            else if(_field === '3') {
                return {...prevState, field3: _value.toUpperCase()}
            }
            else if(_field === '4') {
                return {...prevState, field4: _value.toUpperCase()}
            }
            else if(_field === '5') {
                return {...prevState, field5: _value.toUpperCase()}
            }
            else if(_field === '6') {
                return {...prevState, field6: _value.toUpperCase()}
            }
        })
    }
    const handleSubmit = e => {
        e.preventDefault()
        
        if(loginScreen2Validation(loginScreen2Values)){
			const payload = {
				email: userLoginAttemptWith.email,
				code: Object.values(loginScreen2Values).join(""),
				source:'reset'
			}
            fetch(API_BASE_URL + '/api/v1/auth/validate-code/?webapp=True',{
                method:'post',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: "include",
            })
            .then(res => {
                if(res?.status === 500) throw new Error('Server error please try again or if issue persists contact FreJun.')
                return res?.json()
            })
            .then(res => {
                if(!res?.success) throw new Error(res?.message)
				
				// successfully authenticated
				
                dispatch(loginAttempt({email: userLoginAttemptWith?.email?.value}))
                

				// make call to fetch user details 
				fetch(API_BASE_URL + '/api/v1/auth/retrieve-account-profile/', {
                    credentials:'include',
					method: 'get',
				})
				.then(_res => {
					if(_res?.status === 500) throw new Error('Server error please try again or if issue persists contact FreJun.')
					return _res?.json()
				})
				.then(_res => {
					if(!_res?.success) throw Error(_res?.message || 'Failed to fetch account details')
					
					dispatch(saveAccountDetails(_res))
                    navigate('/forgot-password/reset', {replace: true, state: {email: userLoginAttemptWith?.email?.value}})
				})
				.catch(err => enqueueSnackbar(err?.message || 'Error occurred while fetching account details', { variant: "error"}))
                
            })
            .catch(err => enqueueSnackbar(err?.message || 'Error occurred while verifying code', { variant: "error"}))
			.finally(()=>{
                // reset passCode values
                setLoginScreen2Values({
                    field1: '',
                    field2: '',
                    field3: '',
                    field4: '',
                    field5: '',
                    field6: ''
                })
            })
        }
    }

    const handleResendCode = e => {
        const _body = {
            email :  userLoginAttemptWith?.email,
            source: 'reset'
        }
        fetch(API_BASE_URL + '/api/v1/auth/send-code/', {
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'post',
            body: JSON.stringify(_body)
        }).then(res => {
            if(res?.status === 500) throw new Error('Server error please try again or if issue persists contact FreJun')
            return res?.json()
        })
        .then(res => {
            if(!res?.success) throw Error(res?.message || 'Fetching account details failed')
            enqueueSnackbar((res?.message || 'Check for the verification code in your email' ) +  '. Look in ' + userLoginAttemptWith?.email, { variant: "success"})
        })
        .catch(err => enqueueSnackbar(err?.message || 'Error occurred while resending the code', { variant: "error"}))
		.finally(()=>{
            // reset code
            setLoginScreen2Values({
                field1: '',
                field2: '',
                field3: '',
                field4: '',
                field5: '',
                field6: ''
            })
        })
    }

    return(
		<div className='login-screen2-container'>
			<div className='signup-art'>
            <a href="https://frejun.com/" target="_blank" rel="noopener noreferrer"> <img src={logo} alt="Logo" /></a>
				<img src={ dotsTop }/>
				<img src={ vectorArt }/>
				<img src={ dotsBottom }/>
			</div>
			<div className='login-screen2-form'>
				<p className='title medium-font t5'>Enter verification code </p>
				<form onSubmit={handleSubmit}>
					<p className='sub-title regular-font t7'>Please enter the verfication code sent to <br/> <u className='medium-font t7'>{ userLoginAttemptWith?.email }</u></p>
					<div className='fields'>
						<input ref={field1Ref} type='text' maxLength='1' autoComplete='none' data-loginscreen2='1' onChange={e => handleChange(e)} value={loginScreen2Values.field1}/>
						<input ref={field2Ref} type='text' maxLength='1' autoComplete='none' data-loginscreen2='2' onChange={e => handleChange(e)} value={loginScreen2Values.field2}/>
						<input ref={field3Ref} type='text' maxLength='1' autoComplete='none' data-loginscreen2='3' onChange={e => handleChange(e)} value={loginScreen2Values.field3}/>
						<input ref={field4Ref} type='text' maxLength='1' autoComplete='none' data-loginscreen2='4' onChange={e => handleChange(e)} value={loginScreen2Values.field4}/>
						<input ref={field5Ref} type='text' maxLength='1' autoComplete='none' data-loginscreen2='5' onChange={e => handleChange(e)} value={loginScreen2Values.field5}/>
						<input ref={field6Ref} type='text' maxLength='1' autoComplete='none' data-loginscreen2='6' onChange={e => handleChange(e)} value={loginScreen2Values.field6}/>
					</div>
					<hr />
					<p className='send-again regular-font t7'>Didn't receive the email? <span className='color-green' onClick={handleResendCode}>Send again</span></p>
					<input id='login-passcode-verify' type='submit' className='btn btn-green t7 medium-font' value='Verify' onSubmit={handleSubmit}/>
				</form>
			</div>
		</div>
    )
}

export default ForogotPasswordVerify