import classes from "./RecordingDetails.module.css";

const RecordingDetails = ({ callDetails }) => {
  return (
    <form className={classes.detailForm}>
      {callDetails.map((item, id) => (
        <div key={id}>
          <label htmlFor={id} className="text-sm medium-font">
            {item.label}
          </label>
          <input
            id={id}
            className="text-sm regular-font"
            type="text"
            value={
              item.label === "Duration"
                ? `${item.value.hrs ? item.value.hrs + "h" : ""} ${item.value.mins}m ${item.value.secs}s`
                : item.value
            }
            disabled
          />
        </div>
      ))}
    </form>
  );
};
export default RecordingDetails;
