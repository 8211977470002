import { useNavigate } from "react-router-dom";
import {
  dropdownIcon,
  errorIcon,
} from "../../../utility-functions/assetLoader";
import { playButtonIcon } from "../../../utility-functions/assetLoader";
import GModal from "./GModal";
import "./customComponents.css";
import { useState } from "react";
import Button from "../Button";
import { ExpandMore, Visibility, VisibilityOff } from "@mui/icons-material";
import { useEffect } from "react";
import searchIcon from "../../../assets/SearchIcon.svg";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Chip } from "@mui/material";
import AnimationPage from "../../shared/animation-page/AnimationPage";
import { topNavAccountSelectedAccountTickIcon } from "../../../utility-functions/assetLoader";
import commonStyles from "../commonStyles";
import { MenuItem, Select, Avatar } from "@mui/material";
import colors from "../colors";
import chipDeleteIcon from "../../../assets/gusers-and-teams-chip-delete-icon.svg";
import styles from "../styles";

export const CustomInput = ({
  value,
  handleChange,
  handleFocus,
  label,
  CustomError,
  name,
  type,
  children,
  style,
  inputProps,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <label className="medium-font t7 textfield-label" style={{...props.style, ...props.labelStyles}}>
      {label}
      <div
        className={`${props.dropdown ? "phone-number" : ""}`}
        style={{ position: "relative" }}
      >
        {props?.dropdown && props.dropdown}
        <input
          placeholder={props.placeholder}
          onFocus={handleFocus}
          onBlur={props?.handleBlur}
          className={`regular-font t7 ${value?.error ? "input-error" : ""}`}
          name={name}
          type={showPassword ? "text" : type}
          value={value?.value || ""}
          onChange={handleChange}
          style={style}
          {...inputProps}
        />
        {CustomError && <span className="error-message">{CustomError}</span>}
        {type === "password" && value.value && (
          <span
            style={{
              position: "absolute",
              top: "55%",
              transform: "translateY(-50%)",
              right: "8px",
              cursor: "pointer",
            }}
            onClick={togglePasswordVisibility}
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </span>
        )}

        {value?.error && <div className="error-icon">{errorIcon()}</div>}
      </div>
      {value.error && (
        <p style={props.errorStyle} className="t7 regular-font">
          {value.error}
        </p>
      )}
      {children}
    </label>
  );
};

export const CCDropdown = ({
  handleCountrySelect,
  selectedCountryCode,
  CCDropdownstyle,
}) => {
  const [ccData, setCCData] = useState([]);
  const [searchedCountryCode, setSearchedCountryCode] = useState("");
  // const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const [countryCodeList, setCountryCodeList] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  useEffect(() => {
    fetchCountries();
  }, []);

  const fetchCountries = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_BETA + "/api/v1/auth/locations/"
      );
      const data = await response.json();
      setCCData(data);
      setCountryCodeList(data);
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };

  function handleCCSearchChange(e) {
    const search = e.target.value;
    setSearchedCountryCode(search);
    // filter from ccData and update ccList
    if (!search || search?.trim()?.length === 0) {
      setCountryCodeList(ccData);
      return;
    }
    const lowercaseSearch = search.toLowerCase();
    const temp = ccData.filter(
      (cc) =>
        `${cc.country.toLowerCase()} +${cc.country_code} ${cc.iso_code.toLowerCase()}`.includes(
          lowercaseSearch
        ) || lowercaseSearch.includes(cc.iso_code.toLowerCase())
    );
    setCountryCodeList(temp);
  }

  const handleCountryCodeSelect = (countryCode) => () => {
    // setSelectedCountryCode(countryCode);
    handleCountrySelect(countryCode);
    setDropdownVisible(false);
  };

  const uniqueCountryCodes = [
    ...new Set(countryCodeList.map((cc) => cc.country_code)),
  ];

  return (
    <>
      <div
        className="cc-container"
        onClick={() => setDropdownVisible((prev) => !prev)}
      >
        {selectedCountryCode} <ExpandMore />
      </div>
      {dropdownVisible && (
        <div className="dialer--cc_dropdown_container cc-dropdown">
          <div className="dialer--cc_search">
            <img src={searchIcon} alt="" />
            <input
              style={CCDropdownstyle}
              type="search"
              value={searchedCountryCode}
              onChange={handleCCSearchChange}
              placeholder="Search"
              title="Enter country name to search"
              disabled={!ccData.length}
            />
          </div>
          <ul className="dialer--cc_options">
            {ccData.length > 0 ? (
              uniqueCountryCodes.map((countryCode) => {
                const ccObj = countryCodeList.find(
                  (cc) => cc.country_code === countryCode
                );
                return (
                  <li
                    key={ccObj.id}
                    value={ccObj.country_code}
                    onClick={handleCountryCodeSelect(ccObj.country_code)}
                    style={{
                      backgroundColor:
                        selectedCountryCode === ccObj.country_code
                          ? "#f0fcf4"
                          : "",
                    }}
                  >
                    <div>
                      <span
                        className={
                          selectedCountryCode === ccObj.country
                            ? "dialer--cc_name"
                            : ""
                        }
                      >
                        {`${ccObj.country} (${ccObj.country_code})`}
                      </span>
                    </div>
                    {selectedCountryCode === ccObj.country_code && (
                      <span style={{ float: "right" }}>
                        {topNavAccountSelectedAccountTickIcon()}
                      </span>
                    )}
                  </li>
                );
              })
            ) : (
              <AnimationPage />
            )}
          </ul>
        </div>
      )}
    </>
  );
};

export const CustomSwitch = ({
  checked,
  onChange,
  label,
  description,
  labelStyles = {},
}) => {
  return (
    <div className="switch custom-switch" style={{ marginTop: "32px" }}>
      <label>
        <input
          type="checkbox"
          checked={checked}
          onChange={onChange}
          style={{ cursor: "pointer", marginTop: "0" }}
        />
        <span className="slider round"></span>
      </label>
      <p
        style={{
          marginTop: "-45px",
          marginLeft: "60px",
          width: "555px",
          ...labelStyles,
        }}
        className="t7 medium-font"
      >
        {label}
      </p>
      <p
        className="t7 regular-font"
        style={{ width: "555px", marginLeft: "60px", marginTop: "0" }}
      >
        {description}
      </p>
    </div>
  );
};

const PaymentReminderCard = ({ planName, dueDate, onPayNow }) => {
  return (
    <div
      style={{
        border: "1px solid #E0544C",
        borderRadius: "8px",
        padding: "16px",
        maxWidth: "748px",
        backgroundColor: "#fff",
        color: "#464D48",
      }}
    >
      <p
        style={{
          fontSize: "16px",
          fontWeight: "600px",
          color: "#464D48",
          marginBottom: "10px",
        }}
      >
        Action Required: Complete Payment
      </p>
      <p style={{ fontSize: "16px", lineHeight: "2", fontWeight: "400" }}>
        Your payment for the <span>{planName}</span> is due.
      </p>
      <p style={{ fontSize: "16px", lineHeight: "1", marginBottom: "24px" }}>
        To maintain uninterrupted access to all features, please complete your
        payment by <span>{dueDate}</span>.
      </p>
      <div style={{ textAlign: "right" }}>
        <Button
          hierarchy="green"
          buttonText="Pay now"
          onClick={onPayNow}
          styleOverride={{
            height: "36px",
            fontSize: "14px",
            lineHeight: "14px",
            fontWeight: "400",
            padding: "10px 16px",
          }}
        />
      </div>
    </div>
  );
};

export default PaymentReminderCard;

export const VideoLinkModal = ({
  visible,
  handleClose,
  heading,
  text,
  url,
  ...props
}) => {
  return (
    <GModal
      visible={visible}
      closeModal={handleClose}
      bottomButtons={[]}
      heading={heading}
      headingStyle={{
        backgroundColor: "#fff",
        borderBottom: "none",
        marginLeft: "25%",
        paddingTop: "8px",
        ...props.headingStyle,
      }}
      modalStyle={{ paddingTop: "0px" }}
      bodyWrapperStyle={{ maxWidth: "448px" }}
      body={
        <div>
          <p
            className="t7 regular-font"
            style={{
              textAlign: "center",
              margin: "8px auto 16px",
              color: "#000",
              lineHeight: "180%",
            }}
          >
            {text}
          </p>
          <div
            style={{
              borderRadius: "10px",
              background: "#CAFCDA",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "413px",
              width: "400px",
            }}
          >
            <a href={url || ""}>
              <span>{playButtonIcon()}</span>
            </a>
          </div>
        </div>
      }
    />
  );
};

export const InsufficientCreditsModal = ({
  visible,
  handleClose,
  handlePayWithCard,
  handleAddCredits,
}) => {
  const navigate = useNavigate();
  const handleNavigateToCredits = () => {
    handleClose();
    navigate("/billing", { state: { tab: "Credits" } });
  };
  return (
    <GModal
      heading="Insufficient credits"
      visible={visible}
      closeModal={handleClose}
      bottomButtons={[
        <Button
          styleOverride={btnStyle}
          hierarchy="green"
          buttonText="Pay with card"
          onClick={handlePayWithCard}
          disabled={false}
        />,
        <Button
          styleOverride={btnStyle}
          hierarchy="green"
          buttonText="Add credits"
          onClick={handleAddCredits || handleNavigateToCredits}
          disabled={false}
        />,
      ]}
      body={
        <p className="t7 regular-font">
          Add account credits or pay with Debit/Credit card.
        </p>
      }
      modalStyle={{ width: "430px", minHeight: "auto", padding: "32px" }}
      bottomButtonsStyle={{ borderTop: "none", paddingTop: "8px" }}
      bodyWrapperStyle={{ paddingRight: 0 }}
    />
  );
};

export const PaymentMethodModal = ({
  visible,
  handleClose,
  handlePayWithCard,
  handlePayWithCredits,
}) => {
  return (
    <GModal
      heading="Select payment method"
      visible={visible}
      closeModal={handleClose}
      bottomButtons={[
        <Button
          styleOverride={btnStyle}
          hierarchy="white"
          buttonText="Pay with credits"
          onClick={handlePayWithCredits}
          disabled={false}
        />,
        <Button
          styleOverride={btnStyle}
          hierarchy="green"
          buttonText="Pay with card"
          onClick={handlePayWithCard}
          disabled={false}
        />,
      ]}
      body={
        <p className="t7 regular-font">
          Pay using account credits or Debit/Credit card.
        </p>
      }
      modalStyle={{ width: "430px", minHeight: "auto", padding: "32px" }}
      bottomButtonsStyle={{ borderTop: "none", paddingTop: "8px" }}
      bodyWrapperStyle={{ paddingRight: 0 }}
    />
  );
};
/**
 *
 * @param {string} 		  				              label
 * @param {Boolean} 		  			              disabled
 * @param {object} 			  		  	            value
 * @param {Function}   			                  handleChange
 * @param {object}             			          defaultValue
 * @param {string}   					                renderVal
 * @param {array}						                  options
 * @param {StyleSheet}					              labelStyles
 * @param {StyleSheet}                        selectStyles
 * @returns {import('react').ReactNode}				reusable select component.
 */

export const CustomSelect = ({
  label = "",
  disabled = false,
  value,
  handleChange = () => null,
  defaultValue = "Select",
  renderVal,
  options,
  labelStyles = {},
  selectStyles,
  defaultValStyles,
  extraOptions,
}) => {
  const isSelected = (selected, value) =>
    value !== null &&
    (typeof value === "object"
      ? value?.id === selected?.id
      : value === selected);
  return (
    <>
      <label
        className="t7 nc-gray-900 medium-font"
        style={{ marginBottom: "4px", ...labelStyles }}
      >
        {label}
      </label>
      <Select
        disabled={disabled}
        value={value}
        IconComponent={ExpandMore}
        onChange={handleChange}
        sx={{ ...commonStyles.select, ...selectStyles }}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        renderValue={
          typeof renderVal === "function"
            ? renderVal
            : (selected) => {
                if (!selected) {
                  return (
                    <p
                      style={{ ...defaultValStyles }}
                      className="t7 regular-font nc-gray-400"
                    >
                      {defaultValue}
                    </p>
                  );
                } else {
                  return (
                    <p className="t7 regular-font">
                      {renderVal ? selected[renderVal] : selected}
                    </p>
                  );
                }
              }
        }
      >
        {options.map((opt) => (
          <MenuItem
            value={opt.value}
            sx={{
              ...commonStyles.menu_item,
              backgroundColor: isSelected(value, opt.value)
                ? colors.ic_green_100
                : `${colors.ic_white} !important`,
            }}
            disabled={opt.value === null}
          >
            <span>{opt.displayValue}</span>
            <span>
              {isSelected(value, opt.value) &&
                topNavAccountSelectedAccountTickIcon()}
            </span>
          </MenuItem>
        ))}
        {extraOptions && extraOptions.map((opt) => opt)}
      </Select>
    </>
  );
};
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#F5F7F6",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#F5F7F6",
    color: "#464D48",
    boxShadow: `0 4px 8px rgba(0, 0, 0, 0.1)`,
    padding: "12px",
    fontSize: "14px",
    fontWeight: "500px",
  },
}));
export const CustomTooltip = ({ tooltipText, tooltipTitle, ...props }) => (
  <BootstrapTooltip title={tooltipTitle} arrow placement="top-start">
    {tooltipText ? (
      <Typography
        className="toolTip"
        style={{ fontSize: "14px", color: "#464D48", fontFamily: "inter" }}
      >
        {tooltipText}
      </Typography>
    ) : props.children ? (
      props.children
    ) : (
      <Typography
        className="toolTip"
        style={{ fontSize: "14px", color: "#464D48", fontFamily: "inter" }}
      ></Typography>
    )}
  </BootstrapTooltip>
);

export const CustomChip = ({ label, onDelete, ...props }) => {
  return (
    <Chip
      sx={{
        borderRadius: "4px",
        border: "1px solid #E1E6E2",
        marginRight: "8px",
        height: "24px",
        width: "fit-content",
        color: colors.nc_gray_900,
        ...styles.regular_font,
        ...styles.t7,
        ...props.style,
      }}
      deleteIcon={<img src={chipDeleteIcon} alt="Remove icon" />}
      variant="outlined"
      onMouseDown={(e) => e.stopPropagation()}
      onDelete={onDelete}
      key={props.key || label || "1"}
      label={label}
    />
  );
};
const btnStyle = {
  height: "36px",
  fontSize: "14px",
  lineHeight: "14px",
  fontWeight: "400",
  padding: "10px 16px",
  border: "1px solid lightgray",
};

export const CustomAvatar = ({ name }) => {
  return (
    <Avatar
      sx={{
        bgcolor: "#F7FAF8",
        color: "gray",
        border: "1px solid #E0E0E0",
        marginRight: "10px",
      }}
    >
      {name?.charAt(0)?.toUpperCase()}
    </Avatar>
  );
};
