import classes from "./RecordingNotes.module.css";
import { CustomSelect } from "../design/components/customComponents";
import { useSelector } from "react-redux";
import { Autocomplete, TextField, Chip } from "@mui/material";
import { styled } from "@mui/material/styles";
import chipDeleteIcon from "../../assets/gusers-and-teams-chip-delete-icon.svg";

const RecordingNotes = ({
  callStage,
  setCallStage,
  callOutcomes,
  setCallOutcomes,
  allTags,
  selectedTags,
  setSelectedTags,
  comments,
  setComments,
  shared,
}) => {
  const organizationData = useSelector(
    (state) => state?.account?.value?.allOrgs?.[0]
  );

  //Handlers
  const tagsChangeHandler = (event, newValue) => {
    if (!shared) {
      setSelectedTags(newValue);
    }
  };

  return (
    <form className={classes.notesForm}>
      <CustomSelect
        label={"Call stage"}
        labelStyles={labelStyles}
        value={callStage}
        handleChange={(e) => setCallStage(e.target.value)}
        defaultValue={"Select stage"}
        options={
          organizationData?.call_reasons?.map?.((reason) => ({
            value: reason,
            displayValue: reason,
          })) || []
        }
        selectStyles={selectStyles}
        defaultValStyles={{ color: "#A0A0AB" }}
        disabled={shared}
      />

      <CustomSelect
        label={"Call outcomes"}
        labelStyles={labelStyles}
        value={callOutcomes}
        handleChange={(e) => setCallOutcomes(e.target.value)}
        defaultValue={"Select outcome"}
        options={
          organizationData?.call_outcomes?.map?.((reason) => ({
            value: reason,
            displayValue: reason,
          })) || []
        }
        selectStyles={selectStyles}
        defaultValStyles={{ color: "#A0A0AB" }}
        disabled={shared}
      />

      <label className={`text-sm medium-font ${classes.tagContainer}`}>
        Tags
        <StyledAutocomplete
          multiple
          id="tags-input"
          disableClearable
          options={shared ? [] : allTags}
          limitTags={5}
          getOptionLabel={(option) => option.name}
          value={selectedTags}
          disabled={shared}
          onChange={tagsChangeHandler}
          renderInput={(params) => (
            <TextField
              {...params}
              onMouseDown={(e) => e.stopPropagation()}
              onClick={(e) => e.stopPropagation()}
              placeholder={
                selectedTags.length > 0 || shared ? "" : "Select tags"
              }
              InputLabelProps={{ shrink: false }}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: shared ? null : params.InputProps.endAdornment,
              }}
              sx={{
                color: "#3f3f46",
                fontSize: "14px",
                lineHeight: "20px",
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: 400,
              }}
            />
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) =>
              index > 3 ? (
                index === 4 ? (
                  "..."
                ) : null
              ) : (
                <Chip
                  label={option.name}
                  {...getTagProps({ index })}
                  onDelete={
                    shared
                      ? undefined
                      : () => {
                          const newTags = selectedTags.filter(
                            (tag) => tag.id !== option.id
                          );
                          setSelectedTags(newTags);
                        }
                  }
                  deleteIcon={<img src={chipDeleteIcon} alt="Remove icon" />}
                  onMouseDown={(e) => null}
                  sx={{
                    cursor: "pointer",
                    borderRadius: "4px",
                    border: "1px solid #E4E4E7",
                    marginRight: "6px",
                    height: "24px",
                    background: "#ffffff",
                    fontSize: "14px",
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: 400,
                    color: "#3F3F46",
                    lineHeight: "20px",
                  }}
                />
              )
            )
          }
          sx={{
            width: "100%",
            maxWidth: 500,
            "& .MuiChip-root": {
              margin: "2px",
            },
          }}
        />
      </label>
      <label className="text-sm medium-font">
        Comments
        <textarea
          type="text"
          placeholder="Enter comment"
          className="text-sm regular-font"
          value={comments}
          rows={5}
          onChange={(e) => setComments(e.target.value)}
          disabled={shared}
        />
      </label>
    </form>
  );
};

export default RecordingNotes;

const labelStyles = {
  fontSize: "14px",
  lineHeight: "20px",
  fontWeight: "500",
};
const selectStyles = {
  marginBottom: "20px",
  width: "320px",
  borderRadius: "6px",
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#E4E4E7",
  },
};

const StyledAutocomplete = styled(Autocomplete)(({ theme, disabled }) => ({
  maxWidth: "100% !important",
  marginBottom: "20px",
  "& .MuiAutocomplete-hasPopupIcon.MuiOutlinedInput-root": {
    padding: 0
  },
  "& .MuiOutlinedInput-root": {
    padding: "8px 39px 8px 12px",
    borderRadius: "6px",
    color: "#3f3f46",
    borderColor: "#e4e4e7",
    background: disabled
      ? "#F4F4F5"
      : "#ffffff"
        ? "& fieldset"
        : {
            borderColor: "#e4e4e7",
          },
    "&:hover fieldset": {
      borderColor: "#e4e4e7",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#34cb65",
    },
    "& .MuiInputBase-input::placeholder": {
      color: "#3f3f46",
      fontSize: "14px",
      lineHeight: "20px",
      fontFamily: "Inter, sans-serif",
      fontStyle: "normal",
      fontWeight: 400,
      opacity: 1,
    },
    "&.Mui-disabled": {
      opacity: 1,
      "& .MuiChip-root": {
        opacity: 1,
      },
      "& .MuiChip-label": {
        color: "#1e293b", // Ensure text color remains visible
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgba(0, 0, 0, 0.23) !important", // Keep border visible
      },
    },
  },
  "& .MuiInputLabel-root": {
    display: "none",
  },
}));
