import { createSlice } from "@reduxjs/toolkit";

let initial = null;
try {
  initial = JSON.parse(localStorage.getItem("frejun-current-account"));
} catch (err) {
  console.warn(err, " Setting up local storage for first (re)use.");
}

initial = initial ? initial : {};

export const accountSlice = createSlice({
    name: 'account',
    initialState: {value:Object.keys(initial).length === 0?{}:initial},
    reducers:{
        saveAccountDetails: (state, action) => {
            // NOTE: AFTER successfully logging in details stored here
            const _setStateValue = {...action.payload, isLoggedIn:Object.keys(action.payload).length === 0?false:true}
            state.value = {...state.value, ..._setStateValue}
            localStorage.setItem('frejun-current-account', JSON.stringify(state.value))
        },
		setAllOrgsDetails: (state, action) => {
			state.value = {...state.value, allOrgs: action.payload}
			localStorage.setItem('frejun-current-account', JSON.stringify(state.value))
		},
		saveSelectedOrg: (state, action) => {
			state.value = {...state.value, selectedOrg: action.payload}
			localStorage.setItem('frejun-current-account', JSON.stringify(state.value))
		},
		saveOrgId: (state, action) => {
			state.value = {...state.value, orgId: action.payload}
			localStorage.setItem('frejun-current-account', JSON.stringify(state.value))
		},
    setSubscriptionDetails:(state,action)=>{
      state.value={...state.value,subscription:action.payload}
      localStorage.setItem('frejun-current-account',JSON.stringify(state.value))
        },
    setCredits:(state,action)=>{
      state.value={...state.value,credits:action.payload}
      localStorage.setItem('frejun-current-account',JSON.stringify(state.value))
  },
    saveUserStatus: (state, action) => {
      state.value = {
        ...state.value,
        data: {
          ...state.value.data,
          profile: { ...state.value.data.profile, status: action.payload },
        },
      };
      localStorage.setItem(
        "frejun-current-account",
        JSON.stringify(state.value)
      );
    },
    setSoftphone:(state, action)=>{
      state.value={...state.value, softphone:action.payload}
      localStorage.setItem('frejun-current-account',JSON.stringify(state.value))
    },
    resetAccountDetails: (state, action) => {
      state.value = {}
    }
  }
})

export const { saveAccountDetails, setAllOrgsDetails, saveSelectedOrg, saveOrgId,saveUserStatus, setSubscriptionDetails,setCredits, setSoftphone, resetAccountDetails} = accountSlice.actions
export default accountSlice.reducer