import React from "react";
import Modal from "@mui/material/Modal";
import "./gModalStyles.css";
import { modalCloseIcon } from '../../../utility-functions/assetLoader';
import colors from '../colors';
import '../../../index.css';


/**
 * @param {Boolean} 		        visible       - should modal be visible
 * @param {string} 		  				heading 		  - Heading of the modal
 * @param {import('react').ReactNode} 	 body    - JSX Element (body of the modal)
 * @param {StyleSheet}   			modalStyle 	- Styles to override modal defaults
 * @param {Boolean}   			disableCloseBtn  	- whether close button is disabled or not.
 * @param {Function} 					  closeModal 		- Function to run when modal is closed
 * @param {import('react').ReactNode[]}                 bottomButtons         -array of <Button /> or buttons for the roles of cancel and save/delete
 * @param {Boolean}          closeIconVisible -should close icon be visible  
 * @returns {import('react').ReactNode}					Genral Modal Component
 */

function GModal({
  visible,
  heading,
  body,
  modalStyle,
  disableCloseBtn,
  closeModal,
  bottomButtons,
  bodyWrapperStyle = {},
  additionalClassNames="",
  closeIconVisible=true,
  headingStyle={},
  bottomButtonsStyle={},
  noScrollbar=false
}) {
  return (
    <div >
      <Modal
        open={visible}
        onClose={(event,reason) => {
          if (!disableCloseBtn) closeModal(event,reason);
        }}
        className={"gmodal-background" + " " + additionalClassNames}
      >
        <div className='gmodal-foreground-wrapper'>
          <div className="gmodal-heading" style={headingStyle}>
                  <div style={{userSelect: 'none', cursor: 'default',}} className='t6 medium-font nc-gray-900'>{heading}</div>
                  {closeIconVisible && <div className="gmodal-close-btn" onClick={() => {
                      if (!disableCloseBtn) closeModal();
                    }}>
                    {modalCloseIcon(colors.nc_gray_900, 20, 20)}
                  </div>}
          </div>
          <div className={`gmodal-body-inner custom-scrollbar ${noScrollbar ? 'no-scrollbar': ''}`} style={bodyWrapperStyle}>
        
              <div className="gmodal-foreground" style={modalStyle}>
                {/* Modal Heading moved outside*/}
                

                {/* Modal Body */}
                <div className="gmodal-body">{body}</div>
                
              </div>
          </div>
          
          {bottomButtons.length !== 0 && ( 
            <div className='gmodal-buttons-container' style={bottomButtonsStyle}>
              {bottomButtons?.map((bottomButton, index) => <div key={index} >{bottomButton}</div>)}
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default GModal;
