import "../../design/components/gtableStyles.css";
import { useEffect, useReducer, useState } from "react";
import Button from "../../design/Button";
import GHeader from "../../design/components/GHeader";
import GTable from "../../design/components/GTable";

import "./teamViewStyles.css";
import { useLocation, useNavigate } from "react-router-dom";
import Colors from "../../design/colors";
import { Box, Menu, MenuItem} from "@mui/material";
import { JService } from "../../api-service/ApiService";
import { enqueueSnackbar } from 'notistack';
import { fetchData, filtersReducer, getPermissionId } from "../../../utility-functions";
import { CustomAvatar } from "../../design/components/customComponents";
import { Filters } from "../../dashboard/Filters";
import { hasPermission } from "../../features/permission/auth";
import { chevronLeft, threeDotsIcon } from "../../../utility-functions/assetLoader";
import CreateEditTeamModal from "./CreateEditTeamModal";
import DeleteTeamModal from "./DeleteTeamModal";
import { useSelector } from "react-redux";

const initialState = {
  roles: {
    all: [],
    selected: [],
  },
  currentSet: "",
  searchText: "",
  searchFields: ["teamViewUser"],
};
const filtersList = [{ name: "Role", toggle: false, isSearch: false, payloadFor: 'roles', permission: 'manage roles' }]

const generateRowsFromData = (data) => {
    const returnData = Object.values(data).reduce((cumulative, key) => {
      let { id, first_name, last_name, virtual_numbers, roles, email } = key;

      let row = {
        id,
        teamViewUser: first_name + " " + last_name,
        // choosing the first virtual number
        teamViewVirtualNumber:
          Array.isArray(virtual_numbers) && virtual_numbers?.length
            ? virtual_numbers[0]?.country_code +
              " " +
              virtual_numbers[0]?.number
            : "",
        teamViewRole: roles,
        email:email
      };

      cumulative.push(row);
      return cumulative;
    }, []);
    return returnData;
  };

export default function TeamView(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const teamId = location.pathname.split("/").pop();

  const [showLoader, setShowLoader] = useState(true);
  const [controller, setController] = useState(0); // hook up to useEffect and change controller to fetch data
  const [cols, setCols] = useState([]);
  const [masterRows, setMasterRows] = useState([]);
  const [rows, setRows] = useState([]);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [deleteAllModalVisible,setDeleteAllModalVisible]= useState(false)
  const [contentTitle, setContentTitle] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [users, setUsers] = useState([]);

  const canCreateOrEdit = hasPermission('teams', 'create and edit teams', null, Number(teamId));
  const canDelete = hasPermission('teams', 'delete teams', null, Number(teamId));
  const {canManageRoles, canViewUsers} = useSelector((state) => state?.auth?.permissions);

  const handleBack = () => navigate('/users-and-teams', {state: {tab: 'Teams'}})

  const handleAddToTeamClick = (e) => {
    setAddModalVisible(true);
  };

  const actionsButton = ((canCreateOrEdit || canDelete) ? 
  <>
    <Button
      buttonText={threeDotsIcon()}
      hierarchy="white"
      variant="outlined"
      disabled={false}
      isLoading={false}
      onClick={(event) => setAnchorEl(event.currentTarget)}
      styleOverride={{width: '40px', height: '40px', minWidth: '40px', padding: 0}}
    />
      <Menu
        id="actions-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        sx={{marginLeft: '-15px'}}
        MenuListProps={{sx: {width: '160px'}}}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        {canCreateOrEdit && 
        <MenuItem onClick={() => { 
          setAnchorEl(null); 
          handleAddToTeamClick()
        }}>
          Edit
        </MenuItem>}
        {canDelete && 
        <MenuItem onClick={() => { 
          setAnchorEl(null); setDeleteAllModalVisible(true)}}>
          Delete
        </MenuItem>}
      </Menu>
    </>
  : null);
  
  const [availableData, dispatchAvailableData] = useReducer(
    filtersReducer,
    initialState
  );

  useEffect(() => {
    // simple search
    const searchTextLower = availableData?.searchText?.toLowerCase();
    let finalResult = [];
    availableData?.searchFields?.forEach((searchField) => {
      let intermediateResult = masterRows?.filter((row) =>
        row[searchField]?.toLowerCase()?.includes(searchTextLower)
      );
      finalResult = finalResult.concat(intermediateResult);
    });

    combineSearchAndFilters(finalResult);
  }, [availableData]); // eslint-disable-line

  useEffect(() => {
    const abortController = new AbortController();
    const teamId = window.location.pathname.split("/").pop();

    if (!showLoader) setShowLoader(true);

    JService.get(`/api/v1/auth/teams/${teamId}/`, {}, {signal: abortController.signal})
      .then((res) => {
        if(!('success' in res)) return;
        if (!res?.success) throw new Error(res?.message || "Failed to retreive team details");
        setShowLoader(false);
		    enqueueSnackbar(res?.message || 'Retreived team details', { variant: "success"})
        setCols([
          {
            field: "teamViewUser",
            headerName: "User",
            headerClassName: "teams-view-table-header-team-name",
            flex: 0.24,

            renderCell: (params) => (
              <Box style={{ paddingLeft: "14px", display: "flex" }}>
                <CustomAvatar 
                name={params?.row?.teamViewUser?.trim()?.length
                   ? params.row.teamViewUser : params?.row?.email}
                />
                <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                  {params?.row?.teamViewUser?.trim()?.length ?
                  <p className="t7 regular-font nc-gray-900 pNoOverflow">
                    {params?.row?.teamViewUser}
                  </p> : null}
                  <p className="t7 regular-font nc-gray-500 secondary-text pNoOverflow">
                    {params?.row?.email}
                  </p>
                </Box>
              </Box>
            ),
          },
          {
            field: "teamViewRole",
            headerName: "Role",
            flex: 0.67,
            renderCell: (params) => (
              <Box>
                {getRoleTitles(params?.row?.teamViewRole)}
              </Box>
            ),
          },
        ]);

        let temp = generateRowsFromData(res?.data?.users);
        setMasterRows(temp);
        setRows(temp);

        setContentTitle(res?.data?.name || "");
      })
      .catch((err) => {
        setShowLoader(false);
        enqueueSnackbar(err?.message, { variant: "error"})
      })

    // retreive-users
    if(canViewUsers){
      JService.get(`/api/v1/auth/retrieve-users/?permission_id=${getPermissionId(null, 'view teams')}`)
        .then((res) => {
          if (!res?.success) throw new Error(res?.message || "Failed to retreive users");
          setUsers(res?.data);
        })
        .catch((err) => enqueueSnackbar(err?.message, { variant: "error"}))
        .finally(() => setShowLoader(false));
    }

      const setRoles = (roles) => {
        dispatchAvailableData({
          type: "setPayloadToAllOfType",
          payload: {
            payload: roles?.map(({ id, title }) => ({ id, name: title })),
            payloadFor: 'roles',
          }
        });
      }
      if(canManageRoles){
        fetchData('/api/v1/auth/roles/', setRoles, 'roles');
      }

      return () => abortController.abort();
  }, [controller, canManageRoles, canViewUsers]); // eslint-disable-line

  const breadcrumbs = [
    <div 
      onClick={handleBack} 
      style={{ color: Colors.ic_green_400,fontWeight:"500",fontFamily:"Inter", cursor: "pointer", display: "flex", gap: "6px"}}>
        <span>{chevronLeft()}</span> Back to teams
    </div>
  ];

  // filtering happens here
  function combineSearchAndFilters(arg1) {
    if (!availableData?.roles?.selected?.length) {
      if (arg1 != rows) {
        setRows(arg1); //eslint-disable-line
      }
      return;
    }

    let temp = arg1;

    // roles selected changed
    if (availableData?.roles?.selected?.length) {
      temp = temp?.filter(te => {
        return te?.teamViewRole
                ?.map(role => role?.id)
                ?.findIndex(id => availableData?.roles?.selected?.includes(id)) !== -1
      });
    }

    // @note removing duplicates
    temp = temp?.reduce(function (x, y) {
      if (x.indexOf(y) < 0) x.push(y);
      return x;
    }, []);

    setRows(temp);
  }

  function handleAddModalClose() {
    setAddModalVisible(false);
  }

  function handleDeleteAllModalClose(){
    setDeleteAllModalVisible(false)
    setUsers(null)
  }

  const getRoleTitles = (roles) => {
    return roles?.map(role => role.title)?.join(', ')
  }

  const [csvData, setCSVData] = useState({
    headers: [["User"], ["Role"]],
    data: [],
    ids: [],
  });

  const csvDownloadBody = null;

  useEffect(() => {
    setCSVData((prevState) => {
      let newIds = [];
      const newData = [];

      for (let i = 0; i < rows?.length; ++i) {
        let item = rows[i];
        if (!newIds?.includes(item?.id)) {
          newIds.push(item?.id);
          let temp = [item?.teamViewUser, getRoleTitles(item?.teamViewRole)];
          newData.push(temp);
        }
      }
      return { ...prevState, data: newData, ids: newIds };
    });
  }, [rows]);

  return (
    <div className="screen-container">
      <GHeader
        breadcrumbs={{ isVisible: true, breadcrumbs, pointerEventsAllowed: true, displaySeparator: false }}
        topHeader={{}}
        gsepStyles={{display: 'none'}}
        bottomHeader={{
          contentTitle: contentTitle,
          buttons: [actionsButton],
          isCalendar: false,
          style: {paddingTop: '20px', paddingBottom: '32px'}
        }}
      />
      <Filters
        dispatchAvailableData={dispatchAvailableData}
        availableData={availableData}
        filtersList={filtersList}
        fromScreen="team-view"
        dateFilter={false}
      />
      <GTable
        leftHeader={{filters: [], isSearch: true}}
        dispatchAvailableData={dispatchAvailableData}
        availableData={availableData}
        rightHeader={{ isSearch: false, isRefresh: true, isDownload: true }}
        download={{
          data: csvData,
          body: csvDownloadBody,
          filename:
            "frejun-team-members-" +
            new Date().toLocaleString("en-US", {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            }),
        }}
        rows={rows}
        fromScreen="team-view"
        columns={cols}
        useMuiTable={true}
        isLoading={showLoader}
        additionalProps={{ bgHover: true }}
        searchFields={["teamViewUser"]}
        combineSearchAndFilters={combineSearchAndFilters}
      />
      <CreateEditTeamModal
        heading="Add team member"
        visible={addModalVisible}
        handleClose={handleAddModalClose}
        mode="Edit"
        usersArray={users}
        teamId={teamId}
        refetchData={() => setController(prev => ++prev)}
      />
      <DeleteTeamModal
        visible={deleteAllModalVisible}
        handleClose={handleDeleteAllModalClose}
        teamId={teamId}
        teamName={contentTitle}
        refetchData={handleBack}
      />
    </div>
  );
}