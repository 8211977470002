// TODO: change icons
//TODO: add JSDOCs documentation (refer generic Button component)
/* TODO:  use ___ to generate classNames getTableRowClassName */
// @addTODO: add onRowUpdateChange or onGridTableReady
// TODO: PAGINATION rows per page needs to be double clicked (to select an option say 10 or 25 or 100)
// to hide loader-anim only when the ui is ready
// docs for DataGrid // https://mui.com/x/react-data-grid/getting-started/
// columns and rows expect differently structured data depending on whether usMuiTable is true/false
// cell event listeners are added to be added by the developer from the parent component in the renderCell method of mui if useMuiTable == true
// some assets have been imported(temporary), it is advised to use the renderCell method to show any icons/images in cells (of DataGrid), TODO: add icon support for fallback <table>

/* 	Component Documentation:
		• Simple Search: 
			searchField Props can be passed as an array of strings. When user searches for something, the rows will be filtered based on the specified search Fields.
			Ex: searchFields={['role','firstName']}

		• Complex Search: 
			For cases where the fields are nested OR require some additional logic, complex search can be used. 
			This can be enabled by passing an onSearch Method. This method will run when there is a change in the searchBox and it will recieve the value in search Box.
			When onSearch is passed as a prop, it will override simple search and searchFiled props will have no effect.
*/
// csv download component's required props have to passed to GTable.

import "./gtableStyles.css";
import Colors from "../colors";

import searchIcon from "../../../assets/gusers-and-teams-gtable-search-icon.svg";
import searchActiveIcon from "../../../assets/gusers-and-teams-gtable-search-active-icon.svg";
import refreshIcon from "../../../assets/gusers-and-teams-refresh-icon.svg";
import downloadIcon from "../../../assets/gusers-and-teams-download-icon.svg";

import { useNavigate } from "react-router-dom";
import AnimationPage from "../../shared/animation-page/AnimationPage";

import { DataGrid } from "@mui/x-data-grid";
import styles from "../styles";
import { useEffect, useRef, useState } from "react";
import GFilters from "./GFilters";
import colors from "../colors";
import GCSVDownload from "./GCSVDownload";
import GTableNoRowsState from "./GTableNoRowsState";
import Checkbox from "@mui/material/Checkbox";
import checkBoxCheckedIcon from "../../../assets/gusers-and-teams-checbox-checked-icon.svg";
import checkBoxUncheckedIcon from "../../../assets/gusers-and-teams-checbox-icon.svg";
import { Filters } from "../../dashboard/Filters";
import { Header } from "rsuite";

const searchThrottleTimePeriod = 1000;
const searchProgressSteps = 5;
const rightHeaderIconStyle = {
	height: "20px",
	width: "20px",
};

function MyCheckbox(props) {
	return (
		<Checkbox
			sx={{ marginLeft: "14px" }}
			indeterminateIcon={<img src={checkBoxCheckedIcon} />}
			icon={<img src={checkBoxUncheckedIcon} />}
			checkedIcon={<img src={checkBoxCheckedIcon} />}
			{...props}
		/>
	);
}

function GTable({
	combineSearchAndFilters,
	leftHeader,
	rightHeader,
	rows,
	columns,
	useMuiTable,
	isLoading,
	fromScreen,
	getTableRowClassName,
	additionalProps,
	// rowsPerPageOptions,
	refreshHandler = null,
	className,
	setOnPage,
	onPage,
	initialState,
	searchFields,
	onSearch,
	dispatchAvailableData,
	availableData,
	onRowClick,
	download,
	onClickDownloadIcon,
	checkboxSelection,
	onSelectionModelChange,
	selectionModel,
	handleSelectionModelChange = () => null,
	buttonText = null,
	hideHeader = false,
	hidePagination,
	addColumnHandler,
	addColumnHandlerDisabled=false,
	onColumnHeaderClick,
	rowCount = undefined,
	handlePageChange = () => null,
	onPageChange=()=>null,
	onPageSizeChange = () => null,
	rowsPerPage = 10,
	gTablePageNumber = 0,
	setGTablePageNumber = () => null,
	rowHeight,
	paginationMode='client',
	children,
	checkboxVisibility=true,
	headerCheckbox =true
}) {
	const navigate = useNavigate();

	const [searchText, setSearchText] = useState("");
	const [searchTextUi, setSearchTextUi] = useState(""); // only ui

	const prevSelectionModel = useRef(selectionModel);
	const timerRef = useRef(undefined);

	useEffect(() => {
		if (selectionModel?.length === 0) {
			prevSelectionModel.current = selectionModel;
		}
	}, [buttonText]);

	

	useEffect(() => {
		if (onSearch instanceof Function && availableData?.searchText !== searchText) {
			// should be only for call-logs screen
			if (fromScreen==="voice" || fromScreen === "contact-lists" || fromScreen === "call-logs" || fromScreen === 'sms-logs' || fromScreen === "call-pricing" || fromScreen === "survey-view") {
				if (timerRef.current?.id || timerRef.current?.id === 0) {
					// dispatch action for "NewCallLogs.js"
					dispatchAvailableData({ type: "setSearchText", payload: searchText }); // ⌛@enable
					// clear timeout and reset timerRef
					clearTimeout(timerRef.current.id)
					timerRef.current = undefined
				}
			} else {
				dispatchAvailableData({ type: "setSearchText", payload: searchText });
			}
		}
		else if (
			Array.isArray(searchFields) &&
			searchFields?.length &&
			availableData?.searchText !== searchText
		) {
			applySimpleSearch();
		}

		// also only for call-logs screen
		if (fromScreen === "contact-lists" || fromScreen === "call-logs" || fromScreen === 'sms-logs' || fromScreen === "call-pricing" || fromScreen === "survey-view") {
		if (availableData?.searchText && searchTextUi !== availableData?.searchText) {
			setSearchTextUi(availableData?.searchText);
			//call focus on the input
			if (leftHeader.isSearch) {
				document.getElementById("gtable-search")?.focus()
			}
		}
		}
	}, [searchText, availableData?.searchText]); // eslint-disable-line

	const applySimpleSearch = () => {
		if (searchText === "") {
			dispatchAvailableData({ type: "setSearchText", payload: "" });
			return;
		}

		// sending data to filtersReducer in parent, unique fromScreen
		dispatchAvailableData({ type: "setSearchText", payload: searchText });
	};


	useEffect(() => {
		if (
			!availableData?.currentSet &&
			!availableData?.searchText &&
			searchText
		) {
			setSearchText("");
		}
	}, [availableData]);

	// select-all
	const [page, setPage] = useState(0);
	
	const rowsPerPageOptions = [10, 25, 100];								
	const [pageSize, setPageSize] = useState(rowsPerPage || rowsPerPageOptions[0]);

	useEffect(()=>{
		console.log('gtablePageNumber:', gTablePageNumber)
		if(fromScreen === 'voice' && gTablePageNumber === 0){
			setPage(0);
		}
	},[gTablePageNumber, fromScreen])

	// only for call-logs
	function handleLeftSearch(e) {
		// input ui updated
		if(fromScreen !== 'survey-view'){
			if(e.target.value==''){
				navigate(0)
			}
		}	
		setSearchTextUi(e.target.value);
		if (["deleteWordForward", "deleteWordBackward", "deleteContentBackward", "deleteContentForward"].includes(e.nativeEvent?.inputType)) {
			if (!e.target.value || e.target.value?.trim()?.length === 0) {
				// trigger a refetch
				if(fromScreen === 'survey-view') setSearchText('');
				onSearch();
			}
			// ("deleting from search query")
		}
		// throttle;
		if (!timerRef.current) {
			// every new search starts here
			timerRef.current = {
				state: null,
				id: undefined,
			}
			// start the timer;
			timerRef.current.id = setTimeout(() => {
				setSearchText(e.target.value)
			}, searchThrottleTimePeriod)
		}
		else {
			if (timerRef.current.id || timerRef.current.id === 0) {
				// searchQuery updated, reset the timer
				clearTimeout(timerRef.current.id)

				timerRef.current.id = setTimeout(() => {
					setSearchText(e.target.value)
				}, searchThrottleTimePeriod)
			}
		}
	}
	useEffect(() => {
		if (headerCheckbox) {
		  handleSelectionModelChange([], pageSize);
		}
	  }, [pageSize]);
	  
	// cleanup
	useEffect(() => {
		return () => {
			clearTimeout(timerRef?.id)
			timerRef.current = undefined
		}
	}, [])

	// ✅ add condition to show loader when search is in progress
	if (isLoading) return <AnimationPage />;
	else
		return (
			<div
				className="gtable-container"
				style={{ borderColor: Colors.nc_gray_300 }}
			>{Object.keys(leftHeader?.filters)?.length ? (
				<div className="filters">
					<GFilters
						fromScreen={fromScreen}
						filters={leftHeader?.filters}
						dispatchAvailableData={dispatchAvailableData}
						availableData={availableData}
					/>
				</div>
			) : null}
				{!hideHeader && (
					<div className="header">
						{/* filters */}
						<div style={{position:'absolute',marginTop:'80px',marginLeft:'17px'}}>
							{headerCheckbox}
						</div>

						<div className="left-header">
							{leftHeader.isSearch && (
								<div
									title={(fromScreen==="voice"||fromScreen==="contact-lists" ||  fromScreen === "call-logs" || fromScreen==='sms-logs') ? "clearing Search clears all the Filters" : ""}
									className="left-header-item search"
									style={{
										borderColor: Colors.nc_gray_300,
										maxHeight: "40px",
										marginLeft: "15px",
									}}
								>
									<img
										onClick={(e) =>
											document.getElementById("gtable-search")?.focus()
										}
										style={{ ...rightHeaderIconStyle }}
										src={searchText?.length > 0 ? searchActiveIcon : searchIcon}
										alt="Search icon"
									/>
									{(fromScreen==="voice" || fromScreen==="contact-lists" || fromScreen === "call-logs" || fromScreen==='sms-logs' || fromScreen === "call-pricing" || fromScreen === 'survey-view') ? (
										<><input
											id="gtable-search"
											className="t6 regular-font"
											type="search"
											placeholder="Search"
											value={searchTextUi}
											onChange={handleLeftSearch}
											title="Enter atleast 1 character to get search results"
										/>
										<div style={{marginTop:'-55px'}} >{children}</div></>
									) : (
										<input
											id="gtable-search"
											className="t6 regular-font"
											type="search"
											placeholder="Search"
											value={searchText}
											onChange={(e) => setSearchText(e.target.value)}
											title="Enter a character to start searching"
										/>										
									)
									}
									</div>
										)}
										</div>
						<div className="right-header">
						
							{/* search functionality- check api response */}
							{rightHeader.isSearch && (
								<div
									className="right-header-item search"
									style={{ borderColor: Colors.nc_gray_300, maxHeight: "40px" }}
								>
									<img
										onClick={(e) =>
											refreshHandler instanceof Function
												? refreshHandler
												: navigate(0)
										}
										style={{ ...rightHeaderIconStyle }}
										src={searchText?.length > 0 ? searchActiveIcon : searchIcon}
										alt="Search icon"
									/>
									<input
										id="gtable-search"
										className="t6 regular-font"
										type="search"
										placeholder="Search"
										value={searchText}
										onChange={(e) => setSearchText(e.target.value)}
										title="Enter 2 characters to start searching"
									/>
								</div>
							)}

							{rightHeader.isRefresh && (
								<div
									onClick={(e) => navigate(0)}
									className="right-header-item refresh"
									style={{ borderColor: Colors.nc_gray_300, height: "40px" }}
								>
									<img
										style={{ ...rightHeaderIconStyle }}
										src={refreshIcon}
										alt="Refresh icon"
									/>
								</div>
							)}

							{rightHeader.isDownload && (
								<div
									className="right-header-item download"
									style={{ borderColor: Colors.nc_gray_300, height: "40px" }}
								>
									{download?.isCustom ? (
										<div onClick={download?.customDownloadHandler}>
											<img
												style={{ ...rightHeaderIconStyle }}
												src={downloadIcon}
												alt="Download icon"
											/>
										</div>
									) : (
										<GCSVDownload
											fromScreen={fromScreen}
											headers={download?.data?.headers}
											downloadData={download?.data?.data}
											filename={download?.filename}
											body={
												download?.body || (
													<img
														style={{ ...rightHeaderIconStyle }}
														src={downloadIcon}
														alt="Download icon"
														onClick={onClickDownloadIcon}
													/>
												)
											}
										/>
									)}
								</div>
							)}
							{rightHeader.isAddColumn && (
								<button
									//disabled={tableData[0]?.cells?.values?.length > 10}
									title={addColumnHandlerDisabled ? "Customizable reports are not included in Standard plan" : "Add custom column"}
									onClick={addColumnHandler}
									disabled={addColumnHandlerDisabled}
									style={{
										height: "38px",
										paddingLeft: "12px",
										paddingRight: "12px",
										background: "#ffffff",
										border: "1px solid #dddddd",
										borderRadius: "4px",
										marginRight: "16px",
									}}
									className="table-toolbar-right-button regular-font b2 nc-gray-900"
								>
									Add Column
								</button>
							)}
							{rightHeader?.dateFilter && <Filters value={rightHeader?.dateFilter?.value} style={{margin:0}} filtersList={[]} fromScreen={fromScreen} onApply={rightHeader?.dateFilter?.onApply}/> }
						</div>
					</div>
				)}

				{/* table component */}
				{useMuiTable ? (
					<DataGrid
						className={className || ""}
						sx={{
							cursor: "default",
							userSelect: "none",
							"& .MuiDataGrid-columnHeaders": {
								background: colors.nc_gray_200,
								...styles.medium_font,
								...styles.t6,
								color: colors.nc_gray_800,
								// position: "sticky",
								position: "relative",
								zIndex: 2,
							},

							// autodial-view screen Checkbox container override
							".MuiDataGrid-columnHeader.MuiDataGrid-columnHeaderCheckbox": {
								paddingLeft: "4px",
								marginRight: "14px",
							},
							"& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
								display: checkboxVisibility ? "flex" : "none"
							  },
							".MuiDataGrid-columnHeader:focus": {
								outline: "none",
							},
							".MuiDataGrid-columnHeader:focus-within": {
								outline: "none",
							},
							" .MuiDataGrid-columnHeaderCheckbox": {
								outline: "none",
							},
							"& .MuiDataGrid-menuIcon": {
								display: "none",
							},
							"& .MuiDataGrid-columnSeparator": {
								display: "none",
							},
							'& .MuiDataGrid-iconButtonContainer > button[title="Sort"]': {
								display: "none",
							},
							// custom row class -- applied to all rows // also has sticky cols styles
							"& .mui-table-row": {
								backgroundColor: colors.ic_white,
								borderBottom: "1px solid #F5F7F6",
								transition: "all 0.3s ease-out",
								cursor: (fromScreen === "call-logs" || fromScreen==='sms-logs') ? "default" : "pointer", //  remove if necessary
								"& .MuiDataGrid-cell[data-field='logsContact']": {
									position: "sticky",
									transition: "all 0.3s ease-out",
									left: 0,
									zIndex: 3,
									backgroundColor: colors.ic_white, // same as row background
									borderRight: `1px solid ${colors.nc_gray_300}`,
									paddingRight:'24px'

									// borderRadius: '0px 4px 0px 0px', (low|ui)TODO: add border radius for first of each page // better done in component js. run an
									// useEffect on change of rows, choose the first one, get the row/call id, select in the DOM and update borderRadius.
								},
								"& .MuiDataGrid-cell[data-field='logsContact'] .MuiBox-root":{
									width: '100%',
    								justifyContent: 'space-between'
								},
								"& .MuiDataGrid-cell[data-field='logsPhoneButton']": {
									position: "sticky",
									transition: "all 0.3s ease-out",
									zIndex: 3,
									left: "185px", // Width of logsContact cell - width of logsPhoneButton cell
									"& div": {
										opacity: 1, // so that phone button is always visible
										background: colors.ic_white,
									}
								},
								"& .MuiDataGrid-cell[data-field='logsUser']": {
									paddingLeft: "0px",
								},
								"& .MuiDataGrid-cell[data-field='logsDuration']": {
									paddingLeft: "0px",
								},
								"& .MuiDataGrid-cell[data-field='logsRecording']": {
									paddingLeft: "4px",
								},
								"& .MuiDataGrid-cell[data-field='logsContactedOn']": {
									paddingLeft: "10px",
								},
								"& .MuiDataGrid-cell[data-field='logsCalloutcome']": {
									paddingLeft: "10px",
								},
								"& .MuiDataGrid-cell[data-field='logsCallReason']": {
									paddingLeft: "10px",
								},
								"& .MuiDataGrid-cell[data-field='logsTags']": {
									paddingLeft: "10px",
								},
								// override for general sticky threedots column
								'& .table-cell-edit[data-field="logsEdit"]': {
									position: "sticky",
									transition: "all 0.3s ease-out",
									right: "0px", // changed from 12px to 0px
									background: colors.ic_white,
								},
								"&:hover": {
									backgroundColor: additionalProps?.bgHover
										? "#F7FAF8"
										: "initial",
									"& .MuiDataGrid-cell[data-field='logsContact']": {
										backgroundColor: additionalProps?.bgHover
											? "#F7FAF8"
											: "initial",
									},
									'& .table-cell-edit[data-field="logsEdit"]': {
										backgroundColor: additionalProps?.bgHover
											? "#F7FAF8"
											: "initial",
									},
								},
								// change to &:hover followed by className
								// if underline on-hover is needed
								"& .teams-table-cell-team-name": {
									textDecoration: "underline",
									textDecorationColor: "#28994B",
								},
								"& .table-cell-edit": {
									width: "90px",
									display: "flex",
									flexDirection: "row",
									justifyContent: "center",
									alignItems: "center",
								},
								"& .table-cell-edit > div": {
									opacity: 0,
									transition: "all 0.3s ease-in",
									pointerEvents: "none",
									cursor: "default",
								},
								"&:hover .table-cell-edit > div": {
									opacity: 1,
									pointerEvents: "all",
								},
								// cell selectors -- applied to all cells
								// TODO: Still there is vertical movement when clicking cell

								"& .MuiDataGrid-cell": {
									outline: "none",
									height: "5em",
									fontSize: "14px !important",
									lineHeight: "24px !important",
									color: `${colors.nc_gray_900} !important`,
									fontFamily: "Inter !important",
									fontStyle: "normal !important",
									fontWeight: 400 + "!important",
									transition: "all 0.1s ease-in-out",
									paddingLeft: "24px",
									":active": {
										outline: "none",
										borderWidth: "2px",
									},
								},
							},

							"& .MuiDataGrid-root": {
								border: "none",
							},
							"& .MuiDataGrid-cell.MuiDataGrid-cell--textLeft": {
								paddingLeft: [
									"call-logs",
									"autodialScreen",
									"contact-list",
								].includes(fromScreen)
									? "10px"
									: ["analytics-dashboard"].includes(fromScreen)
										? "14px"
										: "24px",
							},
							"& .MuiDataGrid-cell[data-colindex='0']": {
								// outline: '1px solid red !important',
								paddingLeft: ["message-templates"].includes(fromScreen)
									? "24px"
									: "10px",
							},
							//   "& .MuiDataGrid-cell[data-colindex='1']": {
							// 	// outline: '1px solid red !important',
							// 	paddingLeft: ["autodialScreenView", "call-logs", "autodialScreen", "contact-list"].includes(fromScreen) ? "10px" : "24px",
							//   },

							// styles for rows with checkboxes
							".MuiDataGrid-row.Mui-selected": {
								backgroundColor: colors.nc_gray_100,
								// alternate styling
								// "& .MuiDataGrid-cell[data-field='logsContact']": {
								// 	position: 'sticky',
								// 	left: 0,
								// 	zIndex: 3,
								// 	backgroundColor: colors.nc_gray_100, // same as row background
								// },
							},
							// overrides for selected row hover
							"& .MuiDataGrid-row.Mui-selected:hover": {
								backgroundColor: "#F7FAF8",
							},
							// pagination overrides
							"& .MuiDataGrid-footerContainer": {
								...styles.regular_font,
								color: colors.nc_gray_900,
								justifyContent: "center",
								flexDirection: "row",

								"& .MuiTablePagination-root": {
									width: "100%",
									display: "flex",
									flexDirection: "row",
									justifyContent: "flex-start",
									background: colors.nc_gray_200,
									"& .MuiInputBase-root": {
										marginLeft: "0px",
									},

									"& .MuiToolbar-root": {
										width: "50%",

										paddingLeft: "40px",
										display: "flex",
										justifyContent: "flex-start",
										alignItems: "center",
										flexDirection: "row",
										"& .MuiTablePagination-spacer": {
											display: "none",
										},
										// rows per page label
										"& .MuiTablePagination-selectLabel": {
											...styles.t7,
											...styles.medium_font,
											color: colors.nc_gray_900,
											marginRight: "8px",
										},

										// rows per page number
										"& .MuiTablePagination-select": {
											...styles.t7,
											...styles.regular_font,
											color: colors.nc_gray_900,
											transform: "translateY(1px)",
											background: colors.ic_white,
											borderRadius: "2px",
											display: "flex",
											alignItems: "center",
											padding: "8px 12px",
											minWidth: "80px",
										},
										"& .MuiSelect-icon": {
											display: "inline-block",
											color: colors.nc_gray_900,
										},
										// 1-10 of 14
										"& .MuiTablePagination-displayedRows": {
											...styles.t7,
											...styles.medium_font,
											color: colors.nc_gray_900,
										},
									},
								},
							},
							// scrollbar horizontal
							"& .MuiDataGrid-virtualScroller": {
								overflow: "auto",
							},

							"& .MuiDataGrid-main": {
								// remove overflow hidden overwise sticky does not work
								overflow: "unset",
							},
							// sticky header

							/* scrollbar @overrides*/
							"& .MuiDataGrid-virtualScroller": {
								marginTop: "0 !important",
							},

							"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track-piece": {
								backgroundColor:  "#E1E5E277",
							},

							"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
								/* Neutral Colors/Grey - 300 */
								backgroundColor: "#c6ccc8 !important",
								width: "8px",
								borderRadius: "8px",
								transition: "all 0.3s ease-in-out",
								cursor: "all-scroll"
							},

							"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
								backgroundColor: "#e1e5e277",
								marginTop: "0px",
								marginBottom: "0px",
								width: "4px",
							},

							"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-corner":
							{
								background: "transparent",
								width: "0",
								height: "0",
								borderLeft: "16px solid #E1E5E277",
								borderTop: "16px solid #E1E5E277",
								borderBottom: "16px solid transparent",
								borderRight: "16px solid transparent",
							},
							"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-button": {
								display: "none",
								border: "1px solid red",
								width: "0px",
								height: "0px",
							}, 

							"& :hover .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb":
							{
								/* Neutral Colors/Grey - 300 */
								// background: '#2a2a2a95',
							},
							"& .MuiDataGrid-virtualScroller:hover::-webkit-scrollbar-thumb": {
								backgroundColor:  "#c6ccc8 !important",
								cursor: "all-scroll",
							},
							"& .MuiDataGrid-virtualScrollerContent" :{
								marginBottom: "0px !important"
							},
							"& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
								background:"#F7FAF8",
								width:"12px",
								opacity:0,
								height: "12px",
								paddingLeft: "4px",
								paddingRight: "4px",
							},
							/* --scrollbar end */

							"& .MuiDataGrid-columnHeaders:before": {
								display: (fromScreen === "call-logs" || fromScreen==='sms-logs') ? "block" : "none",
								content: '"Contact"',
								position: "absolute",
								top: 0,
								left: 0,
								background: "#F5F7F6",
								width: (fromScreen==='sms-logs' || fromScreen === "call-logs") ? "285px" : "0px",
								paddingLeft: "24px",
								fontStyle: "normal",
								fontWeight: 500,
								fontSize: "1rem",
								lineHeight: "24px",
								color: "#5F6661",
								lineHeight: "56px",
								zIndex: "23",
							},
						}}
						rowHeight={rowHeight || 88}
						autoHeight={(fromScreen === "call-logs" || fromScreen==='sms-logs') ? false : true}
						rows={rows}
						onColumnHeaderClick={onColumnHeaderClick}
						components={{
							NoRowsOverlay: GTableNoRowsState,
							BaseCheckbox: MyCheckbox,
						}}
						componentsProps={{
							noRowsOverlay: { fromScreen },
							pagination: {
								sx: {
									// border: '1px solid red',
								},
								SelectProps: {
									sx: {
										border: `1px solid ${colors.nc_gray_300}`,
										borderRadius: "4px",
										background: colors.ic_white,
									},
									MenuProps: {
										disableScrollLock: false, //@change to true,
										anchorOrigin: {
											vertical: "top",
											horizontal: "left",
										},
										transformOrigin: {
											vertical: "bottom",
											horizontal: "left",
										},
										sx: {
											"& .MuiPaper-root": {
												color: colors.nc_gray_900,
												...styles.t7,
												...styles.regular_font,
											},
										},
									},
								},
							},
						}}
						initialState={{
							pagination: {
								pageSize,
								page,
							},
							pinnedColumns: { left: ["logsContact"] },
						}}
						columns={columns}
						getRowClassName={(params) =>
							(params?.id ? `mui-table-row-${params?.id} ` : " ") +
							"mui-table-row"
						}
						disableSelectionOnClick={true}
						onRowClick={(params, event, details) =>
							onRowClick(params, event, details)
						}
						disableVirtualization={true}
						rowsPerPageOptions={[10,25,100] || rowsPerPageOptions}  
						checkboxSelection={checkboxSelection}
						checkboxSelectionVisibleOnly
						onSelectionModelChange={(newSelectionModel) => {
							let isApplyMapping = false;
							if(newSelectionModel.length && ((rows?.at(0)?._id===0 || rows?.at(0)?._id) ?? false)) {
								isApplyMapping = true;
							}
							if (buttonText === null) {
								const mappedSelectionModel = isApplyMapping ? rows.filter(r => newSelectionModel.includes(r.id)).map(r => r?._id) : null;
								const selectedIDs = new Set(mappedSelectionModel ? mappedSelectionModel : newSelectionModel);
								const selectedRowData = rows.filter((row) =>
									selectedIDs.has(row?._id ?? row.id)
								);
								onSelectionModelChange(selectedRowData);
							} else {
								// currentPage start id and current page endId
								let cpStart = null
								let cpEnd = null

								let startIndex = page * pageSize;
								/**
								 *
								 * rowsPp items (10items in page 0 0-9) (page)*pageSize
								 * rowsPp items (10items in page 1 10-19)
								 * rowsPp items (10items in page 2 20-29)
								 *
								 * start of the page is page*pageSize(th item in row)
								 * end of the page is (page*pageSize + pageSize - 1)(th item in row)
								 *
								 * p0 0-9: cpStart: 0, cpEnd: 9
								 * p1 10-19: cpStart: 10, cpEnd: 19
								 *
								 * single page selection should happen
								 * when prevSelectionModel was empty
								 *
								 * page 0: stInd = 0+1th item's id index is 0
								 * page 1: stInd = 10+1th item's index of row to
								 * page 2 stInd = find field of 10th item
								 */

								// console.log(startIndex, " is the startIndex");

								startIndex = Math.min(startIndex, rows.length - 1);

								cpStart = rows[startIndex]?._id ?? rows[startIndex]?.id;

								// let lastItemIs = (page)*pageSize + pageSize-1
								let lastIndex = page * pageSize + pageSize - 1; // index of last item on the same page of the startIndex
								lastIndex = Math.min(lastIndex, rows?.length - 1);
								cpEnd = rows[lastIndex]?._id ?? rows[lastIndex]?.id;

								// rearranging
								let temp = cpStart;
								if(!isApplyMapping) {
									cpStart = Math.min(temp, cpEnd);
									cpEnd = Math.max(temp, cpEnd);
								}

								// console.log(cpStart, " cpStart final");
								// console.log(cpEnd, " cpEnd final");

								const mappedSelectionModel = rows.filter(r => newSelectionModel.includes(r.id)).map(r => r?._id);
								newSelectionModel = [...mappedSelectionModel];

								if (buttonText?.toLowerCase() === "clear selection") {
									cpStart = rows[0]?._id ?? rows[0]?.id;

									cpEnd = rows[rows?.length - 1]?._id ?? rows[rows?.length - 1]?.id;
									console.log(
										"in the if: ",
										newSelectionModel.filter(
											(nsm) => nsm >= cpStart && nsm <= cpEnd
										)
									);
								}

								let modifiedSelectionModel = newSelectionModel?.filter(
									(nsm) => nsm >= cpStart && nsm <= cpEnd
								);

								if (
									buttonText?.toLowerCase() !== "clear selection" &&
									newSelectionModel?.length != rows?.length
								) {
									if (newSelectionModel?.length >= pageSize) {
										// after selecting 10 selection on another page
										modifiedSelectionModel = newSelectionModel;
									}
								}

								if (
									Array.isArray(prevSelectionModel.current) &&
									prevSelectionModel.current?.length !== 0
								) {
									// something is selected
									// remove duplicates
									if (
										newSelectionModel?.length >
										prevSelectionModel.current?.length
									)
										modifiedSelectionModel = [
											...prevSelectionModel.current,
											...newSelectionModel?.filter(
												(ns) => !prevSelectionModel?.current?.includes(ns)
											),
										];
									else modifiedSelectionModel = newSelectionModel;
								}
								const remappedSelectionModel = rows.filter(r => modifiedSelectionModel.includes(r._id ?? r.id)).map(r => r.id);
								prevSelectionModel.current = remappedSelectionModel;
								handleSelectionModelChange(remappedSelectionModel, pageSize);
							}
						}}
						selectionModel={selectionModel}
						onPageChange={(newPage) => {
							if (fromScreen=== "voice" || fromScreen === "contact-lists" || fromScreen === "call-logs" || fromScreen==="call-pricing" || fromScreen==='sms-logs' || fromScreen === 'survey-view') {
								setGTablePageNumber(newPage)
								if (newPage < page) handlePageChange(newPage, "previous");
								else if (newPage > page) handlePageChange(newPage, "next");
								else {
									if (newPage === page && page === 0) {
										// console.log("going back to page 1")
										// go back to page 1 (0 for GTable)
										handlePageChange(0, "previous");
										setGTablePageNumber(0);
									}
								}
							}else{
								onPageChange(newPage)
							}
							prevSelectionModel.current = selectionModel;

							setPage(newPage);
						}}
						onPageSizeChange={(newArg) => {
							handleSelectionModelChange([], pageSize);
							setPageSize(newArg);
							onPageSizeChange(newArg);
						}}
						page={(["call-logs", "call-pricing", "sms-logs", "survey-view", "voice"].includes(fromScreen)) ? gTablePageNumber : page}
						paginationMode={paginationMode}
						rowCount={rowCount}
						hideFooterSelectedRowCount={true} // hides ' 1 row selected ' message in footer
						hideFooter={hidePagination}
					/>
				) : null}
			</div>
		);
}

export default GTable;
