import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { loginAttempt } from '../features/user/user'

import './signupStyles.css'
import { Helmet } from 'react-helmet'
import dotsBottom from '../../assets/login-dots-bottom.svg'
import dotsTop from '../../assets/login-dots-top.svg'
import logo from '../../assets/top-nav-logo.svg'
import Button from '../design/Button'
import G2badge1 from '../../assets/G2badge-1.svg';
import G2badge2 from '../../assets/G2badge-2.svg';
import G2badge3 from '../../assets/G2badge-3.svg';
import { CCDropdown, CustomInput } from '../design/components/customComponents'
import { enqueueSnackbar } from 'notistack'
import { filledStar, halfFilledStar } from '../../utility-functions/assetLoader'

const reviews = [
	{
		text: "“Helpful in gaining insights about agents and overall their performance”",
		author: "Sibani M",
		role: 'Customer Success',
		rating: 5
	},
	{
		text: "“Perfect for daily call tracking and call recording”",
		author: "Nitin K",
		role: 'Business Development',
		rating: 5

	},
	{
		text: "“Perfect solution for calling”",
		author: "Naman J",
		role: 'Customer Success',
		rating: 4.5
	}
	
];
const API_BASE_URL = process.env.REACT_APP_BETA
const emailRegex = new RegExp(/^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,15}$/);
const stringFieldValidation = (val) => Boolean(val) && !!String(val).trim().length
const validationMapping = {
	"email" : {
		validate: (val) => emailRegex.test(val),
		error: "Please enter your business email",
	},
	"first_name": {
		validate: (val) => stringFieldValidation(val) && val.trim().length > 0,
		error: "This is a required field"
	},
	"last_name": {
		validate: (val) => stringFieldValidation(val) && val.trim().length > 0,
		error: "This is a required field"
	},
	"phone_number": {
		validate: (val) => val?.length >= 0 ,
		error: "Invalid phone number"
	},
	"password": {
		validate: (val) => val?.length >= 8,
		error: "Password too short, expected minimum length 8"
	},
	"countryCode": {
		validate: (val) => stringFieldValidation(val) && val.trim().length > 0,
		error: "Invalid country code"
	}
}
export default function Signup() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [signupValues, setSignupValues] = useState({
        first_name: {value:'',error:null},
        last_name: {value:'',error:null},
        email: {value:'',error:null},
        password: {value:'',error:null},
        phone_number:{value:'',error:null},
        countryCode:{value:'+91',error:null}
    })
    const [showLoader,setShowLoader]=useState(false)
	
    const [isDisabled, setIsDisabled] = useState(true)

    const handleSignupChange = (e, _field, _value) => {
        const field = e?.target?.name ?? _field;
        let value = e?.target?.value ?? _value
		if(field === "email") value = value.toLowerCase()
		const update = {
			...signupValues,
			[field]: {error: signupValues[field].error, value},
		}
		
		// validate and determine if submit button is enabled
		setIsDisabled(!validateSignup(update, false))
    }
    const handleFocus=(e)=>{
        // const field = e.target.name
		// setSignupValues(prev => ({
		// 	...prev,
		// 	[field]: {...prev[field], error: null}
		// }))
		return
    }

	const validateSignup = (update, fromSubmit) => {
		const entries = Object.entries(update)
		let isValid = true
		for(var [key, val] of entries) {
			// if field is not empty
			if(stringFieldValidation(val.value)) {
				// field specific validation
				if(!validationMapping[key].validate(val.value)) {
					update[key].error = validationMapping[key].error
					isValid = false
				}
				else update[key].error = ""
			} else {
				// submit disabled if any field is empty
				isValid = false
				
				// avoid cluttering up with error if field is empty if not submitting
				if(!fromSubmit) update[key].error = ""
				// if submitting with empty field show error, since if disabled handleSubmit returns
				else update[key].error = validationMapping[key].error
			}
		}
		// update input field values
		setSignupValues(update)
		return isValid
	}

	const handleSignupSubmit = e => {
    e.preventDefault();

	let errors = null;

    setIsDisabled(true);
    setShowLoader(true);

    const payload = {
        first_name: signupValues.first_name.value,
        last_name: signupValues.last_name.value,
        email: signupValues.email.value.toLowerCase(),
        password: signupValues.password.value,
        phone_number: `${signupValues.countryCode.value}${signupValues.phone_number.value}`,
    };
    fetch(API_BASE_URL + '/api/v1/auth/signup/',{
        method:'post',
        body: JSON.stringify({...payload, confirm_password: signupValues.password.value}),
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then(res => {
        if(res?.status === 500) throw new Error('Server error please try again or if issue persists contact FreJun')
        return res?.json()
    })
    .then(res => {
        if(!res?.success) {
            const errors = res?.errors? res.errors : res?.message;
            Object.keys(errors).forEach(key => {
				const errorMessage = Array.isArray(errors[key]) ? errors[key][0] : (typeof errors[key] === 'object' && Object.values(errors[key])[0]);

                setSignupValues(prev => ({...prev, [key]: {...prev[key], error: errorMessage}}));
            });
            throw new Error(res?.message || 'Please enter valid values for input fields')
        }

        dispatch(loginAttempt({email: signupValues.email, password: signupValues.password}))
        navigate('/signup/verification')
    })
    .catch(err => {
	if(errors){
				if(errors?.password) setSignupValues(prev=>({...prev,password:{...prev?.password,error:Array.isArray(errors?.password) ? errors?.password[0] : errors?.password}}))
				else if(errors?.email) setSignupValues(prev=>({...prev,email:{...prev?.email,error:Array.isArray(errors?.email) ? errors?.email[0] : errors?.email}}))
				else if(errors?.last_name) setSignupValues(prev=>({...prev,last_name:{...prev?.last_name,error:Array.isArray(errors?.last_name) ? errors?.last_name[0] : errors?.last_name}}))
				else if(errors?.first_name) setSignupValues(prev=>({...prev,first_name:{...prev?.first_name,error:Array.isArray(errors?.first_name) ? errors?.first_name[0] : errors?.first_name}}))				
				else if(errors?.phone_number) setSignupValues(prev=>({...prev,phone_number:{...prev?.phone_number,error:Array.isArray(errors?.phone_number) ? errors?.phone_number[0] :typeof errors?.phone_number==='object'?errors?.phone_number?.phone_number: errors?.phone_number}}))	
			}
			else enqueueSnackbar(err?.message, { variant: "error"})
		})
    .finally(()=>{
        setShowLoader(false)
        setIsDisabled(false)
    })
}
	const getPasswordStrength = (password) => {
		const criteria = {
			length: password.length >= 8,
			uppercase: /[A-Z]/.test(password),
			lowercase: /[a-z]/.test(password),
			digit: /\d/.test(password),
			specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
		};
	
		const fulfilledCriteria = Object.values(criteria).filter((criterion) => criterion).length;
	
		let score = 0;
		if (fulfilledCriteria === 5) {
			score = "Strong";
		} else if (fulfilledCriteria >= 3) {
			score = "Medium";
		} else if (fulfilledCriteria >= 1) {
			score = "Weak";
		}
	
		return score;
	};
	const passwordStrength = getPasswordStrength(signupValues.password.value);
	const strengthColors = {
		Weak: "indianred",
		Medium: "orange",
		Strong: "#2eb258"
	};
	
    return(
		<div className='signup-container'>
			  <Helmet>
                <link rel="canonical" href="https://product.frejun.com/signup" />
              </Helmet>
			<div className='signup-art'>
			<a href="https://frejun.com/" target="_blank" rel="noopener noreferrer"> <img src={logo} alt="Logo" /></a>
				<img src={ dotsTop } alt="matrix of dots at the top"/>
			<div className='reviews-container'>
				<ul>
					{
					reviews.map((r, idx) => {
						let rating = Math.trunc(r.rating);
						return (
							<li key={idx}>
								<div className='rating-container'>{[...Array(rating).keys()]
										.map(() => <span>{filledStar()}</span>)}
									 {r.rating > rating && <span>{halfFilledStar()}</span>}
								</div>
								<p className='t6 medium-font'>{r.text}</p>
								{/* <p className='t8 regular-font nc-gray-1000'>- {r.author}, {r.role}</p> */}
							</li>
						)
					})}
				</ul>
				<div className='badge-container'>
					<span><img src={G2badge1} alt='OutboundCallTracking_EasiestToDoBusinessWith_EaseOfDoingBusinessWith'/></span>
					<span><img src={G2badge2} alt='OutboundCallTracking_HighPerformer'/></span>
					<span><img src={G2badge3} alt='OutboundCallTracking_HighPerformer_Mid-Market_AsiaPacific_HighPerformer'/></span>
				</div>
				<a href="https://www.g2.com/products/frejun/reviews#reviews" target="_blank" className='link-text'>Read more reviews on G2</a>
			</div>
				<img src={ dotsBottom } alt="matrix of dots at the bottom"/>
				{/* <p className='regular-font t6'>Simplify and Automate<br/>&nbsp;&nbsp;Your Calling Process</p> */}
			</div>
			<h1 style={{position:'absolute',visibility:'hidden'}}>Frejun Signup</h1>
			<div className='signup-form'>
				<div className='logo-container'>
				<img className='logo-image' onClick={() => window.location.href = "https://frejun.com/"} src={logo} alt="Logo" style={{ cursor: 'pointer' }} />
				<a href="https://frejun.com/" target="_blank" rel="noopener noreferrer"> <img src={logo} alt="Logo" /></a>

				</div>

				<p className='title medium-font t5'>Start your free trial!</p>
				<form onSubmit={e => handleSignupSubmit(e)} className='nc-gray-900'>
					<div className='name-container'>
						<CustomInput  CustomError={signupValues.first_name.error}  value={signupValues.first_name} label="First name" handleChange={handleSignupChange} handleFocus={handleFocus} name="first_name" type="text"/>
						<CustomInput CustomError={signupValues.last_name.error}  value={signupValues.last_name} label="Last name" handleChange={handleSignupChange} handleFocus={handleFocus} name="last_name" type="text"/>
					</div>
					<CustomInput CustomError={signupValues.email.error}  value={signupValues.email} label="Work email" handleFocus={handleFocus} handleChange={handleSignupChange} name="email" type="text"/>
					<CustomInput CustomError={signupValues.phone_number.error}  dropdown={<CCDropdown selectedCountryCode={signupValues.countryCode?.value} handleCountrySelect={(val)=>handleSignupChange(null, 'countryCode', val)}/>} value={signupValues?.phone_number} label="Phone number" handleFocus={handleFocus} handleChange={handleSignupChange} name="phone_number" type='tel' />
					<CustomInput CustomError={signupValues.password.error}  value={signupValues.password} label="Password" handleFocus={handleFocus} handleChange={handleSignupChange} name="password" type="password"/>
					<div style={{ color: strengthColors[passwordStrength] }}>
						{signupValues.password.value && <span> Password Strength: {passwordStrength}</span>}
					</div>
					<Button disabled={isDisabled} icon={{startIcon: null, endIcon: null}}  hierarchy='green' variant='contained' buttonText='Sign up' isLoading={showLoader} onClick={handleSignupSubmit} styleOverride={{width: '100%', marginTop:'min(4%,56px)'}}/>
				</form>
				<div className='medium-font t8 to-terms-and-conditions-container nc-gray-900'>By clicking on “Sign Up” you are creating an account on FreJun you’re agreeing to FreJun’s<a href="https://frejun.com/terms-conditions/" target="_blank" rel="noopener noreferrer" className='to-terms-and-conditions to-sign-up'>Terms and Conditions</a></div>
				<div className='medium-font t7 to-login-container nc-gray-900 '>Already have an account?<a style={{marginLeft: '2px', textDecoration: 'none'}} href='/login' className='to-login medium-font to-sign-up'>Log in</a></div>
			</div>
		</div>
    )
}
