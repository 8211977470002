import { Box } from '@mui/system'
import React, { useEffect, useReducer, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import Button from '../design/Button'
import GHeader from '../design/components/GHeader'
import GTable from '../design/components/GTable'

import colors from '../design/colors'
import { autodialModalResumeIcon, autodialSessionsViewRedialIcon } from '../../utility-functions/assetLoader'
import styles from '../design/styles'
import { setReduxAutodialState } from '../features/autodial/autodial'
import Autodial2 from '../contact-lists-view/autodial2/Autodial2'
import GModal from '../design/components/GModal'
import { useDispatch, useSelector } from 'react-redux'
import { JService } from '../api-service/ApiService'
import { enqueueSnackbar } from 'notistack'

import { CustomTooltip } from '../design/components/customComponents'
import { filtersReducer } from '../../utility-functions'
const initialState = {
	contacted: {
		all: [
		  { id: "yes", name: "Yes" },
		  { id: "no", name: "No" },
		],
		selected: [],
	},
	
	currentSet: '',
	searchText: '',
	searchFields: [],
}

export default function AutodialView() {
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const {id: guid} = useParams()

	const [rows, setRows] = useState([])
	const [masterRows, setMasterRows] = useState([])
	const [showLoader, setShowLoader] = useState(false)
	const [masterContactRows, setMasterContactRows] = useState([])
	const [data, setData] = useState([])

	const [redialValues, setRedialValues] = useState([])
	const [redialModalOpen, setRedialModalOpen] = useState(false)

	const [controller, setController] = useState(0)

	const [autodialSelectedRows, setAutodialSelectedRows] = useState([])

	const [selectionModel, setSelectionModel] = useState([])
	const reduxAccount=useSelector((state)=>state?.account?.value)
	const redialButton = <Button tooltipMessage="Select atleast 2 and not more than 100 contacts" icon={{startIcon: (selectionModel?.length < 2 || selectionModel?.length > 100) ? autodialSessionsViewRedialIcon(colors.nc_gray_300) : autodialSessionsViewRedialIcon(), endIcon: null}} disabled={(reduxAccount?.data?.license==='Viewer' || !reduxAccount?.data?.license) || selectionModel?.length < 2 || selectionModel?.length > 100} hierarchy='white' variant='contained' buttonText='Redial' isLoading={false} onClick={handleRedial} styleOverride={{border: `1px solid ${colors.nc_gray_300}`}}/>
	const resumeButton = <Button title='Click to resume' icon={{startIcon: autodialModalResumeIcon(), endIcon: null}} disabled={(reduxAccount?.data?.license==='Viewer' || !reduxAccount?.data?.license)} hierarchy='green' variant='contained' buttonText='Resume' isLoading={false} onClick={handleResumeAutodial} styleOverride={{border: `1px solid ${colors.nc_gray_300}`}}/>
	const reduxAutodial = useSelector(state => state?.autodial?.value)
	const maskNumbers = useSelector((state) => state?.auth?.maskNumbers);

	function handleRedial(e) {
		// if(status of the current autodial list is not paused)
		// start a fresh session with
		// selected contacts are redialed
		// open the modal to set the name and then start

		setRedialModalOpen(true)
	}

	function handleResumeAutodial(e) {
		if(!showLoader) setShowLoader(true)
		localStorage.setItem('frejun-autodial-no-resume', true)
		dispatch(setReduxAutodialState({maximized: true, minimized: false, resumeRead: true, resume: 'resumed', guid: guid, interval: data?.find(d => d?.id === parseInt(guid))?.interval, name: data?.find(d => d?.id === parseInt(guid))?.name }))
		setShowLoader(false);
	}

	const redialModalBody = redialModalOpen ? <Box>
		<form style={localStyles.form}>
			{/* redial autodial name */}
			<label className='t7 nc-gray-900 medium-font' style={localStyles.label}>
				Campaign name
				<input 
					value={redialValues?.name} 
					type='text' 
					className='t6 regular-font nc-gray-900' 
					style={localStyles.textInput} 
					data-edittype='name' 
					onChange={e => setRedialValues(prev => ({...prev, name: e.target.value}))}
				/>
			</label>
		</form>
	</Box> : null
	const cols = [
		{
		field: "autodialSessionsViewName",
		headerName: "Name",
		headerClassName: 'logs-table-header-call-reason-outcome',
		flex: 1,
		renderCell: (params) => {
			return (
			<p style={{ textDecoration: "none", color: colors.nc_gray_900, paddingLeft: '14px', }} >
				<CustomTooltip tooltipText={params?.row?.autodialSessionsViewName} tooltipTitle={params?.row?.autodialSessionsViewName}/>
			</p>
			);
		},
		},
		...maskNumbers ? [] : [{
			field: "autodialSessionsViewPhoneNumber",
			headerName: "Phone number",
			flex: 1,
		
		}],
		
		{
			field: "autodialSessionsViewContacted",
			headerName: "Contacted",
			headerClassName: "autodial-view-screen-table-header-contacted",
			flex: 1,
		},
		{
			field: "autodialSessionsViewCallHistory",
			headerName: "Call History",
			flex: 1,
			renderCell: params => <Box onMouseDown={e => e.stopPropagation()} onClick={e => handleCallHistoryViewClick(e, params?.row?.id)} sx={{color: colors.ic_green_500, ...styles.t6, ...styles.regular_font, textDecoration: 'underline', cursor: 'pointer', padding: '4px 10px', paddingLeft: '0px',}}>
				View
			</Box>
		},
	];

	function handleCallHistoryViewClick(e, rowId) {
		e.stopPropagation()
		let currentRow = masterRows?.find(mr => mr?.id === rowId)
		let payload = {
			first_name: currentRow?.autodialSessionsViewName,
			autodial_id: parseInt(guid),
			last_contacted: {contacted_on: currentRow?.autodialSessionsViewCallHistory} || {},
			phone_number: currentRow?.autodialSessionsViewPhoneNumber,
		}
		localStorage.setItem('frejun-interaction-history', JSON.stringify({dset: true, qread: false, data: payload, cread: false,}))
        navigate("/call-logs")
	}
	
	useEffect(()=>{
		const abortController = new AbortController();
		setShowLoader(true)
		
		JService.get(`/api/v1/core/retrieve-autodial-sessions/`, {}, {signal: abortController.signal})
		.then(res => {
			if(!('success' in res)) return;
			if(!res?.success) throw new Error(res?.message || 'Failed to retrieve autodial session data')
			setShowLoader(false);
			setData(res?.data)
			setRedialValues(res?.data?.find(d => d?.id == guid))
			let tempData = res?.data?.find(r => r?.id == guid)

			
			let tempContacts = Object.values(tempData?.contacts)
			let tempFailed = Object.values(tempData?.contacts_failed)
			let tempRemaining = Object.values(tempData?.contacts_remaining)
			let tempSkipped = Object.values(tempData?.contacts_skipped); 
			// CLARIFY: shouldn't we add skipped also, since we have the array

			let sendData = tempContacts?.map(t => ({
				id: t?.id,
				autodialSessionsViewName: t?.first_name,
				autodialSessionsViewPhoneNumber: t?.phone_number,
				autodialSessionsViewContacted: (tempFailed?.findIndex(tt => tt?.id === t?.id) === -1 && tempRemaining?.findIndex(tt => tt?.id === t?.id) === -1&& tempSkipped?.findIndex(tt => tt?.id === t?.id) === -1) ? 'Yes' : 'No',
				autodialSessionsViewCallHistory: tempData?.created_at,
				autodialSessionsViewLastContactedHidden: 'View',
			}));
			

			setRows(sendData)
			setMasterRows(sendData)

			setMasterContactRows(tempContacts || [])
		})
		.catch(err =>{
			setShowLoader(false);
			enqueueSnackbar(err?.message, { variant: "error"})
		}) 
		
		return () => abortController.abort();
		
	}, [controller])

	const [availableData, dispatchAvailableData] = useReducer(filtersReducer, initialState)

	const onSearch = searchText => {
		const searchTextLower = searchText?.toLowerCase()
		const temp = masterRows?.filter(mrf => maskNumbers ? mrf?.autodialSessionsViewName?.toLowerCase()?.includes(searchTextLower) : mrf?.autodialSessionsViewName?.toLowerCase()?.includes(searchTextLower) || mrf?.autodialSessionsViewPhoneNumber?.toLowerCase()?.includes(searchTextLower))
		combineSearchAndFilters(temp)
	}

	useEffect(()=>{
		if(onSearch instanceof Function)  {
			onSearch(availableData?.searchText)
		}	
	}, [availableData]) //eslint-disable-line
	
	function combineSearchAndFilters(arg1) {
		// if filters and search is empty
		if((!availableData?.contacted?.selected?.length && availableData?.searchText.trim()?.length === 0)) {
			if(arg1 != rows){
				setRows(arg1) //eslint-disable-line
			}
			return
		}
		let temp = arg1
		if(availableData?.contacted?.selected?.length) {
			temp = temp?.filter(te => availableData?.contacted?.selected?.includes(te?.autodialSessionsViewContacted?.toLowerCase()))
		}

		setRows(temp)
	}
	
	const [buttonText, setButtonText] = useState(`Select all ${rows?.length} contacts in this list`)
	const [buttonPrefix, setButtonPrefix] = useState()
	const [_pageSize, _setPageSize] = useState(10)
	const [areAllSelected, setAreAllSelected] = useState(false)
	const [showTooltip, setShowTooltip] = useState(true)
	const [selectedRows, setSelectedRows] = useState([]);
	
	function handleSelectionModelChange(selectionModelChangeArg, pageSize) {
		let idArrayFromSelectionModelChangeArg = selectionModelChangeArg?.map(sm => sm?.id)
		setSelectedRows(masterContactRows?.filter(r => idArrayFromSelectionModelChangeArg?.includes(r?.id)))
		setSelectionModel(idArrayFromSelectionModelChangeArg)
		setButtonPrefix(idArrayFromSelectionModelChangeArg?.length)
		_setPageSize(pageSize)
	}

	useEffect(()=>{
		if(selectionModel?.length === 0 && areAllSelected) {
			setAreAllSelected(false)
			setButtonPrefix(selectionModel?.length)
			setShowTooltip(true)
			setButtonText(`Select all ${rows?.length} contacts in this list`)
		}
	
		if(selectionModel?.length !== 0 && !areAllSelected) {
			setButtonPrefix(selectionModel?.length)
			setButtonText(selectionModel?.length === rows?.length ? 'Clear selection':`Select all ${rows?.length} contacts in this list`)
			setAreAllSelected(true)
			setShowTooltip(selectionModel?.length > 100 ? true : false)
		}
	
	}, [selectionModel])


	useEffect(()=>{
		if(parseInt(buttonText) !== rows.length) setButtonText(`Select all ${rows?.length} contacts in this list`)
	}, [rows])

	  
	function handleSelectAllRows(e) {
		if(buttonText?.toLowerCase() !== 'clear selection'){
			handleSelectionModelChange(masterContactRows)
			setButtonText('Clear selection')
			setAreAllSelected(true)
			setButtonPrefix(rows?.length)
		} else {
			handleSelectionModelChange([])
			setButtonText(`Select all ${rows?.length} contacts in this list`)
			setAreAllSelected(false)
			setButtonPrefix(_pageSize)
		}
	}

	function handleRedialModalClose(e) {
		setRedialModalOpen(false)
		setRedialValues([])
	}

	function handleRedialModalSave(e) {
		// start the autodial here with the selected contacts
		setRedialModalOpen(false)
		let payloadData = redialValues?.contacts

		// let arrayFromPayloadData = Object.values(payloadData)
		// arrayFromPayloadData = arrayFromPayloadData?.filter(pd => selectionModel?.find(sr => parseInt(sr) == pd?.id) != -1)
		// // array of values that have their ids in selectionModel array
		let finalSend = {}
		
		let arrayFromPayloadData = Object.values(payloadData)
		arrayFromPayloadData = arrayFromPayloadData?.filter(ap => selectionModel?.includes(ap?.id))
		
		for(let i=0; i<arrayFromPayloadData?.length; ++i) {
			if(selectionModel?.includes(i+1)) {
				finalSend[(i+1).toString()] = {
					id: (i + 1),
					first_name: arrayFromPayloadData[i]?.first_name,
					phone_number: arrayFromPayloadData[i]?.phone_number?.slice(-10),
					notes: arrayFromPayloadData[i]?.notes
				}
			}
		}

		let payload = {
			name: redialValues?.name || '',
			interval: redialValues?.interval || 5,
			contacts: finalSend || {},
		}

		let autodialSendData = Object.values(finalSend)
		setAutodialSelectedRows(autodialSendData)
		dispatch(setReduxAutodialState({minimized: false, maximized: true, ...payload }))
	}

	useEffect(()=>{
		if(!reduxAutodial?.maximized && autodialSelectedRows?.length) {
			setController(prevState => ++prevState)
			setSelectionModel([])
			setAutodialSelectedRows([])
		}
	}, [reduxAutodial])

	return (
		<div
		  className="screen-container autodial-view-screen-container"
		>
	
			<GHeader
				breadcrumbs={false}
				threeDots={false}
				topHeader={{}}
				bottomHeader={{
				contentTitle: "Contacts",
				buttons: [data?.find(d => d?.id === parseInt(guid))?.status?.toLowerCase() === 'paused' ? resumeButton: redialButton],
				isCalendar: false,
				}}
			/>

			{/* gmail select one page || select all pages UI */}
	  
			<Box sx={{
			border: `1px solid ${colors.ic_green_400}`,
			borderRadius: '6px',
			marginBottom: '24px',
			height: '56px',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'space-between',
			width: 'calc(100%)',
			paddingLeft: '18px',
			paddingRight: '18px',
			display: areAllSelected ? 'flex': 'none',
		}}>
			{/* main-content */}
			<Box style={{
				

				borderRadius: '6px',
				height: '56px',
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'center',
				width: 'calc(100%)',
				userSelect: 'none',
				// overrides

			}}>
				
				<span 
					style={{
						...styles.regular_font,
						...styles.t6,
					}}
				>
					{buttonPrefix === rows?.length && 'All '}
					<span 
						style={{
							...styles.semibold_font,
						}}
					>
						{buttonPrefix}
					</span>
					&nbsp;contacts on this page are selected.
				</span>
				<Button 
					disabled={false} 
					hierarchy='white' 
					variant='outlined' 
					buttonText={buttonText}
					isLoading={false} 
					onClick={handleSelectAllRows}
					styleOverride={{
						marginLeft: '10px',
						paddingLeft: '12px',
						paddingRight: '12px',
						color: colors.ic_green_400,
						textDecoration:'underline',
						border: 'none',
						':hover': {
							border: 'none',
							textDecoration:'underline',
						}
					}}
				/>
				
			</Box>
		</Box>
	
		  	<GTable
				leftHeader={{
					filters: [{name: 'Contacted', toggle: false, isSearch: false, payloadFor: 'contacted'},],
					// filters: [],
				}}
				dispatchAvailableData={dispatchAvailableData}
				availableData={availableData}
				fromScreen='autodialScreenView'
				rightHeader={{
				isSearch: true,
				}}
				rows={rows}
				columns={cols}
				useMuiTable={true}
				isLoading={showLoader}
				getTableRowClassName={() => `teams-table-row`}
				additionalProps={{ bgHover: true }}
				onRowClick={() => null}
				onSearch={onSearch}
				combineSearchAndFilters={combineSearchAndFilters}
				checkboxSelection={true}
				onSelectionModelChange={handleSelectionModelChange}
				handleSelectionModelChange={handleSelectionModelChange}
				selectionModel={selectionModel}
				DataGridForcedPropsKey={'checkboxSelectionVisibleOnly'}
		    />


			{/* edit modal */}
			<GModal 
				disableCloseButton={showLoader}
				closeModal={handleRedialModalClose}
				body={redialModalBody}
				heading={<span className='t6 nc-gray-900 medium-font'>Edit campaign</span>}
				visible={redialModalOpen}
				bottomButtons={[<Button disabled={false} hierarchy='white' variant='contained' buttonText='Cancel' isLoading={false} onClick={handleRedialModalClose}/>,
				<Button disabled={false} hierarchy='green' variant='contained' buttonText='Start' isLoading={false} onClick={handleRedialModalSave}/>]}
			/>

			<Autodial2 data={data?.find(d => d?.id === parseInt(guid))?.status?.toLowerCase() === 'paused' ? data : autodialSelectedRows} contactListId={redialValues?.contact_list}/>
	
		</div>
	  )
}

const localStyles = {
	// redial modal body
	form: {
		display: 'flex',
		flexDirection: 'column',
		maxWidth: '100%'
	},
	label: {
		marginBottom: '24px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		alignItems: 'stretch',
	},
	textInput: {
		marginTop: '4px',
		padding: '8px 12px',
		borderRadius: '4px',
		border: '1px solid #E1E6E2',
	}
}
