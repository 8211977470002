import { Navigate, Outlet } from 'react-router-dom';
import { hasPermission } from '../features/permission/auth';

const ProtectedRoute = ({children, ...props }) => {
  let result = false;
  if(props.category && Array.isArray(props.category)){
    result = props.category.some(elem => hasPermission(elem, props.action))
  }
  else {
    result = hasPermission(props.category, props.action)
  }
  return result ? <Outlet/> : <Navigate to="/permission-denied" replace/>;
};

export default ProtectedRoute;
