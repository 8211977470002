// # components
import GHeader from "../../design/components/GHeader";
import Button from "../../design/Button";
import GTable from "../../design/components/GTable";

// # assets
import userEditIcon from "../../../assets/call-logs-edit.svg";

// # lib
import { useEffect, useReducer, useState } from "react";
import { Box, Menu, MenuItem} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { JService } from "../../api-service/ApiService";
import { enqueueSnackbar } from 'notistack';
import { CustomTooltip } from "../../design/components/customComponents";
import { filtersReducer, getPermissionId } from "../../../utility-functions";
import { hasPermission } from "../../features/permission/auth";
import CreateEditTeamModal from "./CreateEditTeamModal";
import DeleteTeamModal from "./DeleteTeamModal";
import { useSelector } from "react-redux";

// returns response data in formatted form
const generateTeamsTableRowsData = (data) => {
  const returnData = Object.values(data).reduce((cumulative, key) => {
    let { id, name, total_members, virtual_numbers } = key;

    let row = {
      id,
      teamTeam: name,
      teamMemberCount: total_members,
      teamVirtualNumbers: virtual_numbers,
    };

    cumulative.push(row);
    return cumulative;
  }, []);
  return returnData;
};

const initialState = {
  searchText: "",
  searchFields: ["teamTeam"],
};

export default function Teams() {
  const navigate = useNavigate();

  const {canCreateEditTeams, canViewUsers} = useSelector((state) => state?.auth?.permissions);

  const [rows, setRows] = useState([]);
  const [masterRows, setMasterRows] = useState([]);
  const [cols, setCols] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [controller, setController] = useState(1);
  // response data of sub-org for edit team
  const [data, setData] = useState([]);
  const [usersArray, setUsersArray] = useState([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [dropdownAnchorEl, setDropdownAnchorEl] = useState(null);
  const [createEditModalVisible, setCreateEditModalVisible] = useState({visible: false, mode: ''})
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  // details of team on which latest click was made via threedots
  // or in the dropdown options if edit is clicked team is set to
  //  the item filtered out from data (response data)
  const [team, setTeam] = useState(null);

  const [availableData, dispatchAvailableData] = useReducer(
    filtersReducer,
    initialState
  );

  useEffect(() => {
    // after search call combineSearchAndFilters
    // simple search
    const searchTextLower = availableData?.searchText?.toLowerCase();
    let finalResult = [];
    let intermediateResult = []; // eslint-disable-line
    availableData?.searchFields?.forEach((searchField) => {
      let intermediateResult = masterRows?.filter((row) =>
        row[searchField]?.toLowerCase()?.includes(searchTextLower)
      );
      finalResult = finalResult.concat(intermediateResult);
    });

    combineSearchAndFilters(finalResult);
  }, [availableData]); //eslint-disable-line

  function combineSearchAndFilters(arg1) {
    // apply the filters on arg1
    let temp = arg1;
    // @note removing duplicates
    temp = temp?.reduce(function (x, y) {
      if (x.indexOf(y) < 0) x.push(y);
      return x;
    }, []);

    setRows(temp);
  }

  const handleCreateTeamClick = (e) => {
    if (deleteModalVisible) handleCloseDeleteModal();
    setCreateEditModalVisible({visible: true, mode: 'Create'});
  };

  const createTeamButton = (canCreateEditTeams ?
    <Button
      variant="filled"
      buttonText="Create team"
      isLoading={false}
      hierarchy="green"
      onClick={handleCreateTeamClick}
	  className="ui--create-team"
    />
  : null);

  function handleThreeDotsClick(e, id) {
    e.stopPropagation();

    setIsDropdownVisible(true);
    setDropdownAnchorEl(e.currentTarget || null);
    setTeam(id);
  }

  // onclick of one of threedots options
  const handleDropdownClick = (e) => {
    const menuitemtype = e.target.dataset.menuitemtype;
    if (menuitemtype === "edit") {
      setCreateEditModalVisible({visible: true, mode: 'Edit'})
    } else if (menuitemtype === "delete") {
      setDeleteModalVisible(true);
    }
    // closing the dropdown
    setIsDropdownVisible(false);
  };

  useEffect(() => {
    const abortController = new AbortController();
    if (!showLoader) setShowLoader(true);
    JService.get(`/api/v1/auth/teams/?permission_id=${getPermissionId(null, 'view teams')}`, {}, {signal: abortController.signal})
      .then((res) => {
        if(!('success' in res)) return;
        if (!res?.success)
          throw new Error(res?.message || "Could not retreive teams");
        setShowLoader(false);
        setCols([
          {
            field: "teamTeam",
            headerName: "Name",
            headerClassName: "teams-table-header-team-name",
            cellClassName: "teams-table-cell-team-name",
            flex: 1,
            renderCell: (params) => (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  transition: "all 0.3s ease-in",
                  textDecoration: "none",
                  paddingLeft: "16px",
                  /* Interface Colors/Green - 500 */
                  color: "#28994B",
                }}
              >
                <span style={{ textDecorationColor: "#28994B" }}>
                  {params?.row?.teamTeam}
                </span>
              </Box>
            ),
          },
          {
            field: "teamVirtualNumbers",
            headerName: "Virtual number",
            flex: 1,
            renderCell: (params) => {
              return (
                <CustomTooltip
                  disableHoverListener={
                    params?.row?.teamVirtualNumbers.length < 2
                  }
                  placement="top"
                  arrow
                  title={
                    <p className="t7">
                      {Array.isArray(params?.row?.teamVirtualNumbers) &&
                        params?.row?.teamVirtualNumbers.map((vn, index) => (
                          <span
                            key={index}
                            className="t6 regular-font nc-gray-900"
                          >
                            {(index != 0 ? ", " : "") +
                              (vn?.country_code || " ") +
                              " " +
                              (vn?.number || " ")}
                          </span>
                        ))}
                    </p>
                  }
                  sx={{
                    "& .MuiTooltip-arrow": {
                      color: "#E1E6E2",
                    },
                  }}
                >
                  <div style={{ maxWidth: "100%" }}>
                    <Box
                      sx={{
                        justifyContent: "center",
                        display: "inline-block",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        width: "100%",
                      }}
                    >
                      {Array.isArray(params?.row?.teamVirtualNumbers) &&
                        params?.row?.teamVirtualNumbers
                          .map((vn) => {
                            return vn.country_code + " " + vn.number;
                          })
                          .join(", ")}
                    </Box>
                  </div>
                </CustomTooltip>
              );
            },
          },
          {
            field: "teamMemberCount",
            headerName: "Members",
            flex: 1,
          },
          {
            field: "teamEdit",
            headerName: "",
            flex: 0.5,
            cellClassName: "table-cell-edit",
            renderCell: (params) => {
              return (
                <Box data-teamid={params?.id}>
                  {(hasPermission('teams', 'create and edit teams', null, params?.id) 
                || hasPermission('teams', 'delete teams', null, params?.id)) ? (<img
                    data-teamid={params?.id}
                    onClick={(e) => handleThreeDotsClick(e, params?.id)}
                    src={userEditIcon}
                    alt="three dots icon"
                  />) : null}
                </Box>);
            },
          },
        ]);
        setData(res?.data);
        let temp = generateTeamsTableRowsData(res?.data?.reverse() || []);
        setRows(temp);
        setMasterRows(temp);
      })
      .catch((err) => {
        setShowLoader(false);
        enqueueSnackbar(err?.message || "Failed to retreive teams", { variant: "error"})
      })

    if(canViewUsers){
      JService.get(`/api/v1/auth/retrieve-users/?permission_id=${getPermissionId(null, 'create and edit teams')}`)
        .then((res) => {
          if (!res?.success)
            throw new Error(res?.message || "Could not retreive users");
          setUsersArray(res?.data || []);
          let temp = res?.data?.map(
            (rd) => rd?.first_name + " " + rd?.last_name + "," + rd?.id
          );
        })
        .catch((err) => enqueueSnackbar(err?.message, { variant: "error"}))
    }

      return () => {
        abortController.abort();
      }
  }, [controller, canViewUsers]); // eslint-disable-line

  function onRowClick(params, event, details) {
    // reference props.location.state.teamId in the TeamView page.
    navigate(`/teams/${params?.id}`, {
      state: {
        id: params?.id,
      },
    });
  }

  function handleCloseDeleteModal() {
    setDeleteModalVisible(false);
  }

  function handleCreateEditModalClose () {
    setCreateEditModalVisible({visible: false, mode: ''});
    setTeam(null);
  }

  const [csvData, setCSVData] = useState({
    headers: [["Team"], ["Virtual number"], ["Managers"], ["Members"]],
    data: [],
    ids: [],
  });

  const csvDownloadBody = null;

  useEffect(() => {
    setCSVData((prevState) => {
      let newIds = [];
      let newData = [];

      for (let i = 0; i < rows?.length; ++i) {
        let item = rows[i];
        if (!newIds?.includes(item?.id)) {
          newIds.push(item?.id);
          let temp = [
            item?.teamTeam,
            item?.teamVirtualNumbers
              .map((vn) => {
                return vn?.country_code + " " + vn?.number;
              })
              .join(", "),
            item?.teamManager?.join(", ") || "",
            item?.teamMemberCount,
          ];
          newData.push(temp);
        }
      }
      return { ...prevState, data: newData, ids: newIds };
    });
  }, [rows]);

  function threeDotsAutoClose(e) {
    const path = e.composedPath()?.map((pathItem) => {
      let newPathItem = null;
      newPathItem =
        pathItem?.tagName?.toLowerCase() +
        (pathItem?.className ? "." + pathItem?.className : "");
      return newPathItem;
    });

    if (!path?.length || path[0]?.includes("div.MuiBackdrop-root")) {
      // clicked outside
      setIsDropdownVisible(false);
      setDropdownAnchorEl(null);
      return;
    }
  }

  useEffect(() => {
    if (isDropdownVisible) window.addEventListener("click", threeDotsAutoClose);
    else window.removeEventListener("click", threeDotsAutoClose);

    return () => {
      window.removeEventListener("click", threeDotsAutoClose);
    };
  }, [isDropdownVisible]);

  return (
    <div className="screen-container-teams">
      <GHeader
        breadcrumbs={false}
        topHeaderVisible={false}
        threeDots={false}
        topHeader={{}}
        bottomHeader={{
          contentTitle: "",
          buttons: [createTeamButton],
          isCalendar: false,
        }}
      />

      <GTable
        leftHeader={{ filters: {}, isSearch: true }}
        dispatchAvailableData={dispatchAvailableData}
        availableData={availableData}
        rightHeader={{ isRefresh: true, isDownload: true }}
        download={{
          data: csvData,
          body: csvDownloadBody,
          filename:
            "frejun-teams-" +
            new Date().toLocaleString("en-US", {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            }),
        }}
        rows={rows}
        fromScreen="teams"
        columns={cols}
        useMuiTable={true}
        isLoading={showLoader}
        additionalProps={{ bgHover: true }}
        onRowClick={onRowClick}
        searchFields={["teamTeam"]} // set in availableData's initialState
        combineSearchAndFilters={combineSearchAndFilters}
      />

      {/* three dots dropdown menu */}
      <Menu
        disableScrollLock={true}
        open={isDropdownVisible}
        anchorEl={dropdownAnchorEl}
      >
        {hasPermission('teams', 'create and edit teams', null, team) && 
        <MenuItem
          data-menuitemtype="edit"
          onClick={handleDropdownClick}
          sx={{ cursor: "pointer" }}
        >
          Edit
        </MenuItem>}

        {hasPermission('teams', 'delete teams', null, team) && 
        <MenuItem
          data-menuitemtype="delete"
          onClick={handleDropdownClick}
          sx={{ cursor: "pointer" }}
        >
          Delete
        </MenuItem>}
      </Menu>
      <DeleteTeamModal
       visible={deleteModalVisible} 
       handleClose={handleCloseDeleteModal}
       teamId={team}
       teamName={team?.name || data?.find((d) => d?.id == team)?.name}
       refetchData={() => setController(prev => ++prev)}
      />
      <CreateEditTeamModal 
        visible={createEditModalVisible.visible}
        handleClose={handleCreateEditModalClose}
        mode={createEditModalVisible.mode}
        usersArray={usersArray}
        teamId={team}
        refetchData={()=>setController(prev => ++prev)}
      />
    </div>
  );
}