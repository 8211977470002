import { useEffect, useMemo, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Menu, MenuItem  } from "@mui/material";
import { enqueueSnackbar } from "notistack";

import GTable from "../design/components/GTable";
import GHeader from "../design/components/GHeader";
import Button from "../design/Button";
import SurveyModal from './SurveyModal';

import {threeDotsIcon, topNavAccountPlusIcon} from "../../utility-functions/assetLoader";
import { JService } from "../api-service/ApiService";
import { formatDate } from "../../utility-functions";

import styles from "./Survey.module.css";
import gstyles from '../design/styles'
import colors from "../design/colors";
import { useSelector } from "react-redux";
import DeleteSurveyModal from "./DeleteSurveyModal";


const initialState = {
  searchText: "",
};
const Survey = () => {
  const navigate = useNavigate();
  const reduxAccount = useSelector(state=>state?.account?.value);
  const userRole = reduxAccount?.data?.profile?.role;

  const [rows, setRows] = useState([]);
  const [rowsToDisplay, setRowsToDisplay] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rowOptionsCords, setRowOptionsCords] = useState([]);
  const [rowBeingEdited, setRowBeingEdited] = useState(null);
  const [rowOptionsVisible, setRowOptionsVisible] = useState(false);
  const [surveyModalVisible, setSurveyModalVisible] = useState({visible: false, mode:''});
  const [deleteSurveyModalVisible, setDeleteSurveyModalVisible] = useState(false);
  const [dropdownAnchorEl, setDropdownAnchorEl] = useState(null);

  const searchReducer = (state, action) => {
    if (action.type === "setSearchText") {
      if (typeof action.payload !== "string") return state;
      return {
        ...state,
        searchText: action.payload,
      };
    } else {
      return state;
    }
  };
  const [availableData, dispatchAvailableData] = useReducer(
    searchReducer,
    initialState
  );

  const fetchData = async (signal) => {
    try {
      setLoading(true);
      const res = await JService.get("/api/v1/core/retrieve-ivr-surveys/", {}, {signal});
      if(!('success' in res)) return;
      if (!res.success) {
        throw new Error(res.message || "Error while retrieving surveys");
      }
      setLoading(false);
      setRowsToDisplay(res.data);
      setRows(res.data);
    } catch (err) {
      setLoading(false);
      enqueueSnackbar(err.message || "Error while retrieving surveys", {
        variant: "error",
      });
    } 
    // finally {
    //   setLoading(false);
    // }
  };

  const handleEditSurvey = () => {
    handleDropdownClose()
    setSurveyModalVisible({visible: true, mode:'Edit'});
  }

  const handleDeleteClicked = () => {
    setDeleteSurveyModalVisible(true);
    handleDropdownClose()
  }

  const handleDeleteModalClose = () => {
    setDeleteSurveyModalVisible(false);
    setRowBeingEdited(null);
  }

  const handleSurveyModalClose = () => {
    setSurveyModalVisible({visible:false, mode:''});
    fetchData()
  }

  const handleDropdownClose = () => {
    setRowOptionsVisible(false);
    setDropdownAnchorEl(null);
  }

  const onSearch = (searchText) => {
    if(!rows || !Array.isArray(rows)) return;
    if (!searchText || !searchText.trim().length) {
      setRowsToDisplay([...rows]);
      return;
    }
    const searchTextLower = searchText.toLowerCase();
    const temp = rows.filter((row) => {
      return row?.title?.toLowerCase()?.includes(searchTextLower);
    });
    setRowsToDisplay(temp);
  };

  const handleThreeDotsClick = (e, params) => {
    setRowOptionsCords([e["clientX"], e["clientY"]]);
    e.stopPropagation();
    setRowBeingEdited(params.row);
    setRowOptionsVisible(true);
    setDropdownAnchorEl(e.target);
  };

  useEffect(() => {
    const controller = new AbortController();
    fetchData(controller.signal);

    return () => controller.abort();
  }, []);

  useEffect(()=>{
    onSearch(availableData?.searchText)
  },[availableData?.searchText, rows]);

  useEffect(()=>{
    if(rowOptionsVisible) window.addEventListener("click", handleDropdownClose);
	  else window.removeEventListener("click", handleDropdownClose);

    return () => {
      window.removeEventListener("click", handleDropdownClose);
    };
   },[rowOptionsVisible]);

  const createSurveyBtn = (
    <Button
      variant="contained"
      hierarchy="green"
      buttonText="New survey"
      icon={{ startIcon: topNavAccountPlusIcon("#fff") }}
      onClick={()=>setSurveyModalVisible({visible:true, mode:'Create'})}
    />
  );

  const cols = useMemo(
    () => [
      {
        field: "title",
        headerName: "Campaign",
        flex: 0.33,
        // width: 400,

        renderCell: (params) => {
          return (
            <p
              style={{
                textDecoration: "underline",
                color: "#28994B",
                marginLeft: "14px",
                marginRight: "24px",
                marginRight: "32px",
              }}
              className={styles.spanNoOverFlow}
              onClick={(e) => {
                  navigate(`/survey/${params?.id}`, {state: {contentTitle: params.row.title}});
              }}
            >
              {params.row.title}
            </p>
          );
        },
      },
      {
        field: "last_response",
        headerName: "Last response",
        headerClassName:'survey-table-header',
        flex: 0.19,
        renderCell: (params) => {
          return (
            <p className={styles.spanNoOverFlow}>
              {params.row.last_response ? formatDate(params.row.last_response) : '--'}
            </p>
          );
        },
      },
      {
        field: "responses",
        headerClassName:'survey-table-header',
        headerName: "Responses",
        flex: 0.17,
      },
      {
        field: "created_at",
        headerClassName:'survey-table-header',
        headerName: "Created on",
        flex: 0.3,
        renderCell: (params) => {
          return (
            <p className={styles.spanNoOverFlow}>
              {formatDate(params.row.created_at)}
            </p>
          );
        },
      },
      {
        field: "options",
        headerName: "",
        flex: 0.11,
        cellClassName: "table-cell-edit", // @important use this for threedots
        renderCell: (params) => {
          return (
            <Box sx={{ textAlign: "left", border:`1px solid ${colors.nc_gray_300}`, width:'28px', padding:'0px', borderRadius:'4px', height:'28px', textAlign:'center' }}>
              <div onClick={(e) => handleThreeDotsClick(e, params)}>{threeDotsIcon()}</div>
            </Box>
          );
        },
      },
    ],
    []
  );

  return (
    <div
      className="screen-container "
      style={{
        height:
          document.documentElement.scrollHeight - 56 >= window.innerHeight
            ? "fit-content"
            : window.innerHeight,
      }}
    >
      <GHeader
        breadcrumbs={false}
        title=""
        threeDots={false}
        topHeaderVisible={false}
        topHeader={{}}
        bottomHeader={{
          contentTitle: "Surveys",
          buttons: [createSurveyBtn],
          isCalendar: false,
        }}
      />
      <GTable
        leftHeader={{
          filters: [],
          isSearch: true,
        }}
        rightHeader={{}}
        rows={rowsToDisplay}
        columns={cols}
        useMuiTable={true}
        isLoading={loading}
        getTableRowClassName={() => `teams-table-row`}
        additionalProps={{ bgHover: true }}
        onRowClick={()=>null}
        onSearch={onSearch}
        fromScreen="survey"
        availableData={availableData}
        dispatchAvailableData={dispatchAvailableData}
      />
      <Menu
        disableScrollLock={true}
        open={rowOptionsVisible}
        anchorEl={dropdownAnchorEl}
        sx={localStyles.menu}
      >
        <MenuItem
          onClick={handleEditSurvey}
          sx={localStyles.menuItem}
          >
          View/edit survey
        </MenuItem>
        <MenuItem
        onClick={handleDeleteClicked}
        sx={localStyles.menuItem}
        >
          Delete survey
        </MenuItem>
      </Menu>
      <DeleteSurveyModal visible={deleteSurveyModalVisible} handleClose={handleDeleteModalClose} id={rowBeingEdited?.id} title={rowBeingEdited?.title} callback={fetchData}/>
      <SurveyModal visible={surveyModalVisible.visible} handleClose={handleSurveyModalClose} mode={surveyModalVisible.mode} id={rowBeingEdited?.id}/>
    </div>
  );
};

export default Survey;

const localStyles = {
  menu: {
    "& .MuiPaper-root": {
     marginLeft: '-50px',
     boxShadow: 'none',
     border: `1px solid ${colors.nc_gray_300}`,
    },
    "& .MuiPaper-root .MuiList-root.MuiMenu-list":{
      padding: 0
    }
  },
  menuItem: {
    cursor: "pointer",
    padding: '12px 20px', 
    ...gstyles.t7, 
    ...gstyles.regular_font, 
    color: colors.nc_gray_900 
  }
}
