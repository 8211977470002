import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import accessDeniedArt from "../../assets/access-denied-art.svg";
import CallRecordingNew from "../call-recording-new/CallRecordingNew";
import PageNotFound from "../page-not-found/PageNotFound";
import AnimationPage from "../shared/animation-page/AnimationPage";

function SharedCallRecording(props) {
  const navigate = useNavigate();
  const { id: guid } = useParams();
  const API_BASE_URL = process.env.REACT_APP_BETA;
  const [searchParams] = useSearchParams();

  //States
  const [recordingData, setRecordingData] = useState({});
  const [accessDenied, setAccessDenied] = useState(false);
  const [outOfOrg, setOutOfOrg] = useState(false);
  const [linkNotFound, setLinkNotFound] = useState(false); // very old links give res.success == false which was not handled // blank page issue
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    //TODO: confirm if token and checks based on it can be removed/altered
    let token = localStorage.getItem("frejun-temp-jwt");
    if (token) token = JSON.parse(token).access_token;

    let searchQuery = searchParams.get("signature");
    let queryParams = searchQuery ? `?signature=${searchQuery}` : "";

    let url =
      API_BASE_URL + `/api/v1/core/shared-record/${guid}/${queryParams}`;

    setLoading(true);
    axios
      .get(url, { withCredentials: true })
      .then((response) => {
        const res = response?.data;
        if (res?.success) {
          setRecordingData(res?.data);
        } else setLinkNotFound(true);
      })
      .catch((err) => {
        if (err?.request?.status === 401) {
          if (token === null) setAccessDenied(true);
          else setOutOfOrg(true);
        }
        if (err?.request?.status === 403) {
          if (token === null) setAccessDenied(true);
          setOutOfOrg(true);
        }
        console.log("ERROR", err);
        console.warn(err?.message);
        console.log(
          "Failed to access call record associated with the link, please request for a new link or contact Frejun"
        );
      })
      .finally(() => {
        localStorage.removeItem("frejun-login-redirect");
        setLoading(false);
      });
  }, []);

  const Denied = () => {
    const handleLoginClick = (e) => {
      console.log("searchParams:", searchParams.get("signature"));
      localStorage.setItem(
        "frejun-login-redirect",
        JSON.stringify({
          url:
            window.location.pathname +
            "?signature=" +
            searchParams.get("signature"),
          isRead: false,
        })
      );
      navigate("/login");
    };
    return (
      <div className="access-denied-container nc-gray-900 regular-font t6">
        <img src={accessDeniedArt} alt="access-denied" />
        <p className="medium-font t5 ">Login to access</p>
        <p className="medium-font t6">
          Log in to your FreJun account to access recording.
        </p>
        <button
          onClick={(e) => handleLoginClick(e)}
          className="btn btn-green medium-font t6"
        >
          Login
        </button>
      </div>
    );
  };

  function OutOfOrg() {
    const navigate = useNavigate();
    const handleSwitchAccountClick = (e) => {
      localStorage.removeItem("frejun-token");
      localStorage.setItem(
        "frejun-login-redirect",
        JSON.stringify({
          url:
            window.location.pathname +
            "?signature=" +
            searchParams.get("signature"),
          isRead: false,
        })
      );
      navigate("/login");
    };
    return (
      <div className="access-denied-container regular-font nc-gray-900 t6">
        <img src={accessDeniedArt} alt="access-denied" />
        <p className="medium-font t5">Access Denied</p>
        <p style={{ textAlign: "center" }} className="t6">
          You don’t have permission to access the recording.
          <br />
          Switch to an account with permission.
        </p>
        <button
          onClick={(e) => handleSwitchAccountClick(e)}
          style={{
            width: "50%",
            minWidth: "100px",
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
          className="btn btn-green medium-font t6"
        >
          Switch Account
        </button>
      </div>
    );
  }

  return linkNotFound ? (
    <PageNotFound />
  ) : (
    <div style={{ width: "1136px", margin: "42px auto" }}>
      {loading ? (
        <AnimationPage />
      ) : accessDenied ? (
        <Denied />
      ) : outOfOrg ? (
        <OutOfOrg />
      ) : (
        <CallRecordingNew
          recordingDataShared={recordingData}
          shared={true} 
          isWidgetOpen={props.isWidgetOpen}
          handleToggleWidget={props.handleToggleWidget}
        />
      )}
    </div>
  );
}

export default SharedCallRecording;
