import {Box, Avatar, IconButton} from '@mui/material';
import classes from './roles/CreateEditRoleModal.module.css';
import { modalCloseIcon } from '../../utility-functions/assetLoader';
export const SelectedTeamsContainer = ({selectedTeams, allTeams}) => {
    return (
        selectedTeams.map((tid, idx) => (
        <Box
          key={tid}
          sx={{
            display: "flex",
            alignItems: "center",
            padding: "12px 16px",
            borderBottom: idx !== selectedTeams.length - 1 ? "1px solid #FAFAFA" : 'none'
          }}
        >
          <Avatar
            sx={{
              bgcolor: "#F7FAF8",
              color: "gray",
              border: "1px solid #E0E0E0",
              marginRight: "10px",
            }}
          >
            {allTeams[tid].name?.charAt(0)?.toUpperCase()}
          </Avatar>
          <Box sx={{ flexGrow: 1 }}>
            <p className="t7 regular-font nc-gray-900">{allTeams[tid].name}</p>
            <p className={`t7 regular-font nc-gray-500 ${classes.selectedSecondaryText}`}>
              {`${allTeams[tid].total_members} users`}
            </p>
          </Box>
        </Box>
      ))
    )
}

export const SelectedUsersContainer = ({selectedUsers, allUsers, handleRemoveUser, noSelectionText = ''}) => {
    return (
        (!selectedUsers || !selectedUsers.length) ? (
        <p className={`t7 regular-font nc-gray-500 ${classes.noSelectionText}`}>{noSelectionText}</p>) :
        selectedUsers.map((uid) => (
            <Box
              className={classes.selectedRoleUser}
              key={uid}
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "14px",
                "&:hover": {
                  backgroundColor: "#F7FAF8",
                },
              }}
            >
              <Avatar
                sx={{
                  bgcolor: "#F7FAF8",
                  color: "gray",
                  border: "1px solid #E0E0E0",
                  marginRight: "10px",
                }}
              >
                {allUsers[uid].first_name?.charAt(0)?.toUpperCase() 
                || allUsers[uid].email?.charAt(0)?.toUpperCase()}
              </Avatar>
              <Box sx={{ flexGrow: 1 }}>
                <p className="t7 regular-font nc-gray-900">{allUsers[uid].first_name + ' ' + allUsers[uid].last_name}</p>
                <p className={`t7 regular-font nc-gray-500 ${classes.selectedSecondaryText}`}>{allUsers[uid].email}</p>
              </Box>
              <IconButton
                sx={{width: '24px', height: '24px', padding: 0, borderRadius: '3px', '&:hover': {backgroundColor: '#f4f4f5'}}}
                onClick={() => handleRemoveUser(uid)}
                className={classes.removeIcon}
              >
                {modalCloseIcon()}
              </IconButton>
            </Box>
          ))
    )
}