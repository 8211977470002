import React, { useEffect, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import { capitalizeString, filtersReducer } from "../../utility-functions";
import { JService } from "../api-service/ApiService";
import GHeader from "../design/components/GHeader";
import GTable from "../design/components/GTable";
import userEditIcon from "../../assets/call-logs-edit.svg";
import { Box } from "@mui/system";
import GModal from "../design/components/GModal";
import Button from "../design/Button";
import { dropdownIcon, modalCloseIcon, topNavAccountPlusIcon } from "../../utility-functions/assetLoader";
import styles from './VirtualNumber.module.css'
import { setVirtualNumbersCount } from "../features/virtual-number/virtualNumber";
import { useDispatch, useSelector } from "react-redux";
import { enqueueSnackbar } from 'notistack';

const initialState = {
  locations: {
    all: [
      { id: "Hyderabad, India", name: "Hyderabad" },
      { id: "Vijayawada, India", name: "Vijayawada" },
      { id: "Mumbai, India", name: "Mumbai" },
      { id: "Bengaluru, India", name: "Bengaluru" },
    ],
    selected: [],
  },
  numberTypes: {
    all: [
      { id: "Calling", name: "Calling" },
      { id: "Voice Broadcast", name: "Voice broadcast" },
    ],
    selected: [],
  },
  // currentSet: "",
  searchText: "",
  searchFields: [],
};

const API_BASE_URL = process.env.REACT_APP_BETA;
function VirtualNumber({handleToggleWidget,isWidgetOpen}) {
  const navigate = useNavigate();
  const dispatch=useDispatch();
  const reduxSubscription=useSelector(state=>state?.account?.value?.subscription)

  const [showLoader, setShowLoader] = useState(false);
  const [rows, setRows] = useState([]);
  const [masterRows, setMasterRows] = useState([]);
  const [vnInfo,setVNInfo]=useState({})
  const [displayAllVn,setDisplayAllVn]=useState(false)
  const [maximizeBenefitInfoVisible,setMaximizeBenefitInfoVisible]=useState(false)

  // Three dots options
  const [rowBeingEdited, setRowBeingEdited] = useState(null);
  const [rowOptionsCords, setRowOptionsCords] = useState([0, 0]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editRowName, setEditRowName] = useState("");

  const [csvData, setCSVData] = useState({
    headers: [
      ["Number name"],
      ["Virtual number"],
      ["Location"],
      ["Type"],
      ["Users"],
    ],
    data: [],
    ids: [],
  });

  const [availableData, dispatchAvailableData] = useReducer(
    filtersReducer,
    initialState
  );

  useEffect(() => {
    fetchData();
  }, []);

  // UseEffect for Search
  useEffect(() => {
    onSearch(availableData?.searchText);
  }, [availableData]);

  // UseEffect to set CSV data
  useEffect(() => {
    setCSVData((prevState) => {
      let newIds = [];
      let newData = [];

      for (let i = 0; i < rows.length; i++) {
        let item = rows[i];
        if (!newIds?.includes(item?.id)) {
          newIds.push(item?.id);
          let temp = [
            item.name,
            item.country_code + " " + item.number,
            item.location,
            item.type,
            item.users,
          ];
          newData.push(temp);
        }
      }
      return { ...prevState, data: newData, ids: newIds };
    });
  }, [rows]);

  const fetchData = () => {
    setShowLoader(true);
    JService.get(API_BASE_URL + `/api/v1/auth/virtual-numbers/`)
      .then((res) => {
        if(!res?.success) throw new Error(res?.message || "Error occurred while fetching locations")
		let freeNumbers=0;
		setRows(res.data);
		setMasterRows(res.data);
		let vnInfoObj={}
		res?.data?.forEach(vn=>{
			let location=vn.location
			if(location?.includes('India') || ['bengaluru','hyderabad','noida','mumbai', 'vijayawada'].some((city)=>location?.toLowerCase()?.includes(city)))   location='India'
			if(!vnInfoObj[location]){
				vnInfoObj[location]={total:0,includedInPlan:0}
			}
			vnInfoObj[location]['total']+=1
			vnInfoObj[location]['includedInPlan']+= (!vn.addon?1:0)
			if(!vn.addon) freeNumbers+=1;
		})
		if(res?.data?.length>freeNumbers && reduxSubscription?.plan_licenses?.total>freeNumbers){
			setMaximizeBenefitInfoVisible(true)
		}
		else {
			if(maximizeBenefitInfoVisible){
			setMaximizeBenefitInfoVisible(false)
			}
		}
		setVNInfo(vnInfoObj);
		dispatch(setVirtualNumbersCount(vnInfoObj))
      })
      .catch((err) => enqueueSnackbar(err?.message, { variant: "error"}))
      .finally(() => setShowLoader(false));
  };

  const handleScrollWhenRowOptionsOpen = (e) => {
    setRowBeingEdited(null);

    window.removeEventListener("scroll", handleScrollWhenRowOptionsOpen);
  };

  useEffect(() => {
    if (rowBeingEdited) {
      window.addEventListener("scroll", handleScrollWhenRowOptionsOpen);
    }

    return () => {
      window.removeEventListener("scroll", handleScrollWhenRowOptionsOpen);
    };
  }, [rowBeingEdited]);

  const onSearch = (searchText) => {
    const searchTextLower = searchText?.toLowerCase();
    const temp = masterRows?.filter((row) =>
      row.name?.toLowerCase().includes(searchTextLower)
    );
    combineSearchAndFilters(temp);
  };

  const combineSearchAndFilters = (arg1) => {
    // if filters and search is empty, set rows if rows != masterRows
    if (
      !availableData?.locations?.selected?.length &&
      !availableData?.numberTypes?.selected?.length &&
      availableData?.searchText.trim()?.length === 0
    ) {
      if (arg1 != rows) {
        setRows(arg1); //eslint-disable-line
      }
      return;
    }

    // locations selected changed
    if (availableData?.locations?.selected?.length) {
      arg1 = arg1?.filter((te) =>
        availableData?.locations?.selected?.includes(te?.location)
      );
    }

    // callTypes selected changes
    if (availableData?.numberTypes?.selected?.length) {
      arg1 = arg1?.filter((te) =>
        availableData?.numberTypes?.selected?.includes(te?.type)
      );
    }

    setRows(arg1);
  };

  const updateName = () => {
    let url;

    if (rowBeingEdited.type === "Calling")
      url = `/api/v1/auth/virtual-numbers/calling/${rowBeingEdited.id}/`;
    else if (rowBeingEdited.type === "Voice Broadcast")
      url = `/api/v1/auth/virtual-numbers/voice-broadcast/${rowBeingEdited.id}/`;
    else return;

    JService.patch(API_BASE_URL + url, { name: editRowName })
      .then((response) => {
        const res = response.data;
        
        if(!response?.success) throw new Error(response?.message || "Error while updating virtual number")
		const updatedRows = masterRows.map((row) => {
			if (row.id === rowBeingEdited.id) {
			return { ...row, name: res.data?.name };
			}
			return row;
		});
		setRows(updatedRows);
		setMasterRows(updatedRows);
		enqueueSnackbar(response?.message, { variant: "success"})
        
        setShowEditModal(false);
        setRowBeingEdited(null);
      })
      .catch((err) => enqueueSnackbar(err?.message, { variant: "error"}))
  };
 const handleOpenChatWidget=()=>{
  if(!isWidgetOpen){
    handleToggleWidget()
  }
 }

 const handleAddVN = () => {
  navigate('/virtual-numbers/?add-vn=true');
  handleOpenChatWidget();
 }

  const cols = [
    {
      field: "name",
      headerName: "Number name",
      headerClassName: "user-table-header-user-name",
      flex: 1,
      renderCell: (params) => {
        return (
          <p
            className="ic-green-500 regular-font b1"
            style={{ marginLeft: "14px", textDecoration: "underline", fontSize:'14px'}}
          >
            {!!params.row?.name ? capitalizeString(params.row.name) : ""}
          </p>
        );
      },
    },
    {
      field: "number",
      headerName: "Virtual number",
      flex: 1,
      renderCell: (params) => {
        return (
          <p className="t6 regular-font nc-gray-900" style={{fontSize:'14px'}}>
            {params.row.country_code + " " + params.row.number}
          </p>
        );
      },
    },
    {
      field: "location",
      headerName: "Location",
      flex: 1,
      renderCell: (params) => {
        return (
          <p className="t6 regular-font nc-gray-900" style={{fontSize:'14px'}}>{params.row.location}</p>
        );
      },
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      renderCell: (params) => {
        return <p className="t6 regular-font nc-gray-900" style={{fontSize:'14px'}}>{params.row.type}</p>;
      },
    },
    {
      field: "users",
      headerName: "Users",
      flex: 0.6,
      renderCell: (params) => {
        return (
          <p className="t6 regular-font nc-gray-900" style={{fontSize:'14px'}}>{params.row.users}</p>
        );
      },
    },
    {
      field: "numberEdit",
      headerName: "",
      flex: 0.5,
      cellClassName: "table-cell-edit", // @important use this threedots
      renderCell: (params) => {
        return (
          <div>
            <Box>
              <img
                onClick={(e) => {
                  setRowOptionsCords([e["clientX"], e["clientY"]]);
                  e.stopPropagation();
                  setRowBeingEdited(params.row);
                  setEditRowName(params.row.name);
                }}
                src={userEditIcon}
                alt="three dots icon"
              />
            </Box>
          </div>
        );
      },
    },
  ];

  return (
    <div
      className="screen-container "
      style={{
        height:'max-content'
      }}
    >
      {maximizeBenefitInfoVisible && (
        <div className={styles.maximizeBenefitBox}>
          <div><p className="t6 semibold-font">Maximize your plan benefits! ✨</p><span onClick={()=>setMaximizeBenefitInfoVisible(false)}>{modalCloseIcon()}</span></div>
          <p className="t6 regular-font">Convert your paid virtual number to free one by connecting it with your plan license.<br/>
          Reach out to our <span onClick={handleOpenChatWidget}className={styles.chatSupport}>chat support</span> to include your existing number within your plan.</p>
          <p className="t6"><span className="medium-font">Note: </span><span className="regular-font">Virtual numbers not included with plan license will incur a monthly fee</span></p>
        </div>
      )}
      <GHeader
        breadcrumbs={false}
        title="Virtual numbers"
        threeDots={false}
        topHeader={{}}
        topHeaderVisible={false}
        bottomHeader={{
          contentTitle: "Virtual numbers",
          buttons: [<Button buttonText='Add number' hierarchy='green' disabled={reduxSubscription?.status==='TRIAL'} onClick={handleAddVN} icon={{startIcon:<span>{topNavAccountPlusIcon(reduxSubscription?.status==='TRIAL'?'#c6ccc8':'#fff')}</span>}}/>],
          isCalendar: false,
        }}
      />
      {Object.keys(vnInfo || {})?.length > 0 && !showLoader && <div style={{borderRadius:'6px',border:'1px solid #34cb65',padding:'12px 16px',marginBottom:'24px',}}>
        {vnInfo && Object.keys(vnInfo)?.slice(0,Object.keys(vnInfo)?.length>3?2:3)?.map(vn=>{
          return (<p>
            <span className="t7 medium-font">{vnInfo[vn].total}</span> <span className="regular-font">{vn} Numbers</span><span className="regular-font t7" style={{color:'#78807a'}}> ({vnInfo[vn].includedInPlan} included with plan)</span>
            </p>
          )
        })}
        {vnInfo && Object.keys(vnInfo)?.length>3 && displayAllVn && Object.keys(vnInfo)?.slice(2)?.map(vn=>{
          return (
            <p>
            <span className="t7 medium-font">{vnInfo[vn].total}</span> <span className="regular-font">{vn} Numbers</span><span className="regular-font t7" style={{color:'#78807a'}}> ({vnInfo[vn].includedInPlan} included with plan)</span>
            </p>
          )
        })}
        {vnInfo && Object.keys(vnInfo)?.length>3 && <div className={`t7 medium-font ${styles.viewMoreBtn}`} style={{cursor:'pointer',color:'#2eb258', display:'flex',marginTop:'16px'}} onClick={()=>setDisplayAllVn(prev=>!prev)}>{displayAllVn ? 'Hide':'View more'}<div style={{rotate:displayAllVn?'180deg':'0deg',marginLeft:'4px',width:'20px'}}>{dropdownIcon('#2eb258')}</div></div>}
      </div>}
      <GTable
        leftHeader={{
          filters: [
            { name: "Location", toggle: false, isSearch: false, payloadFor: 'locations' },
            // { name: "Number type", toggle: false },
          ],
        }}
        rightHeader={{ isSearch: true, isRefresh: true, isDownload: true }}
        rows={rows}
        availableData={availableData}
        dispatchAvailableData={dispatchAvailableData}
        download={{
          data: csvData,
          body: null,
          filename:
            `virtual-numbers-` +
            new Date().toLocaleString("en-US", {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            }),
        }}
        fromScreen="virtual-nums"
        onSearch={onSearch}
        columns={cols}
        useMuiTable={true}
        isLoading={showLoader}
        getTableRowClassName={() => `teams-table-row`}
        additionalProps={{ bgHover: true }}
        onRowClick={(params, event, details) => {
          navigate(`/virtual-numbers/${params.row.number}`, {
            state: {
              id: params.row.id,
              type: params.row.type,
            },
          });
        }}
      />

      {!!rowBeingEdited && (
        <div
          className="row-options-backdrop"
          onClick={() => {
            setRowBeingEdited(null);
          }}
        >
          <div style={{ position: "relative" }}>
            <div
              className="row-options-foreground"
              style={{
                marginLeft: rowOptionsCords[0],
                marginTop: rowOptionsCords[1],
              }}
            >
              <button
                className="t6 nc-gray-900"
                style={{ textAlign: "left" }}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowEditModal(true);
                }}
              >
                Edit
              </button>
            </div>
          </div>
        </div>
      )}

      {showEditModal && (
        <GModal
          visible={showEditModal}
          closeModal={() => {
            setShowEditModal(false);
            rowBeingEdited(null);
          }}
          heading="Edit number"
          modalStyle={{ width: "448px", height: "150px" }}
          bottomButtons={[
            <Button
              variant="filled"
              buttonText="Cancel"
              icon={{}}
              disabled={false}
              isLoading={false}
              hierarchy="white"
              onClick={() => {
                setShowEditModal(false);
                rowBeingEdited(null);
              }}
            />,
            <Button
              variant="filled"
              buttonText="Save"
              icon={{}}
              disabled={
                !!rowBeingEdited &&
                (editRowName === rowBeingEdited.name ||
                  editRowName.length === 0)
              }
              isLoading={false}
              hierarchy="green"
              onClick={(e) => updateName(e)}
            />,
          ]}
          body={
            <>
              <form
                className="new-contact-list-form add-contact-form virtual-numbers-form"
                style={{ maxWidth: "400px" }}
              >
                <label
                  className="t7 medium-font"
                  style={{ marginBottom: "32px" }}
                >
                  Number name
                  <input
                    placeholder="Enter name"
                    className="px-14"
                    type="text"
                    onChange={(e) => {
                      setEditRowName(e.target.value);
                    }}
                    value={editRowName}
                  />
                </label>
              </form>
            </>
          }
        />
      )}
    </div>
  );
}

export default VirtualNumber;
