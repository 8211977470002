import './signupScreen6Styles.css'

import vectorArt from '../../assets/signup-screen2.svg'
import dotsBottom from '../../assets/login-dots-bottom.svg'
import dotsTop from '../../assets/login-dots-top.svg'
import logo from '../../assets/top-nav-logo.svg'

import { useState, useEffect } from 'react'

import { login, resetUserState } from '../features/user/user'

import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'


import Button from '../design/Button'
import colors from '../design/colors'
import { JService, refreshAccessToken } from '../api-service/ApiService'
import AnimationPage from '../shared/animation-page/AnimationPage'
import './commonStyles.css'
import { enqueueSnackbar, closeSnackbar } from 'notistack'
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { pushToClevertap } from '../../utility-functions/clevertap'
import clevertap from 'clevertap-web-sdk'
import { saveAccountDetails } from '../features/account/account'
import { CustomSelect } from '../design/components/customComponents'

const numberType = 'USA number'

const SignupScreen6 = ({handleToggleWidget}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const userLoginAttemptWith = useSelector(state => state.user.value)

    const [virtualNumber, setVirtualNumber] = useState(null);
    const [availableVns, setAvailableVns] = useState(null);
    const [vnsToDisplay, setVnsToDisplay] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const [isLoading, setIsLoading] = useState(false)


    useEffect(() => {
      if('navigate' in userLoginAttemptWith && userLoginAttemptWith.navigate === true && !window.location.pathname?.startsWith('/dashboard')){
        navigate('/dashboard',{replace: true, state: {from: 'signup'}});
        setIsLoading(false);
        dispatch(resetUserState());
      }
    }, [userLoginAttemptWith])

    useEffect(() => {
        const controller = new AbortController();
        fetchTrialVirtualNumbers(controller.signal);

        return () => controller.abort();
    }, []);

    useEffect(()=>{
      if(!numberType || !availableVns) return;
      const temp=availableVns?.filter((vn)=>{
        if(numberType==='India number')
          return ['Noida','Bengaluru','Hyderabad','Mumbai','Vijayawada'].includes(vn.location)
        else{
          const locations={USA:"United States",UK:"United Kingdom",Canada:'Canada'}
          return locations[numberType.split(' ')[0]].includes(vn.country)
        }
      })
      setVnsToDisplay(temp)
    },[numberType,availableVns])

    useEffect(() => {
      if(!Array.isArray(availableVns)) return;
      if(!vnsToDisplay || !Array.isArray(vnsToDisplay) || !vnsToDisplay.length){
        enqueueSnackbar("Unable to select virtual number? ", {
          style: {
              backgroundColor: "white",
              color: "black",
              border: "1px solid #34cb65",
              height: '56px',
              fontWeight: '500px',
              fontSize: '16px',
              lineHeight: '24px',
          },
          action: (
              <>
             <div onClick={handleToggleWidget} className='snackbar' style={{ color: '#34cb65', cursor: 'pointer',marginRight:'249px' ,textDecoration: 'underline'}}><div></div>Contact&nbsp;Us</div>
             <IconButton
                      aria-label="close"
                      color="inherit"
                      onClick={handleSnackbarClose}
                  >
                      <CloseIcon sx={{ height: '20px', width: '20px' }} />
                  </IconButton>
              </>
          ),
        })
      }
    }, [vnsToDisplay]);

    const fetchAccountProfile=()=>{  
        JService.get("/api/v1/auth/retrieve-account-profile/")
          .then((res) => {
            if (!res?.success) throw Error(res?.message || "Failed to fetch account details");
            dispatch(saveAccountDetails(res));
    
            //@clevertap update
            const userDetails = res?.data;
    
            let integrations = "";
            if (userDetails?.profile?.hubspot_access) {
              integrations += "hubspot";
            }
    
            if (userDetails?.profile?.monday_access) {
              if (!integrations) integrations += "monday";
              else integrations += ", monday";
            }
            if (userDetails?.profile?.deskera_access) {
              if (!integrations) integrations += "deskera";
              else integrations += ", deskera";
            }
    
            if (userDetails?.profile?.pipedrive_access) {
              if (!integrations) integrations += "pipedrive";
              else integrations += ", pipedrive";
            }
    
            if (userDetails?.profile?.zoho_access) {
              if (!integrations) integrations += "zoho";
              else integrations += ", zoho";
            }
    
            if (userDetails?.profile?.zoho_phonebridge_access) {
              if (!integrations) integrations += "zohophonebridge";
              else integrations += ", zohophonebridge";
            }
            let payload = {
              Name: userDetails?.first_name + " " + userDetails?.last_name, // fullname
              Identity: userDetails?.email, //email,
              Email: userDetails?.email,
    
              Phone:
                userDetails?.number?.country_code +
                "" +
                userDetails?.number?.phone_number, // string | number, // user phone number
              OrganizationName: userDetails?.profile?.org_name || "",
              OrganizationId: userDetails?.profile?.org_id,
              VirtualNumbers: JSON.stringify(userDetails?.virtual_numbers),
              SuperAdmin: userDetails?.account_holder ? "yes" : "no",
              Role: JSON.stringify(userDetails.roles ?? []),
              IsVerified: userDetails?.profile?.is_verified
                ? "verified"
                : "not verified",
              TotalMinutes: userDetails?.profile?.total_minutes,
              Integrations: integrations,
              InboundCalls: userDetails?.profile?.inbound_calls
                ? "enabled"
                : "disabled",
              UserEmail: userDetails?.email,
              UserPhoneNumber:
                userDetails?.number?.country_code +
                "" +
                userDetails?.number?.phone_number, // string | number, /
              BrowserCalls: userDetails?.profile?.browser_calls
                ? "enabled"
                : "disabled",
              NotifyEmails: userDetails?.profile?.notify_emails ? "true" : "false",
              MissedCallNotifications: userDetails?.profile
                ?.missed_call_notifications
                ? "true"
                : "false",
              PrimaryVirtualNumber: JSON.stringify(
                userDetails?.profile?.primary_virtual_number
              ),
              FeatureAddOns: JSON.stringify(
                userDetails?.profile?.feature_add_ons || []
              ),
            };
            clevertap.onUserLogin.push({ Site: payload });
            let country = res?.data?.profile?.primary_virtual_number?.location;
            country = ["Hyderabad", "Bengaluru", "Mumbai"].includes(country)
              ? "India"
              : country;
            pushToClevertap("New Account Created", true, {
              countryOfCalling: country,
            });     
          })
          .catch((err) => {
            enqueueSnackbar(err?.message || "Error occurred while fetching account details", { variant: "error"})
            pushToClevertap("New Account Created", false, null, err);
          })
      }
     
    const handleSubmit = (e) => {
        e.preventDefault()
        if(!numberType || !virtualNumber) return;
          setIsLoading(true)
          JService.post('/api/v1/subscriptions/initiate-trial/', JSON.stringify({ vn: virtualNumber }))           
            .then(res => {
                if(!res?.success) throw new Error(res?.message || 'Failed to update numberType and virtual number details')
                //TODO: check if this needs to be uncommented
			          //dispatch(login(userLoginAttemptWith))
                console.log("intiate trial response")
                setTimeout(async ()=>{
                  fetchAccountProfile()
                  await refreshAccessToken();
                  console.log('calling dispatch(login)');
                  dispatch(login({...userLoginAttemptWith, source: 'signup'}))
                },3500);     
            })
            .catch(err => enqueueSnackbar(err?.message, { variant: "error"}))
    }

    const fetchTrialVirtualNumbers=(signal)=>{
        setShowLoader(true);
        JService.get('/api/v1/auth/get-trial-vns/', {}, {signal})          
          .then((res)=>{
            if(!('success' in res)) return;
            if(!res.success){
                throw new Error(res?.message || 'Failed to retrieve virtual numbers');
            }
            setShowLoader(false);
            setAvailableVns(res?.data || []);
        })
        .catch(err => {
          setShowLoader(false);
          enqueueSnackbar(err?.message, { variant: "error" })
        })
}

    const handleSnackbarClose = () => {
        closeSnackbar();
    };

    return (
        <div className='signup-screen-container signup-screen6-container color-2d'>
            <div className='signup-art'>
            <a href="https://frejun.com/" target="_blank" rel="noopener noreferrer"> <img src={logo} alt="Logo" /></a>
                <img src={dotsTop} />
                <img src={vectorArt} />
                <img src={dotsBottom} />
            </div>
            {showLoader && <div className='loader-container'><AnimationPage /></div>}
            {!showLoader && (
                <div className='signup-screen-form'>
                    <p className='title t4 medium-font s6font'>Choose your trial number</p>
                    <form onSubmit={e => handleSubmit(e)} className=' screen6-form'>
                        <div className="select-container">
                        <CustomSelect
                            label="Virtual number"
                            value={virtualNumber}
                            handleChange={(e) => setVirtualNumber(e.target.value)} 
                            renderVal={(val) =>
                              val ? (
                                <span className="nc-gray-900" style={{ color: colors.nc_gray_900 }}>
                                  {vnsToDisplay.find((elem) => elem.number === val)?.country_code} {val}
                                </span>
                              ) : (
                                <span className="nc-gray-400">Select number</span>
                              )
                            }
                            options={
                              numberType
                                ? vnsToDisplay.map((elem) => ({
                                    value: elem.number,
                                    displayValue: `${elem.country_code} ${elem.number}`
                                  }))
                                : [{ value: null, displayValue: 'Select a number type' }]
                            }
                          />
                        </div>
                        <p>
                            <span className='t7 regular-font note'>Need a different number? 
                              Contact <span className='medium-font' style={{textDecoration:'underline',color:'#2eb258',cursor:'pointer'}} onClick={handleToggleWidget}>chat support</span>  
                              .
                            </span>
                        </p>
                        <div className='buttons-container btnscreen6'>
                            <Button className='s6btn' isLoading={isLoading || showLoader} hierarchy="green" buttonText="Next" onClick={handleSubmit}  />
                        </div>
                    </form>
                </div>)}

        </div>
    )
}

export default SignupScreen6;
